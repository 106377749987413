import cx from 'classnames';
import React from 'react';
import { FlosField, FlosFieldProps } from '../../flos-field';
import { IRadioFieldContext, RadioFieldContext } from '../radio-field-context';
import { FlosInputProps } from '../../input/flos-input';
import { useId } from '../../../../utils/hooks';

export type RadioFieldProps = {
    id?: string;
    label?: React.ReactNode;
    description?: React.ReactNode;
    /** error help text for the field that will only be displayed if status is error */
    errorText?: string;
    /**
     * states if the radio input should be arranged vertically
     * @default true
     */
    stacked?: boolean;
    value: string | number | boolean;
    /** name for this radio group, will be injected into all radio inputs */
    name?: string;
    isValid?: IRadioFieldContext['isValid'];
    /** Change the layout to be eg. radio buttons */
    variant?: 'button-toolbar' | 'button-menu';
    required?: boolean;
    onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
    children?: React.ReactNode;
    disabled?: boolean;
} & Omit<FlosInputProps, 'value'> &
    FlosFieldProps;

/**
 * `RadioField` renders containers for a group of radio input and coordinate between them.
 *
 * `RadioField` and `RadioInput` should be used together as Compound Component.
 * As an metaphor, `RadioField` is like `select` element while `RadioInput` is like `option` element.
 *
 * If you want to render this wrapper totally differently, instead of add more props to this component, consider create your own wrapper by using `RadioControlContext`.
 */
export const RadioField = React.forwardRef<HTMLInputElement, Omit<RadioFieldProps, 'maxLength'>>(
    ({ onChange, isValid, variant, required, errorText, helpText, label, value, description, stacked = true, children, name, disabled, className, ...rest }, ref) => {
        name = useId(name);
        const contextValue = React.useMemo(
            () => ({
                name,
                isValid,
                value,
                variant,
                required,
                disabled,
                errorText,
            }),
            [name, isValid, value, variant, required, disabled, errorText]
        );

        const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            onChange && onChange(e);
        };

        return (
            <RadioFieldContext.Provider value={contextValue}>
                <fieldset className={className}>
                    {label && (
                        <legend>
                            <strong>
                                {label}
                                {required && '*'}
                            </strong>
                        </legend>
                    )}
                    {description && <p>{description}</p>}
                    <FlosField
                        ref={ref}
                        custom
                        required={required}
                        isValid={isValid}
                        disabled={disabled}
                        errorText={errorText}
                        helpText={helpText}
                        renderInput={() => (
                            <div
                                className={cx({
                                    [`flos-${variant}`]: !!variant,
                                    'flos-radio-field': !variant,
                                    'flos-radio-field--stacked': stacked,
                                    'u-No-bottom-margin': variant === 'button-toolbar',
                                })}
                            >
                                {React.Children.map(children, (child, index) => {
                                    if (React.isValidElement(child)) {
                                        return React.cloneElement(child as React.ReactElement, {
                                            ref: ref,
                                            onChange: changeValue,
                                            key: index,
                                        });
                                    } else {
                                        return;
                                    }
                                })}
                            </div>
                        )}
                        {...rest}
                    />
                </fieldset>
            </RadioFieldContext.Provider>
        );
    }
);
RadioField.displayName = 'RadioField';
