import React from 'react';
import { animated } from '@react-spring/web';
import { ProgressBar } from '../navigation/progressbar/progressbar';
import { useWizardAnimation } from './use-wizard-animation';
import { FlosIcon } from '../../core/icon/flos-icon';
import { Link } from '../navigation/flos-link/link';
import cx from 'classnames';
const { useState, useEffect, useRef, Children, isValidElement, useMemo } = React;

type PaneChild = string | React.ReactElement | JSX.Element;
export type WizardPaneProps = {
    /** To indicate if step is current active */
    active?: boolean;
    /** To toggle Stepper visibility on top of Wizard */
    hideProgress?: boolean;
    /** Title of each step, will be extract by `Wizard.Container` */
    title?: string;
    /** Callback function when user click on `Ret` button */
    onChange?: (step: number) => void;
    /** Content to be show on each step */
    children?: PaneChild | PaneChild[];
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>;

/**
 * The WizardPane component.
 * Wizard wrapper for each step or content to be shown
 *
 * @param {WizardPaneProps} props - The props for the component.
 * @param {boolean} active - To indicate if step is current active.
 * @param {boolean} hideProgress - To toggle Stepper visibility on top of Wizard.
 * @param {string} title - Title of each step, will be extract by `Wizard.Container`.
 * @param {function} onChange - Callback function when user click on `Ret` button.
 * @returns {JSX.Element} The `WizardPane` component.
 */
export const WizardPane: React.FC<WizardPaneProps> = ({ children }: WizardPaneProps) => children as React.ReactElement;

export type WizardContainerProps = {
    /** The content to be displayed in the steps. */
    children?: JSX.Element | JSX.Element[];
    /** The current step to be displayed. */
    currentStep?: number;
    /** Total steps to be display on stepper default to children count */
    totalSteps?: number;
    /** The animation duration in milliseconds. */
    duration?: number;
    /** Decide how the wizard should behave */
    variant?: 'horizontal' | 'vertical';
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * The WizardContainer component.
 * Wizard wrapper for each step or content to be shown
 *
 * @param {WizardContainerProps} props - The props for the component.
 * @param {WizardPaneProps[]} children - The content to be displayed in the steps.
 * @param {number} [currentStep=1] - The current step to be displayed.
 * @param {number} [totalSteps] - Total steps to be display on stepper default to children count.
 * @param {number} [duration=250] - The animation duration in milliseconds.
 * @param {string} [variant='horizontal'] - Decide how the wizard should behave
 * @returns {JSX.Element} The `WizardContainer` component.
 */
export const WizardContainer: React.FC<WizardContainerProps> = ({
    children,
    currentStep = 1,
    totalSteps,
    duration = 250,
    variant = 'horizontal',
    className,
    ...rest
}: WizardContainerProps) => {
    // Filter out invalid child from children prop
    const childrenArray = Children.toArray(children).filter((child: React.ReactElement) => {
        if (!isValidElement(child) || child.type !== WizardPane) {
            console.warn('<Wizard.Container /> children should be <Wizard.Pane />');
        }
        return isValidElement(child) && child.type === WizardPane;
    });

    // A ref that point to wrapper of current step
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);

    // Getting the count of childrens
    const childrenCount = childrenArray.length;
    const _totalSteps = totalSteps || childrenCount;
    // Parsing valid current step
    const validStep = currentStep > childrenCount ? childrenCount : currentStep < 1 ? 1 : currentStep;
    // Use state to keep track of current step
    const [_currentStep, setCurrentStep] = React.useState(validStep);
    // Use ref to keep track of prev step to prevent additional render
    const _prevStep = useRef(validStep);
    // Use ref to track current step children

    // Use state to keep track of the sliding animation
    const [direction, setDirection] = useState<'forward' | 'backward'>(_currentStep < validStep ? 'forward' : 'backward');

    // Wizard initial render then there will not have prevStep
    const isInitialRender = _prevStep.current === _currentStep;

    // Use effect hook for updating valid current and prev step
    useEffect(() => {
        if (typeof validStep !== 'number') return;
        setDirection(_currentStep < validStep ? 'forward' : 'backward');
        _prevStep.current = _currentStep;
        setCurrentStep(validStep);
    }, [validStep, _currentStep]);

    // transition duration in milliseconds
    const transitionDuration = `${duration}ms`;

    // Custom hook to generate entrance and leave animation
    const [exitStyle, entranceStyle, animationEnd] = useWizardAnimation({
        direction,
        immediate: isInitialRender,
        currentStep: _currentStep,
        duration,
        variant,
    });

    // Scroll to wrapper when animation ended
    useEffect(() => {
        if (!animationEnd || !wrapperRef.current) return;
        const scrollBuffer = 80;
        window.scrollTo({
            top: wrapperRef.current.getBoundingClientRect().top + window.scrollY - scrollBuffer,
            behavior: 'smooth',
        });
    }, [animationEnd]);

    // Constant to indicate if wizard is vertical variant
    const isVertcalVariant = variant === 'vertical';
    // Creating constant for current content from children array
    const currentChild = childrenArray[_currentStep - 1] as React.ReactElement;
    // Creating constant for prev content for leaving animation.
    const prevChild = childrenArray[_prevStep.current - 1 < 0 ? 0 : _prevStep.current - 1] as React.ReactElement;
    // Grab title out from props
    const currentChildTitle = currentChild && currentChild.props && currentChild.props.title;
    // Constant to tell if Stepper should be hiddenscrol
    const _hideProgress = (isVertcalVariant || _currentStep > _totalSteps || (currentChild && currentChild.props.hideProgress)) as boolean;
    // `currentStep` update wil toggle 4 rendering, use momoization to prevent expensive loop, also make the transition smot
    const verticalSteps = useMemo(() => {
        if (!isVertcalVariant) return null;
        return childrenArray.map((child: React.ReactElement, index) => {
            const step = index + 1;
            const isCurrentStep = step === _currentStep;
            const isPrevStep = step === _prevStep.current;
            // Constant of which step should show return button
            const isReturnable = step < _currentStep && child.props.onChange;
            const { title, active, className, ...rest } = child.props;

            return (
                <div
                    key={title}
                    ref={isCurrentStep ? wrapperRef : null}
                    aria-selected={isCurrentStep}
                    className={cx(
                        {
                            'flos-wizard-step--vertical': true,
                            'flos-wizard-step--active': active || isCurrentStep,
                        },
                        className
                    )}
                    {...rest}
                >
                    <h3
                        className={cx({
                            ['flos-wizard-title']: true,
                            ['flos-wizard-title--subtle']: !isCurrentStep && !active,
                            ['flos-wizard-title--with-icon']: active,
                        })}
                    >
                        {<FlosIcon shape="circle-check" className={cx({ 'flos-wizard-icon has-fill is-green': true, 'flos-wizard-icon--hide': !active })} />}
                        <span className={cx({ ['flos-wizard-span']: true, 'flos-wizard-span--with-icon': active })}>{title}</span>
                        {isReturnable && (
                            <Link theme="logo" className="flos-wizard-action" onClick={() => child.props.onChange(step)}>
                                Ret
                            </Link>
                        )}
                    </h3>
                    {currentChild && isCurrentStep && (
                        <animated.div className={'flos-wizard-step-animated-div'} style={entranceStyle} aria-selected={true} tabIndex={0}>
                            {currentChild}
                        </animated.div>
                    )}
                    {prevChild && isPrevStep && !animationEnd && (
                        <animated.div className={'flos-wizard-step-animated-div'} style={exitStyle} aria-hidden="true" tabIndex={-1}>
                            {prevChild}
                        </animated.div>
                    )}
                </div>
            );
        });
    }, [_currentStep, exitStyle, entranceStyle, animationEnd, childrenArray, currentChild, isVertcalVariant, prevChild]);

    // Final template rendering.
    if (!isVertcalVariant && !!currentChild) {
        /** <!-- Horizontal variant --> */
        return (
            <div className={cx('flos-wizard', className)} style={{ transitionDuration }} {...rest}>
                {!_hideProgress && currentChildTitle && <h3 className="flos-wizard-title--small">{currentChildTitle}</h3>}
                {!_hideProgress && <ProgressBar currentStep={_currentStep} totalSteps={_totalSteps} />}
                <div className={cx('flos-wizard-steps', { ['flos-wizard-steps--with-progress']: !_hideProgress })} ref={wrapperRef}>
                    {!isInitialRender && !animationEnd && (
                        <animated.div
                            style={{ ...exitStyle, position: 'absolute' }}
                            className={cx('flos-wizard-step--horizontal', currentChild.props.className)}
                            aria-hidden="true"
                            tabIndex={-1}
                        >
                            {prevChild}
                        </animated.div>
                    )}
                    <animated.div style={entranceStyle} className={cx('flos-wizard-step--horizontal', currentChild.props.className)} aria-selected="true" tabIndex={0}>
                        {currentChild}
                    </animated.div>
                </div>
            </div>
        );
    }

    /** <!-- Vertical variant --> */
    return (
        <div className={cx('flos-wizard', className)} style={{ transitionDuration }} {...rest}>
            <div className="flos-wizard-steps flos-wizard-steps--vertical">{verticalSteps}</div>
        </div>
    );
};

export type WizardProps = {
    Container: React.FC<WizardContainerProps>;
    Pane: React.FC<WizardPaneProps>;
};
export const Wizard: WizardProps = {
    Container: WizardContainer,
    Pane: WizardPane,
};

export default Wizard;
