import React from 'react';
import cx from 'classnames';
import { useId } from '../../../utils/hooks';

type CounterProps = {
    /**
     * The content of the Counter
     */
    content: string | number;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Counter is a circle with a number or char inside.
 */

const Counter: React.FC<CounterProps> = ({ content, ...props }) => {
    const id = useId(props.id);

    return (
        <div id={id} className={cx('flos-counter', props.className && props.className)} {...props}>
            {content}
        </div>
    );
};

export type { CounterProps };
export { Counter };
