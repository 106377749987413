import React, { ChangeEvent } from 'react';
import { FlosInput, FlosInputProps } from '../input/flos-input';
import { PatternFormat } from 'react-number-format';
import { validateCvr } from '../../../utils/validate';
import { useEffect, useState } from 'react';
import { noop } from '../../../utils';

export type CvrInputProps = {
    isValidHandler?: (isValid?: boolean) => void;
    isValid?: boolean;
} & FlosInputProps;

/**
 * CvrInput is a wrapper around Input component with formatting and validity check
 */
export const CvrInput = React.forwardRef<HTMLInputElement, CvrInputProps>(function CvrInputComp(
    { value, isValid, required, onChange, onBlur, isValidHandler = noop, ...inputProps },
    ref
) {
    const [internalValue, setInternalValue] = useState('');

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '' && (isValid || typeof isValid === 'undefined')) {
            isValidHandler(undefined);
        } else if (event.target.value) {
            const cvrFormatIsValid = validateCvr(event.target.value);
            setInternalValue(event.target.value);
            isValidHandler(cvrFormatIsValid);
        } else {
            isValidHandler(false);
        }
        onBlur && onBlur(event);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setInternalValue(newValue);
        event.target.value = newValue;
        onChange && onChange(event as ChangeEvent<HTMLInputElement>);
    };

    useEffect(() => {
        // TODO!
        if (value) {
            setInternalValue(value as string);
        }
    }, [value]);

    return (
        <PatternFormat
            {...(inputProps as any)}
            getInputRef={ref}
            value={internalValue}
            format="########"
            customInput={FlosInput}
            onChange={handleChange}
            onBlur={handleBlur}
            valueIsNumericString
        />
    );
});
