import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { getId } from '../../../utils';
import FlosIcon from '../../../core/icon/flos-icon';
import { IListBoxInfoProps } from './list-box-info/list-box-info';
import { MessageProps } from '../../attention-elements/message/message';

type IListBoxProps = {
    /** box title text or reactNode */
    title: React.ReactNode;
    /** box subtitle text */
    subtitle?: React.ReactNode;
    /** id to be used for accessibility attributes */
    id?: string;
    /** This is where you add the content on the right (in desktop view). Usually its short messages and the like */
    infoArea?: React.ReactElement<IListBoxInfoProps>;
    /** Button or other action component */
    action?: React.ReactNode;
    /**  Children will show up below the title, description and actionArea as accordion content */
    children?: React.ReactElement | React.ReactElement<any>[];
    /** Open children accordion section defaultly */
    isOpen?: boolean;
    /** `Content` to be shown below detailsBox. It is a type on Message component */
    addonContent?: React.ReactElement<MessageProps>;
    /** theme can primary, secondary, transparent or disabled */
    theme?: 'primary' | 'secondary' | 'transparent' | 'disabled';
    /** variant */
    variant?: 'default' | 'eCommerce';
    /** observe when the children exists and accordion expanded */
    onExpanded?: (expanded: boolean) => void;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>;

const IfWrap = ({ children, condition, wrapper }: any) => (condition ? wrapper(children) : children);

/**
 * `ListBox` is a component that allows you to show information to the user, usually used in overviews and lists.
 * - For collapsible sections, use `Accordions` as children, and add you content inside those.
 */
const ListBox = ({
    id,
    title,
    subtitle,
    infoArea,
    action,
    addonContent,
    className,
    children,
    isOpen = false,
    theme = 'primary',
    variant = 'default',
    onExpanded = (expanded: boolean) => expanded,
    ...rest
}: IListBoxProps) => {
    const [expanded, setExpanded] = useState<boolean>(isOpen);
    if (!id) {
        id = getId();
    }
    const adjustContentMaxHeight = (isExpanded: boolean) => {
        const box = document.getElementById('panel' + id);
        if (box) {
            if (isExpanded) {
                box.setAttribute('style', 'max-height:' + (box.scrollHeight + 200) + 'px');
            } else {
                box.removeAttribute('style');
            }
        }
    };

    useEffect(() => {
        if (expanded !== null) {
            adjustContentMaxHeight(expanded);
        }
    }, [expanded]);
    return (
        <div
            className={cx(
                'flos-list-box',
                variant === 'eCommerce' && 'flos-list-box--ecommerce',
                title && 'flos-list-box--has-title',
                subtitle && 'flos-list-box--has-subtitle',
                infoArea && 'flos-list-box--has-info-area',
                action && 'flos-list-box--has-action-area',
                children && 'flos-list-box--has-children',
                addonContent && 'flos-list-box--has-addon-content',
                (!expanded || theme === 'transparent') && `flos-list-box--${theme}`,
                expanded && 'flos-list-box--expanded',
                className
            )}
            id={id}
            {...rest}
        >
            <div className={cx('flos-list-box-header-area', expanded && 'expanded')}>
                {(title || infoArea) && (
                    <div
                        className={cx('flos-list-box-header')}
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <IfWrap
                            condition={children && title && variant !== 'eCommerce'}
                            wrapper={(children: any) => (
                                <div
                                    className={'flos-list-box-icon-wrap'}
                                    onClick={(e) => {
                                        const isExpanded = !expanded;
                                        setExpanded(isExpanded);
                                        onExpanded(isExpanded);
                                        e.preventDefault();
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                        >
                            {children && variant !== 'eCommerce' && (
                                <div className={cx('flos-list-box-accordion-icon', expanded && 'expanded')}>
                                    <a
                                        aria-expanded={!!expanded}
                                        aria-controls={'panel' + id}
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                        className={'flos-button flos-button--secondary flos-button--icon '}
                                    >
                                        <FlosIcon shape={'arrow-down'} size={16} />
                                    </a>
                                </div>
                            )}
                            {subtitle ? (
                                <div>
                                    {title && (
                                        <div className={cx('flos-list-box-title')}>
                                            <span className={cx(theme != 'transparent' && 'h4')} id={`${id}-title`}>
                                                {title}
                                            </span>
                                        </div>
                                    )}
                                    {subtitle && (
                                        <p className={cx('flos-list-box-subtitle', theme === 'transparent' && 'paragraph--small u-color-secondary-4-medium')} id={`${id}-subtitle`}>
                                            {subtitle}
                                        </p>
                                    )}
                                </div>
                            ) : (
                                <>
                                    {title && (
                                        <div className={cx('flos-list-box-title')}>
                                            <span className={cx(theme != 'transparent' && 'h4')} id={`${id}-title`}>
                                                {title}
                                            </span>
                                        </div>
                                    )}
                                </>
                            )}
                        </IfWrap>
                        {(infoArea || action) && (
                            <div
                                className={cx('flos-list-box-action')}
                                onClick={(event) => event.stopPropagation()}
                                onKeyUp={(event) => event.stopPropagation()}
                                onKeyDown={(event) => event.stopPropagation()}
                                onChange={(event) => event.stopPropagation()}
                            >
                                {infoArea && <div>{infoArea}</div>}
                                {action}
                            </div>
                        )}
                    </div>
                )}
                {addonContent && <div className={cx('flos-list-box-addon-content')}>{addonContent}</div>}
                {children && variant === 'eCommerce' && (
                    <div
                        className={cx('flos-list-box-accordion', expanded && 'expanded')}
                        onClick={(e) => {
                            const isExpanded = !expanded;
                            setExpanded(isExpanded);
                            onExpanded(isExpanded);
                            e.preventDefault();
                        }}
                    >
                        <hr />
                        <div className={cx('flos-list-box-accordion-icon', expanded && 'expanded')}>
                            <a
                                aria-expanded={!!expanded}
                                aria-controls={'panel' + id}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                                className={'flos-button flos-button--secondary flos-button--icon '}
                            >
                                <FlosIcon shape={'arrow-down'} size={16} />
                            </a>
                            <p className="u-spacing-stack-none paragraph--small">{expanded ? 'Luk' : ' Se'} detaljer</p>
                        </div>
                    </div>
                )}
            </div>
            {children && (
                <div
                    id={'panel' + id}
                    role="tabpanel"
                    aria-hidden={!expanded}
                    aria-labelledby={'panel-head' + id}
                    className={cx('flos-list-box-children-content', { ['flos-list-box-children-content--expanded']: expanded })}
                >
                    <div
                        className={variant === 'eCommerce' ? 'flos-list-box-children-content--ecommerce' : theme !== 'transparent' ? 'u-spacing-inset-m' : 'u-spacing-inset-m--top'}
                    >
                        {children}
                    </div>
                </div>
            )}
        </div>
    );
};

export type { IListBoxProps };
export { ListBox };
export default ListBox;
