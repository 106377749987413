import React from 'react';
import FlosField, { FlosFieldProps } from '../../flos-field';
import { SelectInput, SelectInputProps } from '../select-input';

export type SelectFieldProps = Omit<FlosFieldProps, 'maxLength'> & SelectInputProps;

/**
 * `SelectField` is a composition of `FlosField` component and `SelectInput` component. If you need extra customization, you can compose them yourself.
 *  Ref will be forwarded to the underlying `<select>` element.
 */
export const SelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>(
    ({ label, iconShape, isValid, onValidityChange, errorText = 'skal udfyldes', wrapperClassName, helpText, ...selectProps }, ref) => {
        return (
            <FlosField
                ref={ref}
                isValid={isValid}
                onValidityChange={onValidityChange}
                label={label}
                iconShape={iconShape}
                errorText={errorText}
                required={selectProps.required}
                id={selectProps.id}
                hasDropdown={true}
                wrapperClassName={wrapperClassName}
                disabled={selectProps.disabled}
                helpText={helpText}
                renderInput={({ getInputProps }) => <SelectInput {...getInputProps({ ...selectProps })} />}
            />
        );
    }
);
