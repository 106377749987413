import React from 'react';
import { Tooltip, TooltipProps } from '../tooltip/tooltip';
import { FlosIcon } from '../../icon/flos-icon';
import { getId } from '../../../utils';

export type TooltipIconProps = {
    iconShape?: string;
} & Omit<TooltipProps, 'children'>;

/**
 * TooltipIcon is a wrapper of `Tooltip` component supporting tooltip on flos-icon.
 */
export const TooltipIcon = ({ content, id, iconShape = 'info', ...props }: TooltipIconProps) => {
    const elementId = id || getId();
    return (
        <Tooltip content={content} id={`tooltip-${elementId}`} {...props}>
            <button role="button" onClick={(event) => event.preventDefault()} className="tooltip-icon-button" id={`tooltip-icon-button${elementId}`}>
                <FlosIcon size={'medium'} className={'is-indigo'} shape={iconShape} />
            </button>
        </Tooltip>
    );
};
