import React from 'react';
import cx from 'classnames';
import { noop } from '../../../utils';

export type FlosInputProps = {
    value?: string;
} & Omit<React.ComponentPropsWithRef<'input'>, 'value'>;
/**
 * Input is a wrapper around `<input />` html element, thus it would accepts all props a `<input />` accepts.
 *
 * You can use `ref` to access the underlying `<input />` element
 *
 * Usually this component is used in composition with other components
 */
const FlosInput = React.forwardRef<HTMLInputElement, FlosInputProps>(({ className, type, ...props }, ref: React.RefObject<HTMLInputElement>) => {
    return (
        <input
            {...props}
            className={cx('flos-input', type === 'file' && 'flos-input--file-upload', className)}
            onChange={props.readOnly ? noop : props.onChange}
            onBlur={props.readOnly ? noop : props.onBlur}
            onFocus={props.readOnly ? noop : props.onFocus}
            onKeyUp={props.readOnly ? noop : props.onKeyUp}
            onKeyPress={props.readOnly ? noop : props.onKeyPress}
            placeholder=" "
            type={type}
            ref={ref}
        />
    );
});

FlosInput.displayName = 'FlosInput';

export { FlosInput };
export default FlosInput;
