import React from 'react';
import cx from 'classnames';

type SelectedIconBoxProps = {
    title: string;
    id: string;
    selected: boolean;
};

type IconBoxListProps = {
    onSelect?: (selected: SelectedIconBoxProps[]) => void;
    selectedItems?: SelectedIconBoxProps[];
    variant?: 'grid-list' | 'row';
    columns?: 2 | 3 | 4 | 5;
} & Omit<React.ComponentPropsWithRef<'div'>, 'onSelect'>;

const IconBoxList = ({ children, selectedItems, onSelect, variant = 'grid-list', columns = 4 }: IconBoxListProps) => {
    const [selected, updateSelected] = React.useState<SelectedIconBoxProps[]>([]);
    const [isUpdated, setIsUpdated] = React.useState(false);

    const handleSelect = (newItem: SelectedIconBoxProps) => {
        updateSelected((prevState) => {
            // First remove the item from state if it already exists - use is deselecting it
            const newstate = prevState.filter((item) => item.id !== newItem.id);
            // Then add the new item if it is selected
            return newItem.selected ? [...newstate, newItem] : newstate;
        });
        setIsUpdated(true);
    };

    React.useEffect(() => {
        // Keep "selected" equal to selectedItems except for selectedItems undefined
        if (selectedItems) {
            updateSelected(Array.isArray(selectedItems as SelectedIconBoxProps[]) ? [...(selectedItems as SelectedIconBoxProps[])] : []);
        }
    }, [selectedItems]);

    React.useEffect(() => {
        // isUpdated prevent infinite looping when states are updated
        if (isUpdated) {
            setIsUpdated(false);
            onSelect && onSelect(selected);
        }
    }, [isUpdated, selected, onSelect]);

    return (
        <ul className={cx('icon-box-' + variant, variant == 'grid-list' && columns && `grid-list-${columns}`)}>
            {React.Children.map(children, (child, index) => {
                if (React.isValidElement(child)) {
                    return (
                        <li key={'ib-' + index}>
                            {React.cloneElement(child as React.ReactElement, {
                                onSelect: handleSelect,
                                key: index,
                            })}
                        </li>
                    );
                } else {
                    return;
                }
            })}
        </ul>
    );
};

export type { IconBoxListProps, SelectedIconBoxProps };
export { IconBoxList };
