import * as React from 'react';
import { IFile, IFileError, IUploadServiceContext, UploadFieldProps, UploadFieldLabelProps, UploadButtonProps } from './types';
import cx from 'classnames';
import { UploadServiceContext } from './upload-service-provider';
import { Button, Message } from '../../..';
import { ScanFileList } from './scan-file-list';

const UploadFieldLabel: React.FC<UploadFieldLabelProps> = ({ className, label, fileCounter, htmlFor }) => {
    if (!label && !fileCounter) return null;
    return (
        <label className={cx('flos-uploadfield-label', className)} htmlFor={htmlFor}>
            <span>{label}</span>
            {fileCounter && <span>{fileCounter}</span>}
        </label>
    );
};

export const UploadButton: React.FC<UploadButtonProps> = ({ children, disabled, onChange, acceptedExtensions = [], id = 'flos-uploadfield', ...props }) => {
    const fileSelectorRef = React.useRef<HTMLInputElement>(null);
    // Handle when user click to open the file selector
    const handleFileSelect = React.useCallback(() => fileSelectorRef.current?.click(), [fileSelectorRef]);
    // create button loading text
    return (
        <>
            <input
                accept={acceptedExtensions.toString()}
                aria-describedby={`${id}-invalid-message`}
                aria-hidden="true"
                data-testid={`${id}-input`}
                disabled={disabled}
                id={`${id}-input`}
                multiple
                onChange={onChange}
                onClick={(e) => ((e.target as HTMLTextAreaElement).value = '')}
                ref={fileSelectorRef}
                style={{ display: 'none' }}
                type="file"
            />
            <Button {...props} theme="secondary" iconShape="upload" className="flos-uploadfield-button" onClick={handleFileSelect} disabled={disabled}>
                {children}
            </Button>
        </>
    );
};

export const UploadButtonWithContext: React.FC<UploadButtonProps> = ({ selectedFilesParser, onChange, ...props }) => {
    return (
        <UploadServiceContext.Consumer>
            {({ configLoading, uploadLoading, fileStateLoading, hasFilePending, onFileChange, ...context }) => {
                const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    if (onChange) onChange(event);
                    if (onFileChange) onFileChange(event, selectedFilesParser);
                };

                // create button loading text
                let defaultLoadingText = '';
                if (configLoading) defaultLoadingText = 'Indlæser';
                else if (uploadLoading) defaultLoadingText = 'Uploader';
                else if (fileStateLoading || hasFilePending) defaultLoadingText = 'Scanner';
                return (
                    <UploadButton
                        {...props}
                        isLoading={configLoading || uploadLoading || fileStateLoading || hasFilePending || props.isLoading}
                        loadingText={defaultLoadingText || props.loadingText}
                        onChange={handleChange}
                        disabled={props.disabled || context.disabled}
                    />
                );
            }}
        </UploadServiceContext.Consumer>
    );
};

export const UploadField: React.FC<UploadFieldProps> = ({
    children,
    deleteButtonLabel,
    description,
    errorLabel,
    errorMessages,
    fileAction = 'delete',
    htmlFor = 'flos-uploadfield',
    infectedLabel,
    invalidFileSelected,
    isLoading,
    label,
    onFileCount = (count) => (count ? `Attached ${count} file${count > 1 ? 's' : ''}` : ''),
    onDelete,
    onFileOpen,
    onInit = () => null,
    openButtonLabel,
    scannedFiles,
}) => {
    const initRender = React.useRef(true);
    // onInit and onScan callback
    React.useEffect(() => {
        if (!initRender.current) return;
        initRender.current = false;
        if (onInit) onInit();
    }, [onInit]);
    // create file counter label text
    const fileCounter = !onFileCount || !scannedFiles.length ? '' : onFileCount(scannedFiles.length);

    return (
        <div className="flos-uploadfield">
            {errorMessages && errorMessages.length > 0 && (
                <div className="flos-uploadfield-errorlist">
                    {errorMessages.map((message, index) => {
                        if (message.length > 0) {
                            return (
                                <Message
                                    className={cx({ 'u-spacing-stack-s': errorMessages.length > 1 })}
                                    data-testid="error-message"
                                    dismissible
                                    key={`error-${index}`}
                                    theme="error"
                                    title={<p className="small u-spacing-stack-none">{message}</p>}
                                />
                            );
                        } else {
                            return <React.Fragment key={index} />;
                        }
                    })}
                </div>
            )}

            <ScanFileList
                deleteButtonLabel={deleteButtonLabel}
                errorLabel={errorLabel}
                fileAction={fileAction}
                fileList={scannedFiles}
                infectedLabel={infectedLabel}
                label={<UploadFieldLabel label={label} fileCounter={fileCounter} htmlFor={`${htmlFor}-input`} />}
                loading={isLoading}
                onDelete={onDelete}
                onOpen={onFileOpen}
                openButtonLabel={openButtonLabel}
            />

            {invalidFileSelected && (
                <span id={`${htmlFor}-invalid-message`} className="flos-uploadfield-description">
                    <strong>Vælg en anden filtype</strong>
                    <br />
                    {description}
                </span>
            )}
            {children}
        </div>
    );
};

export type UploadFieldWithServicesProps = Omit<UploadFieldProps, keyof IUploadServiceContext<IFile[], IFileError[]>>;

export const UploadFieldWithServices: React.FC<UploadFieldWithServicesProps> = ({ onScan, onUpload, onError, onDelete, children, ...props }) => {
    return (
        <UploadServiceContext.Consumer>
            {({ onFileScan, onFileUpload, onServiceError, onFileDelete, hasFilePending, configLoading, uploadLoading, fileStateLoading, ...context }) => {
                const handleDelete = (fileName: string, files: IFile[]) => {
                    if (onDelete) onDelete(fileName, files);
                    if (onFileDelete) onFileDelete(fileName);
                };
                return (
                    <UploadField
                        {...(props as UploadFieldWithServicesProps)}
                        {...context}
                        onScan={onFileScan(onScan)}
                        onUpload={onFileUpload(onUpload)}
                        onError={onServiceError(onError)}
                        onDelete={handleDelete}
                        isLoading={configLoading || uploadLoading || fileStateLoading || hasFilePending}
                    >
                        {children}
                    </UploadField>
                );
            }}
        </UploadServiceContext.Consumer>
    );
};

export default UploadFieldWithServices;
