import cx from 'classnames';
import React from 'react';

export type SectionProps = {
    isHighlighted?: boolean;
    isCompact?: boolean;
    noSpaceBelow?: boolean;
    noSpaceAbove?: boolean;
    theme?: 'primary' | 'secondary';
} & React.ComponentPropsWithRef<'div'>;
/**
 * `Section` groups related items together
 */
export const Section = React.forwardRef<HTMLDivElement, SectionProps>(
    ({ children, className, isHighlighted, isCompact, noSpaceBelow, noSpaceAbove, theme = 'primary', ...props }, ref) => {
        return (
            <section
                className={cx(
                    'Section',
                    isHighlighted && 'Section--highlight',
                    isCompact && 'Section--compact',
                    noSpaceBelow && 'Section--no-space-below',
                    noSpaceAbove && 'Section--no-space-above',
                    theme !== 'primary' && `Section--${theme}`,
                    className
                )}
                {...props}
                ref={ref}
            >
                <div className={'container'}>{children}</div>
            </section>
        );
    }
);

export default Section;
