import React from 'react';
import { getId } from '../../../utils';
import { useEffect, useState } from 'react';
import cx from 'classnames';

type HTMLInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface IRangeInputProps<ValueType> extends HTMLInputProps {
    /** Array of strings or numbers */
    options: Array<number | string>;
    /** callback that will be invoked with the new value when value is changed. */
    onChangeValue?: (value: ValueType) => void;
    /** Show a header above the slider, containing the selected value. */
    showValue?: boolean;
    /** subtitle to value */
    subTitle?: string;
    /** validation status of the slider */
    isValid?: boolean;
    /** Helper text that render below input field */
    helpText?: string;
    /** Error text for the field that will be displayed above the slider to the right if the field is invalid */
    errorText?: string;
}

type RangeInputComponent<T = any> = React.ForwardRefExoticComponent<IRangeInputProps<T>>;

/**
 * `RangeInput` allows you to put an arbitrary List of options and renders them along a range.
 *
 */
export const RangeInput: RangeInputComponent = React.forwardRef<HTMLInputElement, IRangeInputProps<any>>(function RangeInputImpl(
    { value, isValid, subTitle, errorText = 'Vælg et niveau', helpText, options = [], onChangeValue, showValue, disabled, onChange, id, ...props },
    ref
) {
    const inputId = id || getId();
    const min = options[0];
    const max = options[options.length - 1];
    const getValueIndex = (val: any) => options.indexOf(val);
    const hintText = isValid === false ? errorText : helpText;

    const [internalValue, setInternalValue] = useState<any>(value ? getValueIndex(value) : 0);

    const coloredThumb = (val: any, min: any, max: any) => ((val - min) * 100) / (max - min);

    const onChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(ev.target.value);
        const newValue = options[ev.target.value];
        const target = ev.target;
        target.style.backgroundSize = coloredThumb(target.value, target.min, target.max) + '% 100%';

        onChangeValue && onChangeValue(newValue);
        onChange && onChange(ev);
    };

    useEffect(() => {
        setInternalValue(value ? getValueIndex(value) : 0);
    }, [value]);
    const showHelptext = !!hintText;

    return (
        <div className={cx('flos-range-input flos-field', { 'flos-field--with-helptext': showHelptext })}>
            <label htmlFor={inputId} className={cx('flos-range-input-value', { 'flos-range-input-value--disabled': disabled })}>
                {subTitle && subTitle}
                {showValue && <span>{options[internalValue]}</span>}
            </label>
            <div className={'flos-range-input-slider'}>
                <input
                    aria-valuemax={max as number}
                    aria-valuemin={min as number}
                    aria-valuenow={options[internalValue] as number}
                    id={inputId}
                    disabled={disabled}
                    max={options.length - 1}
                    min={0}
                    onChange={onChangeHandler}
                    ref={ref}
                    step={1}
                    style={{ backgroundSize: `${coloredThumb(internalValue, options.indexOf(min), options.indexOf(max))}% 100%` }}
                    type="range"
                    value={internalValue}
                    {...props}
                />
            </div>
            {showHelptext && (
                <div className={cx('flos-field-helptext-row')}>
                    <div
                        className={cx('flos-field-helptext-col', {
                            ['flos-field-helptext--valid']: !disabled && isValid,
                            ['flos-field-helptext--invalid']: !disabled && isValid === false,
                        })}
                    >
                        {hintText}
                    </div>
                </div>
            )}
        </div>
    );
});
