import React from 'react';
import cx from 'classnames';
import { HandlerFunction } from '@storybook/addon-actions';
import FlosIcon from '../../core/icon/flos-icon';

type FlowMenuItemProps = {
    /** Step title */
    title: React.ReactNode;
    /** shape of the icon */
    iconShape: string;
    /** if the box should be disabled - add it to next steps */
    isDisabled?: boolean;
    /** if the box should be dimmed - aka NOT active */
    isDimmed?: boolean;
    /** if the box should shown as finished */
    isFinished?: boolean;
    /** Method to call when clicking the FlowMenuItem */
    onClick?: HandlerFunction;
};

/**
 * `FlowMenuItem` is a component used in the FlowMenu
 */

const FlowMenuItem: React.FC<FlowMenuItemProps> = ({
    title,
    iconShape,
    isDisabled,
    isDimmed,
    isFinished,
    onClick = () => console.log('FlowMenuItem is missing onClick prop'),
}: FlowMenuItemProps) => (
    <a className={cx('flos-flow-menu-item', isDimmed && 'dimmed', isDisabled && 'disabled', isFinished && 'finished')} onClick={onClick}>
        <span>
            <FlosIcon shape={iconShape} size={'small'} />
            <p>
                <small>{title}</small>
            </p>
        </span>
    </a>
);

export type { FlowMenuItemProps };
export { FlowMenuItem };
export default FlowMenuItem;
