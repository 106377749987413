import React, { useState } from 'react';
import cx from 'classnames';

/**
 * QuantityFieldProps - Props for the QuantityField component.
 *
 * @interface
 * @property {number} [min=0] - The minimum allowed quantity value.
 * @property {number} [max=999] - The maximum allowed quantity value.
 * @property {number} [defaultValue=0] - The default value for the quantity field.
 * @property {(value: number) => void} [onChange] - Callback function to handle changes in quantity value.
 */
interface QuantityFieldProps {
    /** The minimum allowed quantity value. */
    min?: number;
    /** The maximum allowed quantity value. */
    max?: number;
    /** The default value for the quantity field. */
    defaultValue?: number;
    /** Callback function to handle changes in quantity value. */
    onChange?: (value: number) => void;
}

/**
 * QuantityField - A quantity field component with increment and decrement buttons.
 *
 * @param {QuantityFieldProps} props - The props for the component.
 * @returns {React.Element}
 */
export const QuantityField: React.FC<QuantityFieldProps> = ({ min = 0, max = 999, defaultValue = 0, onChange }) => {
    const [quantity, setQuantity] = useState(defaultValue);
    const [plusPressed, setPlusPressed] = useState(false);
    const [minusPressed, setMinusPressed] = useState(false);

    // Update the quantity value with clamping between min and max
    const updateQuantity = (newQuantity: number) => {
        const clampedQuantity = Math.min(Math.max(newQuantity, min), max);
        setQuantity(clampedQuantity);
        onChange?.(clampedQuantity);
    };

    // Increment the quantity value
    const handleIncrement = (e: React.MouseEvent | React.KeyboardEvent) => {
        e.preventDefault();
        setPlusPressed(true);
        setTimeout(() => setPlusPressed(false), 100);
        updateQuantity(quantity + 1);
    };

    // Decrement the quantity value
    const handleDecrement = (e: React.MouseEvent | React.KeyboardEvent) => {
        e.preventDefault();
        setMinusPressed(true);
        setTimeout(() => setMinusPressed(false), 100);
        updateQuantity(quantity - 1);
    };

    // Handle keyboard interactions for increment and decrement
    const handleKeyDown = (e: React.KeyboardEvent) => {
        e.preventDefault();
        if (e.key === 'ArrowUp' || e.key === '+') {
            handleIncrement(e);
        } else if (e.key === 'ArrowDown' || e.key === '-') {
            handleDecrement(e);
        }
    };

    return (
        <div className="flos-quantity-field" onKeyDown={handleKeyDown} tabIndex={0}>
            <button
                className={cx('flos-quantity-field-button flos-quantity-field-button--minus', { 'flos-quantity-field-button--pressed': minusPressed })}
                onClick={handleDecrement}
            >
                -
            </button>
            <div className="flos-quantity-field-quantity">{quantity}</div>
            <button className={cx('flos-quantity-field-button flos-quantity-field-button--plus', { 'flos-quantity-field-button--pressed': plusPressed })} onClick={handleIncrement}>
                +
            </button>
        </div>
    );
};

export type { QuantityFieldProps };
export default QuantityField;
