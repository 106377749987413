import cx from 'classnames';
import React from 'react';
import { useId } from '../../utils/hooks';
import { FlosIconProps, FlosIcon } from '../icon/flos-icon';

type ButtonVariantProps = {
    /**
     * Will change the visual style of the button
     */
    theme?: 'primary' | 'secondary' | 'ghost' | 'float';
    /**
     * Size controls the mount of padding on the button
     */
    size?: 'large' | 'medium' | 'small';
    /**
     * Sets the min-width of the button
     */
    minWidth?: 'wide' | 'wider' | 'widest';
    /**
     * Will show animated dots after the loadingText and make the button disabled
     */
    isLoading?: boolean;
    /**
     * The content of the button when loading
     */
    loadingText?: React.ReactNode;
    /**
     * Will disable the button so the user canno interact with it
     */
    disabled?: boolean;
};
type iconProps = { iconShape?: FlosIconProps['shape']; iconSize?: FlosIconProps['size'] };

type ButtonProps = ButtonVariantProps & iconProps & React.ComponentPropsWithRef<'button'>;

/**
 * `Button` is a wrapper for `<button>` html element, it accepts **all** props a `<button>` element accepts in addition of props listed below.
 * You can use `ref` to access the underlying `<button>` element
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, type = 'button', theme = 'primary', size, minWidth, isLoading, loadingText, id, disabled, iconShape, iconSize, children, ...props }, ref) => {
        const uniqueId = useId(id);
        const isIconButton = !!iconShape;
        const isIconOnly = !children && isIconButton;
        const isGhostButton = theme === 'ghost';
        const iconSizes = size
            ? {
                  small: 16,
                  medium: iconSize,
                  large: 32,
              }[size]
            : iconSize;
        const buttonClassnames = cx(
            'flos-button',
            {
                [`flos-button--${theme}`]: theme && theme !== 'primary',
                [`flos-button--${size}`]: !!size,
                [`flos-button--${minWidth}`]: !isIconOnly && !!minWidth,
                'flos-button--icon': isIconButton,
                'flos-button--icon-only': !isLoading && isIconOnly,
            },
            className
        );
        const iconClassnames = cx({ 'is-logo-blue': theme === 'secondary' });
        return (
            <button
                className={buttonClassnames}
                type={type}
                id={id}
                disabled={disabled}
                key={`${uniqueId}--${type}`} // See https://github.com/facebook/react/issues/8554
                {...props}
                ref={ref}
            >
                {isLoading ? (
                    <>
                        {loadingText}
                        <span className="loading-dots" style={{ display: 'inline-block', marginLeft: 0 }} />
                    </>
                ) : (
                    <>
                        {isGhostButton && isIconButton && <FlosIcon shape={iconShape} size={iconSizes} className={iconClassnames} />}
                        {children && children}
                        {!isGhostButton && isIconButton && <FlosIcon shape={iconShape} size={iconSizes} className={iconClassnames} />}
                    </>
                )}
            </button>
        );
    }
);
Button.displayName = 'Button';

export type { ButtonProps };
export { Button };
export default Button;
