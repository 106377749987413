import React from 'react';
import cx from 'classnames';

type ActionMenuItemProps = React.ComponentPropsWithoutRef<'li'>;

const ActionMenuItem = ({ children, ...props }: ActionMenuItemProps): React.ReactElement => {
    return (
        <li className={cx('site-header-menu-item')} {...props}>
            {children}
        </li>
    );
};

export type { ActionMenuItemProps };
export { ActionMenuItem };
export default ActionMenuItem;
