/**
 * format user-provided phone number
 * @param phoneNumber phone number that user has provided
 * @param isInternational state if the phone number could be number other than Danish
 */
export function formatPhone(phoneNumber: string | number | undefined, isInternational = false) {
    if (!phoneNumber || isInternational) {
        return phoneNumber;
    }

    const numberGroups = phoneNumber
        .toString()
        .replace(/\s/g, '')
        .match(/.{1,2}/g);

    return numberGroups ? numberGroups.join(' ') : phoneNumber;
}

/**
 * format user-provided cpr number
 * @param cprNumber cpr number that user has provided
 */
export function formatCprNumber<T extends string | number | undefined>(cprNumber: T) {
    if (!cprNumber) {
        return cprNumber;
    }

    const numberGroups = cprNumber
        .toString()
        .replace(/\s/g, '')
        .match(/.{1,6}/g);

    return numberGroups ? numberGroups.join('-') : cprNumber;
}

function withThousandSeparator(numberText: string, separator: string) {
    const chars = numberText.split('');
    let result = '';

    chars.forEach((char, i) => {
        const maybeSeparator = i < numberText.length - 1 && (numberText.length - i - 1) % 3 === 0 && char !== '-' ? separator : '';

        result += char + maybeSeparator;
    });

    return result;
}

/**
 * Format number with thousand separator and decimal.
 *
 * See it in action [here](#format-number-example).
 *
 * @param moneyValue the value that you want to format. If you pass a string, we will treat `.` as thousandSeparator. If `.` is decimal in your case, cast it to `number` before pass to this function.
 * @param options allows you to override default
 * @param options.thousandSeparator default to "."
 * @param options.decimalSeparator default to ","
 */
export function formatNumber<T>(moneyValue: string | number | T, options: { thousandSeparator?: string; decimalSeparator?: string } = {}): string | T {
    const { thousandSeparator = '.', decimalSeparator = ',' } = options;
    const moneyValueType = typeof moneyValue;
    if (moneyValueType === 'string' || moneyValueType === 'number') {
        const moneyText = moneyValueType === 'string' ? (moneyValue as string) : String(moneyValue).replace('.', decimalSeparator);

        return (
            moneyText &&
            moneyText
                .split(decimalSeparator)
                .map((value, index) => (index === 0 ? withThousandSeparator(value, thousandSeparator) : value))
                .join(decimalSeparator)
        );
    }

    return moneyValue as T;
}
