import { IFile } from './types';

interface IFetchRequestConfig {
    url: string;
    headers: HeadersInit;
    abortSignal?: AbortSignal;
}

interface IUploadFileRequestConfig extends IFetchRequestConfig {
    file: Blob;
    data: {
        fileName: string;
        metadata: { environment?: string; encodedfilename: string };
    };
}

/**
 * Fetches client configuration from the provided URL.
 * @param {IFetchRequestConfig} config - Configuration object for fetching client configuration.
 * @returns {Promise<any>} A Promise that resolves to the fetched client configuration data.
 * @throws {Error} Will throw an error if the fetch request fails or the response is not OK.
 */
export const getClientConfig = async ({ url, headers, abortSignal }: IFetchRequestConfig): Promise<any> => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers,
            signal: abortSignal,
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Fetches files from the provided URL.
 * @param {IFetchRequestConfig} config - Configuration object for fetching files.
 * @returns {Promise<any>} A Promise that resolves to the fetched files data.
 * @throws {Error} Will throw an error if the fetch request fails or the response is not OK.
 */
export const fetchFiles = async ({ url, headers, abortSignal }: IFetchRequestConfig): Promise<IFile[]> => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers,
            signal: abortSignal,
        });

        if (!response.ok) {
            throw new Error(await response.text());
        }

        return await response.json();
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Uploads a file to the server using the provided configuration.
 * @param {IUploadFileRequestConfig} config - Configuration object for uploading a file.
 * @returns {Promise<JSON>} A Promise that resolves upload response that contain presigned url .
 * @throws {Error} Will throw an error if the upload request fails or the response is not OK.
 */
export const uploadFile = async ({ url, data, headers, file }: IUploadFileRequestConfig): Promise<JSON> => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(JSON.parse(await response.text()).errorMessage);
        }

        const responseData = await response.json();

        const formData = new FormData();
        Object.entries(responseData.fields).forEach(([k, v]) => {
            formData.append(k, v as string | Blob);
        });

        formData.append('file', file, data.fileName);

        const presignedUrlResponse = await fetch(responseData.url, {
            method: 'POST',
            body: formData,
        });

        if (!presignedUrlResponse.ok) {
            throw new Error();
        }

        return responseData;
    } catch (error) {
        throw new Error(error.message);
    }
};

/**
 * Deletes a file from the server using the provided URL.
 * @param {IFetchRequestConfig} config - Configuration object for deleting a file.
 * @returns {Promise<string | false>} A Promise that resolves to the deleted file key or `false` if the file key is not a string.
 * @throws {Error} Will throw an error if the delete request fails or the response is not OK.
 */
export const deleteFile = async ({ url, headers, abortSignal }: IFetchRequestConfig): Promise<string | false> => {
    try {
        const response = await fetch(url, {
            method: 'DELETE',
            headers,
            signal: abortSignal,
        });

        if (!response.ok) {
            throw new Error(JSON.parse(await response.text()).errorMessage);
        }

        const data = await response.text();

        return typeof data === 'string' ? data : false;
    } catch (error) {
        throw new Error(error.message);
    }
};
