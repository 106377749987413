import React from 'react';
import cx from 'classnames';

type LinkBoxGroupProps = {
    stacked?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

export const LinkBoxGroup = ({ children, stacked, className, ...props }: LinkBoxGroupProps): React.ReactElement => {
    const arrayChildren = React.Children.toArray(children);
    return (
        <div className={cx(stacked ? 'boxlink-stack' : 'flos-grid boxlink-group', className)} {...props}>
            {React.Children.map(arrayChildren, (child: React.ReactElement) => {
                return <div className={cx('boxlink-wrapper', !stacked && 'boxlink-wrapper--grouped')}>{React.cloneElement(child as React.ReactElement)}</div>;
            })}
        </div>
    );
};

export type { LinkBoxGroupProps };
