import React from 'react';

/**
 * Helper function to get the text for a particular key.
 * When the key is not available, the original key will be returned and a message will be logged.
 */
export type GetLabel = (key: string) => string;

export const ResourceContext = React.createContext<GetLabel>((x) => x);

ResourceContext.displayName = 'Resource';
