import React from 'react';
import cx from 'classnames';
type LinkBoxListProps = React.ComponentPropsWithoutRef<'ul'>;

export const LinkBoxList = ({ children, className, ...props }: LinkBoxListProps): React.ReactElement => {
    const arrayChildren = React.Children.toArray(children);
    return (
        <ul className={cx('boxlink-list', className)} {...props}>
            {React.Children.map(arrayChildren, (child: React.ReactElement) => {
                return <li>{React.cloneElement(child as React.ReactElement)}</li>;
            })}
        </ul>
    );
};

export type { LinkBoxListProps };
