import React from 'react';
import cx from 'classnames';

type SectionHeaderProps = {
    /**
     * Title of the header - add eg. paragraphs by applying children
     */
    title?: string;
} & JSX.IntrinsicElements['div'];

/**
 * `SectionHeader` is the header for a `Section` component. By default it will renders a `h2` tag.
 * If you wish to render other html tags, use `children` props.
 */
export const SectionHeader = ({ className, title, ...props }: SectionHeaderProps) => {
    return (
        <div className={cx('Section-header', className && className)} {...props}>
            {title && <h2>{title}</h2>}
            {props.children && props.children}
        </div>
    );
};
