import React, { useCallback } from 'react';
import { Base64 } from 'js-base64';
import { getInfectedRejectionErr, isErrorExist, getScanningRejectionErr, getConfigRejectionErr, getUploadRejectionErr, getStateRejectionErr } from './helpers';
import { getClientConfig, fetchFiles, uploadFile } from './upload-service';
import { IClientConfig, IRequestHeader, IFileError, IFile, SCAN_STATUS } from './types';

/**
 * Contains custom hooks used by the upload service provider.
 * @exports useUploadClientConfig - a hook that manages the client configuration for the upload service provider.
 * @exports useFileState - a hook that manages the file state for the upload service provider.
 * @exports useUploadFile - a hook that manages the file upload for the upload service provider.
 */

/**
 * A custom hook that manages the client configuration for the upload service provider.
 * @param params - an object containing configuration parameters for the client.
 * @param params.maxUploadFileSizeInMb - the maximum size of a file that can be uploaded in megabytes.
 * @param params.minUploadFileSize - the minimum size of a file that can be uploaded in bytes.
 * @param params.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param params.requestHeaders - an object containing request headers to be sent with API requests.
 * @returns an array containing the following elements:
 *   - [0] a list of errors that have occurred during client configuration.
 *   - [1] a boolean indicating whether the client configuration is currently being loaded.
 *   - [2] the client configuration object for the upload service provider.
 *   - [3] a function to set the client configuration object.
 */
export const useUploadClientConfig = (params: {
    maxUploadFileSizeInMb: number; // in Mb
    minUploadFileSize: number; // in byte
    apiBaseUrl: string;
    requestHeaders: IRequestHeader;
}): [IFileError[] | [], boolean, IClientConfig, React.Dispatch<React.SetStateAction<IClientConfig>>] => {
    const { apiBaseUrl, requestHeaders, maxUploadFileSizeInMb = 20, minUploadFileSize = 32 } = params;
    const [clientConfig, setClientConfig] = React.useState<IClientConfig>({
        acceptedExtensions: [],
        consumerBucket: '',
        friendlyName: '',
        maxUploadFileSizeInMb,
        minUploadFileSize,
    });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<[IFileError] | []>([]);
    const request = React.useCallback(async () => {
        setIsLoading(true);
        const data = await getClientConfig({
            url: `${apiBaseUrl}/upload/v1/config`,
            headers: { ...requestHeaders },
        })
            .catch((error: Error) => {
                setErrors([getConfigRejectionErr('', error.message)]);
            })
            .finally(() => {
                setIsLoading(false);
            });
        if (!data) {
            setIsLoading(false);
            return;
        }
        const { consumerBucket, friendlyName } = data;
        const acceptedExtensions = Object.keys(data.supportedExtensions).map((ext) => `.${ext}`);
        setClientConfig((currConfig) => ({ ...currConfig, consumerBucket, friendlyName, acceptedExtensions }));
        setIsLoading(false);
    }, [requestHeaders, apiBaseUrl]);
    React.useEffect(() => {
        request();
    }, [request]);

    return [errors, isLoading, clientConfig, setClientConfig];
};

/**
 * A custom hook that manages the file state for the upload service provider.
 * @param params - an object containing configuration parameters for the hook.
 * @param params.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param params.requestHeaders - an object containing request headers to be sent with API requests.
 * @param params.files - an array of files to be managed by the hook.
 * @returns an array containing the following elements:
 *  - [0] a list of errors that have occurred during file state management.
 *  - [1] a boolean indicating whether the file state is currently being loaded.
 *  - [2] a boolean indicating whether there are files that are currently being scanned.
 *  - [3] a function to set the scanned file list.
 *  - [4] a function to accept a callback triggered when the file state changes.
 *  - [5] an array of file objects that have been scanned for viruses.
 */
export const useFileState = <T extends IFile[], U extends (scannedFiles: T) => void>(params: {
    files: T;
    apiBaseUrl: string;
    requestHeaders: IRequestHeader;
}): [IFileError[], boolean, boolean, React.Dispatch<React.SetStateAction<T>>, (arg: U) => U, T] => {
    const callbackRef = React.useRef<U>();
    const { apiBaseUrl, requestHeaders, files } = params;
    const [scanFileList, setScanFileList] = React.useState<T>([] as unknown as T);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<IFileError[] | []>([]);
    const counter = React.useRef<number>(0);
    const delayInMiliseconds = 2000;

    const onFileScan = useCallback((callback?: U) => {
        if (callback) callbackRef.current = callback;
        return callbackRef.current as U;
    }, []);

    const request = React.useCallback(async () => {
        if (!requestHeaders['x-top-task-id']) return;
        setIsLoading(true);

        const data = await fetchFiles({
            url: `${apiBaseUrl}/upload/v1/state`,
            headers: { ...requestHeaders },
        }).catch((error: Error) => {
            setErrors((currErrors) => {
                if (isErrorExist('', currErrors)) return currErrors;
                return [...currErrors, getStateRejectionErr('', error.message)];
            });
        });

        if (!data) {
            setIsLoading(false);
            return;
        }

        data.forEach((newFile: IFile) => {
            if (newFile.scanStatus === SCAN_STATUS.INFECTED) {
                setErrors((currErrors) => {
                    if (isErrorExist(newFile.key, currErrors)) return currErrors;
                    return [...currErrors, getInfectedRejectionErr(newFile.key)];
                });
            }
            if (newFile.scanStatus === SCAN_STATUS.ERROR) {
                setErrors((currErrors) => {
                    if (isErrorExist(newFile.key, currErrors)) return currErrors;
                    return [...currErrors, getScanningRejectionErr(newFile.key)];
                });
            }
        });
        if (callbackRef.current) callbackRef.current(data as T);
        setScanFileList(data as T);

        setIsLoading(false);
    }, [requestHeaders, apiBaseUrl]);

    const hasFilePending = React.useMemo(() => scanFileList.some((file) => file.scanStatus === SCAN_STATUS.PENDING || file.scanStatus === SCAN_STATUS.SCANNING), [scanFileList]);

    React.useEffect(() => {
        let timeoutId: number;
        if (hasFilePending && counter.current < 30) timeoutId = window.setTimeout(() => request(), delayInMiliseconds);
        return () => {
            if (timeoutId) window.clearTimeout(timeoutId);
        };
    }, [scanFileList, request, hasFilePending]);

    React.useEffect(() => {
        // make a initial request regardless
        request();
    }, [files, request]);

    return [errors, isLoading, hasFilePending, setScanFileList, onFileScan, scanFileList];
};

/**
 * A custom hook that manages the upload state for the upload service provider.
 * @param params - an object containing configuration parameters for the hook.
 * @param params.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param params.requestHeaders - an object containing request headers to be sent with API requests.
 * @param params.files - an array of files to be uploaded.
 * @param props.metaData - extra info to attach together with the file uploaded
 * @returns an array containing the following elements:
 *   - [0] a list of errors that have occurred during file upload.
 *   - [1] a boolean indicating whether the files are currently being uploaded.
 *   - [2] a function accept a callback to handled upload loading state changed.
 *   - [3] an array of files that have been uploaded to S3 bucket.
 *   - [4] an array of files that have been rejected due to various reasons (e.g., file size too large).
 */
export const useUploadFile = <T extends IFile[], U extends (files: T, loading?: boolean) => void>({
    apiBaseUrl,
    files,
    requestHeaders,
    metaData,
}: {
    apiBaseUrl: string;
    files: T;
    requestHeaders: IRequestHeader;
    metaData?: { [key: string]: string };
}): [IFileError[], boolean, (arg: U) => U, T, T?] => {
    const callbackRef = React.useRef<U>();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [uploadedFileList, setUploadedFileList] = React.useState<T>([] as unknown as T);
    const [rejectedFileList, setRejectedFileList] = React.useState<T>([] as unknown as T);
    const [errors, setErrors] = React.useState<IFileError[] | []>([]);

    const onFileUpload = useCallback((callback?: U) => {
        if (callback) callbackRef.current = callback;
        return callbackRef.current as U;
    }, []);

    const request = React.useCallback(
        async (newFiles: T) => {
            setIsLoading(true);
            if (callbackRef.current) callbackRef.current(newFiles as T, true);
            const toUploadList: T = [] as unknown as T;
            const toRejectList: T = [] as unknown as T;
            const toShowErrors: IFileError[] = [];
            await Promise.allSettled(
                newFiles.map(async (file) => {
                    if (!file) return Promise.resolve();
                    return await uploadFile({
                        url: `${apiBaseUrl}/upload/v1/upload`,
                        data: {
                            fileName: file.key,
                            metadata: { encodedfilename: Base64.encode(file.key), ...metaData },
                        },
                        headers: { ...requestHeaders },
                        file: (file.$metaData as { [key: string]: string | Blob }).file as Blob,
                    })
                        .then(() => {
                            toUploadList.push(file);
                        })
                        .catch((error: Error) => {
                            toRejectList.push(file);
                            toShowErrors.push(getUploadRejectionErr(file.key, error.message));
                        });
                })
            )
                .then(() => {
                    // only fire everything when requests is completed
                    // ? To prevent firing same amount of `/state` API when upload multiple files.
                    if (toUploadList.length) {
                        setUploadedFileList((currUploadedFiles) => {
                            return [...currUploadedFiles, ...toUploadList] as T;
                        });
                    }
                    if (toShowErrors.length) {
                        setErrors((currErrors) => {
                            return [...currErrors, ...toShowErrors];
                        });
                    }
                    if (toRejectList.length) {
                        setRejectedFileList((currRejectedFiles) => {
                            return [...currRejectedFiles, ...toRejectList] as T;
                        });
                    }
                })
                .finally(() => {
                    if (callbackRef.current) callbackRef.current(toUploadList as T, false);
                    setIsLoading(false);
                });
        },
        [apiBaseUrl, requestHeaders, metaData]
    );
    React.useEffect(() => {
        if (files.length) request(files);
    }, [files, request]);

    return [errors, isLoading, onFileUpload, uploadedFileList, rejectedFileList];
};
