import React from 'react';
import cx from 'classnames';
import { Link } from '../../navigation/flos-link/link';
import { FlosIcon } from '../../../core/icon/flos-icon';
import { CondWrap } from '../../../utils/jsx-utils';

type LinkBoxProps = {
    /**
     * The title of the link
     */
    title: string;
    /**
     * An optional description in the link. See examples for usage
     */
    description?: React.ReactNode;
    /**
     * An optional note in the link. See examples for usage
     */
    note?: React.ReactNode;
    /**
     * Which focus should the link have? Currently this is only useful in conjunction with the `dynamic` theme (see below)
     */
    focus?: 'low' | 'medium' | 'high';
    /**
     * An optional icon for the link
     */
    iconShape?: string;
    /**
     * An optional icon variant to use. Currently only `rounded` is supported
     */
    iconVariant?: 'rounded';
    /**
     * Which theme to use for the link
     */
    theme?: 'highlight' | 'transparent' | 'dynamic';
    /**
     * Currently this is only used with `external` value to replace the default blue arrow with a 'launch' icon (indicates an external link)
     */
    variant?: 'external';
    arrowArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'a'>;

export const LinkBox = ({ iconShape, iconVariant, title, description, note, focus, className, theme, variant, arrowArea, ...props }: LinkBoxProps): React.ReactElement => {
    return (
        <Link
            hasIcon={!arrowArea as any}
            theme={variant === 'external' ? 'external' : 'primary'}
            className={cx(
                'boxlink',
                note && 'has-note',
                arrowArea && 'boxlink--has-arrow-area',
                focus && `boxlink--focus-${focus}`,
                theme && `boxlink--${theme}`,
                className && className
            )}
            {...props}
        >
            <CondWrap condition={note} wrapper={(children: React.ReactElement) => <div className={'u-flex-box u-flex-box--center u-flex-box--space-between'}>{children}</div>}>
                <>
                    <div className={'u-flex-box u-flex-box--nowrap u-flex-box--center u-flex-box--gap-small'}>
                        {iconShape && (
                            <FlosIcon
                                className={cx(iconVariant === 'rounded' ? 'flos-icon--highlight' + (theme === 'highlight' ? '-secondary' : '') : '', 'boxlink-icon')}
                                shape={iconShape}
                                size={iconVariant == 'rounded' ? 'xsmall' : 'medium'}
                            />
                        )}
                        <div>
                            <span>{title}</span>
                            {description && <p className={'small'}>{description}</p>}
                        </div>
                    </div>
                    {note && (
                        <div className={'boxlink-note'}>
                            <p>{note}</p>
                        </div>
                    )}
                </>
            </CondWrap>
            {arrowArea && <div>{arrowArea}</div>}
        </Link>
    );
};

export type { LinkBoxProps };
export default LinkBox;
