import React, { useCallback } from 'react';
import { IFile, IFileError, DEFAULT_ACCEPTED_ATTRS, SCAN_STATUS, FileNameType, IRequestHeader, IConfig, IUploadServiceContext } from './types';
import { useUploadClientConfig, useFileState, useUploadFile } from './use-upload-services';
import { getDeletingRejectionErr, getInvalidTypeRejectionErr, getTooLargeRejectionErr, getTooManyFilesRejectionErr, getTooSmallRejectionErr, getUniqueFileName } from './helpers';
import accept from 'attr-accept';
import { deleteFile } from './upload-service';

/**
 * Represents the props for the UploadServiceProvider component.
 * @param {IConfig} config - a configuration object for the upload service provider.
 * @param {JSX.Element} children - a JSX element representing the child components of the upload service provider.
 */
export type UploadServiceProviderProps = {
    /** a configuration object for the upload service provider. */
    config: IConfig;
    /** a JSX element representing the child components of the upload service provider. */
    children: JSX.Element;
};

/** Represent the initial state for the upload service provider */
const initialState: IUploadServiceContext<IFile[], IFileError[]> = {
    acceptedExtensions: DEFAULT_ACCEPTED_ATTRS,
    scannedFiles: [],
    selectedFiles: [],
    errors: [],
    onFileChange: () => Promise.resolve(),
    onFileDelete: () => Promise.resolve(),
    onFileScan: () => () => null,
    onFileUpload: () => () => null,
    onServiceError: () => () => null,
    fileStateLoading: false,
    configLoading: false,
    uploadLoading: false,
    disabled: false,
};

/** Represents the context object for the upload service provider */
export const UploadServiceContext = React.createContext<IUploadServiceContext<IFile[], IFileError[]>>(initialState);

/**
 * A React component that provides the file upload service.
 * @param props.config - an object containing configuration parameters for the file upload service.
 * @param props.config.maxUploadFileSizeInMb - the maximum file size (in MB) allowed for upload.
 * @param props.config.minUploadFileSize - the minimum file size (in bytes) allowed for upload.
 * @param props.config.apiBaseUrl - the base URL for the API endpoint (staging/production).
 * @param props.config.appname - required by C9 platform API.
 * @param props.config.clientId - unique ID given by C9 platform depending on team or usecase.
 * @param props.config.taskId - unique ID generated based on session.
 * @param props.config.metaData - extra info to attach together with the file uploaded.
 * @param props.config.totalFilesLimit - the maximum number of files that can be uploaded.
 * @param children - the child components that will be rendered within this component.
 * @returns a React component that provides the file upload service.
 */
export const UploadServiceProvider = <T extends IFile, U extends IFileError[]>({ config, children }: UploadServiceProviderProps) => {
    // Set default values for the maximum and minimum file size limits, and the maximum file count.
    const { maxUploadFileSizeInMb = 20, minUploadFileSize = 32, apiBaseUrl, taskId, clientId, appname, metaData, totalFilesLimit = 50 } = config;
    // Set up state variables for selected files and errors.
    const [selectedFiles, setSelectedFiles] = React.useState<T[]>([]);
    const [selectedFileError, setSelectedFileError] = React.useState<U>([] as unknown as U);
    const [disabled, setDisabled] = React.useState<boolean>(false);

    // Determine the maximum number of files that can be uploaded, based on the configuration.
    const MAX_FILES_COUNT = totalFilesLimit ? (totalFilesLimit > 50 ? 50 : totalFilesLimit) : 10;
    const initSuccessRef = React.useRef<boolean>(false);
    const onErrorCallbackRef = React.useRef<(errs: U) => void>(() => null);

    // Set up a function for setting file errors.
    const addSelectedFileError = (error: IFileError) => setSelectedFileError((prevErrors) => [...prevErrors, error] as U);

    // Set up the request headers for API calls.
    const requestHeaders = React.useMemo(() => {
        const headers: IRequestHeader = {
            'x-top-appname': appname,
            'x-top-client-id': clientId,
            'x-top-task-id': taskId || '',
        };
        if (metaData?.environment) headers['x-top-env'] = metaData.environment;
        return headers;
    }, [appname, clientId, taskId, metaData]);

    // Fetch the client configuration from the server.
    const [configError, configLoading, clientConfig] = useUploadClientConfig({
        maxUploadFileSizeInMb,
        minUploadFileSize,
        apiBaseUrl,
        requestHeaders,
    });
    // Upload any new files that have been selected.
    const [uploadError, uploadLoading, onFileUpload, uploadedFileList] = useUploadFile({
        apiBaseUrl,
        requestHeaders,
        files: selectedFiles,
        metaData,
    });
    // Fetch the state of any files already uploaded.
    const [stateError, fileStateLoading, hasFilePending, setScanFileList, onFileScan, scannedFiles] = useFileState({
        apiBaseUrl,
        requestHeaders,
        files: uploadedFileList,
    });

    // Set up a function to accept callbacks for handling service errors.
    const onServiceError = useCallback((callback?: (errs: U) => void) => {
        if (callback) onErrorCallbackRef.current = callback;
        return onErrorCallbackRef.current;
    }, []);

    // Combine all errors into a single array.
    const allErrors = React.useMemo(() => {
        const errs = [...selectedFileError, ...stateError, ...uploadError, ...configError];
        if (onErrorCallbackRef.current) onErrorCallbackRef.current(errs as U);
        return errs;
    }, [selectedFileError, stateError, uploadError, configError]);

    // init the selected files list after files uploaded
    React.useEffect(() => {
        setSelectedFiles([]);
    }, [uploadedFileList]);

    // Update the init success ref when the configuration is loaded or there is an error.
    React.useEffect(() => {
        if (configError.length || configLoading) initSuccessRef.current = false;
        else initSuccessRef.current = true;
    }, [configError.length, configLoading]);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>, parser?: (file: T[]) => T[]) => {
        event.preventDefault();
        setSelectedFiles(() => {
            const newSelectedFiles: IFile[] = [];
            const currentFilesCount = scannedFiles.length;
            Array.prototype.slice.call(event.target.files).forEach((newFile: File, index: number) => {
                const newFileSizeInMb = newFile.size / 1000000;
                const newFileName = getUniqueFileName(newFile.name, scannedFiles);

                const isAcceptedAttr = accept(
                    {
                        name: newFileName,
                        type: newFile.type,
                    },
                    clientConfig.acceptedExtensions.toString()
                );
                if (currentFilesCount + (index + 1) > MAX_FILES_COUNT) {
                    addSelectedFileError(getTooManyFilesRejectionErr(newFileName, MAX_FILES_COUNT));
                } else if (!isAcceptedAttr) {
                    addSelectedFileError(getInvalidTypeRejectionErr(newFileName));
                } else if (newFileSizeInMb > clientConfig.maxUploadFileSizeInMb) {
                    addSelectedFileError(getTooLargeRejectionErr(newFileName, clientConfig.maxUploadFileSizeInMb));
                } else if (newFile.size < clientConfig.minUploadFileSize) {
                    addSelectedFileError(getTooSmallRejectionErr(newFileName, clientConfig.minUploadFileSize));
                } else {
                    newSelectedFiles.push({
                        key: newFileName,
                        scanStatus: SCAN_STATUS.PENDING,
                        $metaData: { file: newFile },
                    });
                }
            });
            if (!parser) return newSelectedFiles as T[];
            return parser(newSelectedFiles as T[]).map((file) => ({
                ...file,
                key: getUniqueFileName(file.key, [...scannedFiles, ...newSelectedFiles]),
            })) as T[];
        });
    };

    const onFileDelete = async (fileName: FileNameType) => {
        // delete from selected file list
        const fileIndex = scannedFiles.findIndex((file) => file.key === fileName);
        // When files are deleted, remove them from the selected files list.

        // If file had been uploaded to S3 bucket, call delete file service,
        if (fileIndex >= 0) {
            const fileToDelete = { ...scannedFiles[fileIndex] };
            setScanFileList((currScannedFiles) => {
                // Delete the file directly without waiting
                return currScannedFiles.filter((f) => f.key !== fileName);
            });
            return deleteFile({
                url: `${apiBaseUrl}/upload/v1/remove?fileName=${fileName}`,
                headers: { ...requestHeaders },
            }).catch((errorMessage: Error) => {
                addSelectedFileError(getDeletingRejectionErr(fileName, errorMessage.message));
                setScanFileList((currScannedFiles) => {
                    // Restore the file if deletion failed
                    return [...currScannedFiles, fileToDelete];
                });
            });
        }
        return Promise.resolve();
    };

    // Determine if the upload button should be disabled based on the current state.
    // To force re-rendering due to the use of a ref
    React.useEffect(
        () => setDisabled(!initSuccessRef.current || hasFilePending || configLoading || uploadLoading || fileStateLoading),
        [initSuccessRef.current, hasFilePending, configLoading, uploadLoading, fileStateLoading]
    );

    // Determine if an invalid file has been selected.
    const invalidFileSelected = selectedFileError.length > 0;

    // Render the child components, passing down the file upload service context.
    return (
        <UploadServiceContext.Provider
            value={{
                acceptedExtensions: clientConfig.acceptedExtensions,
                errors: allErrors,
                onFileChange,
                onFileDelete,
                onFileScan,
                onFileUpload,
                onServiceError,
                scannedFiles,
                selectedFiles,
                configLoading,
                fileStateLoading,
                uploadLoading,
                hasFilePending,
                disabled,
                invalidFileSelected,
            }}
        >
            {children}
        </UploadServiceContext.Provider>
    );
};

/**
 * A higher-order component that wraps a component with the UploadServiceContext consumer.
 * @param WrappedComponent - the component to be wrapped with the UploadServiceContext consumer.
 * @returns a new component that wraps the input component with the UploadServiceContext consumer.
 */
export function withUploadService<T extends IFile[], U extends IUploadServiceContext<T, IFileError[]>>(WrappedComponent: React.ComponentType<U>) {
    return function ComponentWithUploadService(props: Omit<U, keyof IUploadServiceContext<T, IFileError[]>>) {
        return <UploadServiceContext.Consumer>{(context) => <WrappedComponent {...(props as U)} {...context} />}</UploadServiceContext.Consumer>;
    };
}
