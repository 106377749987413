import React from 'react';
import * as Progress from '@radix-ui/react-progress';
import cx from 'classnames';

export type LoadingBarProps = {
    /** Progress in % */
    progress?: number;
} & JSX.IntrinsicElements['div'];

/**
 * `LoadingBar` is used as indicator of loading state.
 */
const LoadingBar = ({ progress = 0, ...rest }: LoadingBarProps) => {
    return (
        <Progress.Root className={cx('loading-bar-root', rest.className)} value={progress}>
            <Progress.Indicator className={'loading-bar-indicator'} style={{ transform: `translateX(-${100 - progress}%)` }} />
        </Progress.Root>
    );
};
LoadingBar.displayName = 'LoadingBar';

export { LoadingBar };
export default LoadingBar;
