import React from 'react';
import { ButtonProps } from '../../button/button';

/** Represents the type of the file name */
export type FileNameType = string;

export type FileErrorType = 'FILE_TOO_LARGE' | 'FILE_TOO_SMALL' | 'FILE_INVALID_TYPE' | 'TOO_MANY_FILES' | 'FILE_IS_INFECTED';
export type ServiceErrorType = 'FILE_IS_INFECTED' | 'SCANNING_ERROR' | 'CONFIG_ERROR' | 'DELETING_ERROR' | 'UPLOAD_ERROR' | 'STATE_ERROR';
export type ErrorType = FileErrorType | ServiceErrorType;

export type FileScanStatusType = 'pending' | 'scanning' | 'clean' | 'infected' | 'error';

/**
 * Represents a file to be uploaded, with metadata and a scan status.
 * @property key - a string representing a unique identifier for the file.
 * @property scanStatus - a value from the SCAN_STATUS enum indicating the current scanning status of the file.
 * @property $metaData - an object containing metadata for the file, specifically the File object itself.
 */
export interface IFile {
    key: FileNameType;
    scanStatus: FileScanStatusType;
    $metaData?: { [key: string]: string | Blob };
}

/**
 * Represents an error encountered while uploading a file.
 * @property fileName - a string representing the name of the file that encountered the error.
 * @property error - a string representing the error message for the error encountered.
 */
export interface IFileError {
    fileName?: string;
    error: ErrorType;
    metaData?: { [key: string]: string | number };
}

/**
 * Represents the client configuration object for the upload service.
 * Defines the accepted file extensions, maximum upload file size, and minimum upload file size.
 * @property acceptedExtensions - an array of strings representing the file extensions that are allowed to be uploaded.
 * @property consumerBucket - a string representing the consumer bucket.
 * @property friendlyName -  a string representing the friendly name of the client.
 * @property maxUploadFileSizeInMb - a number representing the maximum size of an uploaded file in megabytes.
 * @property minUploadFileSize - a number representing the minimum size of an uploaded file in bytes.
 */
export interface IClientConfig {
    acceptedExtensions: string[];
    consumerBucket: string;
    friendlyName: string;
    maxUploadFileSizeInMb: number;
    minUploadFileSize: number;
}

/** Represent required header when using upload service API
 * @property x-top-appname - required by C9 platform API.
 * @property x-top-client-id - unique ID given by C9 platform depending on team or usecase.
 * @property x-top-task-id - unique ID generated based on session.
 * @property x-top-env - extra info to attach together with the file uploaded.
 *
 */
export interface IRequestHeader {
    'x-top-appname': string;
    'x-top-client-id': string;
    'x-top-task-id': string;
    'x-top-env'?: string;
}

/**
 * Represents the types of error that might occur during upload process.
 * @property FILE_TOO_LARGE - the file exceed the maximum size
 * @property FILE_TOO_SMALL - the file is below the minimum file size requirement
 * @property FILE_IS_INFECTED - the file found infected during scan process
 * @property FILE_INVALID_TYPE - the file type is not allowed to be upload
 * @property TOO_MANY_FILES - the total of the files is exceed the maxinum length
 * @property CONFIG_ERROR - configuration request failure
 * @property DELETING_ERROR - deleting a file request failure
 * @property UPLOAD_ERROR - uploading a file request failure
 * @property STATE_ERROR - state request failure
 */
export enum ERROR_TYPE {
    FILE_TOO_LARGE = 'FILE_TOO_LARGE',
    FILE_TOO_SMALL = 'FILE_TOO_SMALL',
    FILE_IS_INFECTED = 'FILE_IS_INFECTED',
    FILE_INVALID_TYPE = 'FILE_INVALID_TYPE',
    TOO_MANY_FILES = 'TOO_MANY_FILES',
    CONFIG_ERROR = 'CONFIG_ERROR',
    DELETING_ERROR = 'DELETING_ERROR',
    UPLOAD_ERROR = 'UPLOAD_ERROR',
    STATE_ERROR = 'STATE_ERROR',
}

/**
 * Represents the scan status of a file being uploaded.
 * @property PENDING - the file is waiting to be upload
 * @property SCANNING - the file still scanning in progress
 * @property CLEAN - the file has passed the scanning process
 * @property INFECTED - the file found virus infected during scan process
 * @property ERROR - there is error occur during the scanning process
 */
export enum SCAN_STATUS {
    PENDING = 'pending',
    SCANNING = 'scanning',
    CLEAN = 'clean',
    INFECTED = 'infected',
    ERROR = 'error',
}

/**
 * Represents the configuration options for the upload service provider.
 * @typeDef IConfig
 * @property maxUploadFileSizeInMb - a number representing the maximum size of an uploaded file in megabytes.
 * @property minUploadFileSize - a number representing the minimum size of an uploaded file in bytes.
 * @property apiBaseUrl - a string representing the base URL for the API endpoint.
 * @property appname - a string representing the name of the application.
 * @property clientId - a string representing the unique identifier for the client.
 * @property taskId - a string representing the unique identifier for the task.
 * @property metaData - an object containing any extra metadata associated with the file.
 * @property totalFilesLimit - a number representing the maximum number of files that can be uploaded.
 */
export interface IConfig {
    maxUploadFileSizeInMb?: number;
    minUploadFileSize?: number;
    apiBaseUrl: string; // base URL for the API endpoint (staging/production)
    appname: string; // required by C9 platform API
    clientId: string; // unique ID given by C9 platform depending on team or usecase
    taskId: string | null | undefined; // unique ID generated based on session
    metaData?: { [key: string]: string }; // extra info to attach together with the file uploaded
    totalFilesLimit?: number;
}

/**
 * Represents the context object for the upload service provider.
 * @property acceptedExtensions - an array of accepted file extensions for the upload service.
 * @property configLoading - a boolean indicating whether the client configuration is currently being loaded.
 * @property disabled - a boolean indicating whether the upload field should be disabled.
 * @property errors - an array of errors that have occurred during file upload or processing.
 * @property fileStateLoading - a boolean indicating whether file state is currently being loaded.
 * @property hasFilePending - a boolean indicating whether there are files not being scanned.
 * @property invalidFileSelected - a boolean indicating whether an invalid file has been selected by the user.
 * @property onFileChange - a function that handles file selection, optional callback to format the files before upload
 * @property onFileDelete - a function that handles file deletion.
 * @property onFileScan - a function to receive a callback when scan API is called.
 * @property onFileUpload - a function to receive a callback when before and after upload API is called.
 * @property scannedFiles - an array of files that have been scanned.
 * @property selectedFiles - an array of files that have been selected for upload.
 * @property uploadLoading - a boolean indicating whether files are currently being uploaded.
 */
export interface IUploadServiceContext<T extends IFile[] = IFile[], U extends IFileError[] = IFileError[]> {
    acceptedExtensions?: string[];
    configLoading?: boolean;
    disabled?: boolean;
    errors?: U;
    fileStateLoading?: boolean;
    hasFilePending?: boolean;
    invalidFileSelected?: boolean;
    onFileChange?: (event: React.ChangeEvent<HTMLInputElement>, parser?: (files: T) => T) => void;
    onFileDelete?: (fileName: FileNameType) => void;
    onFileScan: (callback?: (files: T) => void) => (files: T) => void;
    onFileUpload: (callback?: (files: T, loading?: boolean) => void) => (files: T, loading?: boolean) => void;
    onServiceError: (callback?: (errors: U) => void) => (errors: U) => void;
    scannedFiles: T;
    selectedFiles: T;
    uploadLoading?: boolean;
}

export interface UploadButtonProps extends Omit<ButtonProps, 'onChange'> {
    selectedFilesParser?: (files: IFile[]) => IFile[];
    onChange?: React.InputHTMLAttributes<HTMLInputElement>['onChange'];
    acceptedExtensions?: IUploadServiceContext['acceptedExtensions'];
}

type FileActions = 'delete' | 'open';

/**
 * Rpresents interface of UploadField Props
 * @param {FileActions} props.fileAction - a string to determine the action of file button, delete will show delete button, open will show open button
 * @param {function} props.onScan - a callback when scanned file list changed
 * @param {function} props.onUpload - a callback when uploading file is loading
 * @param {function} props.onError - a callback callback when encounter an error
 * @param {function} props.onFileCount - a callback to return file count label text
 * @param {function} props.onFileOpen - a callback when open button is clicked
 * @param {function} props.onInit - a callback after first get files service is called
 * @param {IFileError[]} props.errorMessages - a array of error messages to be shown within the upload component
 * @param {string} props.deleteButtonLabel - label text to be shown in delete button, fileAction must be delete
 * @param {string} props.description - descriptive text that displayed at the bottom of the upload button label
 * @param {string} props.errorLabel - label text to be shown if scanning indicates file has an error
 * @param {string} props.infectedLabel - label text to be shown if scanning indicates file is infected
 * @param {string} props.label - label text to be shown on top left of upload field
 * @param {string} props.openButtonLabel - label text to be shown in open button, fileAction must be open
 * @param {boolean} props.isLoading - a boolean to determine if the upload field is in loading state
 */
export interface UploadFieldProps<T extends IFile[] = IFile[], U extends IFileError[] = IFileError[]>
    extends Omit<React.LabelHTMLAttributes<HTMLLabelElement>, keyof UploadButtonProps>,
        Partial<Omit<UploadButtonProps, 'onError'>> {
    onError?: (errors: U) => void;
    onFileCount?: (count: number) => string;
    onFileOpen?: (fileName: FileNameType) => void;
    onInit?: () => void;
    onScan?: (files: T) => void;
    onUpload?: (files: T, loading: boolean) => void;
    onDelete?: (fileName: FileNameType, files: T) => void;
    fileAction?: FileActions;
    deleteButtonLabel?: string;
    description?: string;
    errorLabel: string;
    errorMessages?: string[];
    infectedLabel: string;
    label?: string;
    openButtonLabel?: string;
    isLoading?: boolean;
    children?: React.ReactNode;
    invalidFileSelected?: IUploadServiceContext['invalidFileSelected'];
    scannedFiles: IUploadServiceContext['scannedFiles'];
}

/**
 * The default accepted file extensions for the upload service.
 */
export const DEFAULT_ACCEPTED_ATTRS = ['.jpg', '.jpeg', '.gif', '.bmp', '.tif', '.tiff', '.png', '.doc', '.docx', '.pdf', '.rtf', '.txt', '.xls', '.xlsx'];

/** Represent value mapping of css classes base on the status provided */
export const CSS_STATUS_MAP = {
    [SCAN_STATUS.CLEAN]: 'is-clean',
    [SCAN_STATUS.INFECTED]: 'has-error',
    [SCAN_STATUS.ERROR]: 'has-error',
    [SCAN_STATUS.PENDING]: 'is-loading',
    [SCAN_STATUS.SCANNING]: 'is-loading',
};

/**
 * Represents the props for the upload field label component.
 * @property className - a string representing the class name for the component.
 * @property label - a string representing the label text for the component.
 * @property fileCounter - a string representing the file counter text for the component.
 */
export interface UploadFieldLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    className?: string;
    label?: string;
    fileCounter?: string;
}
