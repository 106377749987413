import React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { TextareaProps, Textarea } from './textarea';

type TextareaFieldProps = TextareaProps & FlosFieldProps;

/**
 * TextareaField is a composition of `FlosField` and `Textarea` components.
 * If you wish to have more control, you can compose them yourself.
 */
export const TextareaField = React.forwardRef<HTMLTextAreaElement, TextareaFieldProps>(
    (
        { label, wrapperClassName, isValid, errorText, maxLength, minRows = 4, iconShape, onValidityChange, helpText, ...textareaProps },
        ref: React.RefObject<HTMLTextAreaElement>
    ) => {
        return (
            <FlosField
                ref={ref}
                disabled={textareaProps.disabled}
                errorText={errorText}
                helpText={helpText}
                iconShape={iconShape}
                id={textareaProps.id}
                inputId={textareaProps.id}
                isValid={isValid}
                label={label}
                maxLength={maxLength}
                onValidityChange={onValidityChange}
                required={textareaProps.required}
                wrapperClassName={wrapperClassName}
                renderInput={({ getInputProps }) => <Textarea {...getInputProps({ ...textareaProps, minRows, ref })} />}
            />
        );
    }
);

TextareaField.displayName = 'TextareaField';
export type { TextareaFieldProps };
export default TextareaField;
