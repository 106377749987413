import React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { PhoneInput, PhoneInputProps } from './phone-input';
import { useEffect, useState } from 'react';

export type PhoneFieldProps = PhoneInputProps & Omit<FlosFieldProps, 'label'> & { label?: string };

/**
 * PhoneField is a composition of `Field` and `PhoneInput` component.
 * If you wish to have more control, you can compose them yourself.
 */
export const PhoneField = React.forwardRef<HTMLInputElement, Omit<PhoneFieldProps, 'maxLength'>>(
    ({ label = 'Telefonnummer', iconShape, isValid, errorText = 'er ikke korrekt udfyldt', wrapperClassName, editable, onValidityChange, helpText, ...inputProps }, ref) => {
        const [state, setState] = useState<boolean | undefined>(undefined);

        const setStatus = (isPhoneValid?: boolean) => setState(isValid ? isValid : isPhoneValid);

        useEffect(() => {
            setState(isValid);
        }, [isValid]);

        return (
            <FlosField
                ref={ref}
                onValidityChange={onValidityChange}
                isValid={state}
                editable={editable}
                label={label}
                iconShape={iconShape}
                errorText={errorText}
                required={inputProps.required}
                id={inputProps.id}
                disabled={inputProps.disabled}
                wrapperClassName={wrapperClassName}
                helpText={helpText}
                renderInput={({ getInputProps }) => <PhoneInput {...getInputProps({ ...inputProps, ref })} isValidHandler={setStatus} isValid={isValid} />}
            />
        );
    }
);

PhoneField.displayName = 'PhoneField';
export default PhoneField;
