import React from 'react';
import { cloneElement } from 'react';
import cx from 'classnames';
import FlosIcon from '../../../core/icon/flos-icon';

type PaginationProps = {
    /** Variant of the pagination */
    variant?: 'large' | 'small';
    /** Current Page no, starting from 1 */
    currentPage?: number;
    /** List of pages  */
    children: React.ReactElement<React.ComponentPropsWithoutRef<'li'>>[];
    /** Page change event */
    onChange?: (event: React.ChangeEvent) => void;
} & React.ComponentPropsWithoutRef<'nav'>;

const Pagination = ({ onChange, variant = 'large', currentPage = 1, children, className, ...props }: PaginationProps): React.ReactElement => {
    const [currentIndex, setCurrentindex] = React.useState<number>(currentPage);

    const changePage = (pageNo: number) => {
        setCurrentindex(pageNo);
        const changeEvent = new Event('change', { bubbles: true });
        if (changeEvent) {
            if (!changeEvent.target) {
                Object.defineProperty(changeEvent, 'target', { writable: false, value: new EventTarget() });
            }
            (changeEvent.target as any).value = pageNo;
            onChange && onChange(changeEvent as unknown as React.ChangeEvent);
        }
    };
    const goNext = (event: any) => {
        event.preventDefault();
        if (currentIndex < children.length) {
            changePage(currentIndex + 1);
        }
    };
    const goPrevious = (event: any) => {
        event.preventDefault();
        if (currentIndex > 1) {
            changePage(currentIndex - 1);
        }
    };
    const renderPageList = () => {
        if (variant === 'small') {
            return (
                <li>
                    <p className="small">
                        {currentIndex}/{children.length}
                    </p>
                </li>
            );
        } else {
            return children.map((item: any, index) => {
                const pageNo = index + 1;
                return cloneElement(item as any, {
                    key: index,
                    className: cx(pageNo === currentIndex ? 'is-active' : '', pageNo > 5 && 'hidden-xs hidden-sm', pageNo === 5 && 'hidden-xs'),
                    onClick: () => {
                        changePage(pageNo);
                        item.props.onClick && item.props.onClick();
                    },
                    ...item.props,
                });
            });
        }
    };

    React.useEffect(() => {
        if (currentIndex !== currentPage) {
            setCurrentindex(currentPage);
        }
    }, [currentPage]);

    return (
        <nav className={cx('flos-pagination', variant === 'small' && 'flos-pagination--small', className && className)} {...props}>
            <ul>
                <li className={cx(currentIndex === 1 && 'is-disabled')} onClick={goPrevious} role="previous">
                    <a>
                        <FlosIcon shape="arrow-left" className="is-logo-blue" size={16} />
                    </a>
                </li>
                {renderPageList()}
                <li className={cx(currentIndex === children.length && 'is-disabled')} onClick={goNext} role="next">
                    <a>
                        <FlosIcon shape="arrow-right" className="is-logo-blue" size={16} />
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export type { PaginationProps };
export { Pagination };
export default Pagination;
