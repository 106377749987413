import React from 'react';
import FlosIcon from '../../../core/icon/flos-icon';
import cx from 'classnames';

type DropdownItemProps = {
    /**
     * Value of the drop down item
     **/
    value?: string;
    selected?: boolean;
    children?: string;
    onClick?: React.MouseEventHandler<HTMLLIElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLLIElement>;
    iconShape?: string;
};

export const DropdownItem: React.FC<DropdownItemProps> = ({ value, selected, onClick, children, iconShape, ...props }: DropdownItemProps): React.ReactElement => {
    return (
        <li
            className={cx('flos-dropdown-list-item', iconShape && 'flos-dropdown-list-item--with-icon')}
            role={'option'}
            tabIndex={0}
            value={value}
            aria-selected={selected}
            onClick={onClick}
            {...props}
        >
            {iconShape && <FlosIcon shape={iconShape} className={cx(selected ? 'is-logo-blue' : 'is-indigo')} />}
            {children}
        </li>
    );
};

export default DropdownItem;

export type { DropdownItemProps };
