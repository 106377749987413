import React from 'react';
import cx from 'classnames';

type DivProps = React.HTMLAttributes<HTMLDivElement>;

export interface IModalHeaderProps extends Omit<DivProps, 'title'> {
    title?: React.ReactNode;
}

export const ModalHeader: React.FC<IModalHeaderProps> = ({ title, children, className, ...headerProps }) => (
    <div className={cx('topdk-modal__header', className)} {...headerProps}>
        <h4 className="modal-title">{title}</h4>
        {children}
    </div>
);

export default ModalHeader;
