import React from 'react';
import cx from 'classnames';
import { useId } from '../../../utils/hooks';

type SplashProps = {
    /**
     * The title of the splash
     */
    title?: React.ReactNode;
    /**
     * The description of the splash
     */
    description?: React.ReactNode;
    /**
     * If splash should have an image
     */
    imageUrl?: string;
} & Omit<React.ComponentPropsWithoutRef<'a'>, 'title'>;

/**
 * Splash is a circle with content inside.
 */

const Splash = ({ title, description, imageUrl, ...props }: SplashProps) => {
    const id = useId(props.id);

    return (
        <a id={id} className={cx('flos-splash', imageUrl && 'has-image', props.className && props.className)} {...props}>
            {!imageUrl ? (
                <>
                    {title && <h3>{title}</h3>}
                    {description && <p>{description}</p>}
                </>
            ) : (
                <picture>
                    <source media="(min-width: 1200px),(-webkit-min-device-pixel-ratio: 1.5),(min-resolution: 1.5dppx)" srcSet={imageUrl} />
                    <img alt="flos splash image alt text" src={imageUrl} />
                </picture>
            )}
        </a>
    );
};

export type { SplashProps };
export { Splash };
