import React from 'react';
import { Dropdown, DropdownProps } from '../navigation/dropdown/dropdown';
import { DropdownItem } from '../navigation/dropdown/dropdown-item';
import { useState } from 'react';
import type { StakeholderClientProps } from '../../types/types';
import FlosIcon from '../../core/icon/flos-icon';
import { IconBoxList } from '../boxes/icon-box/icon-box-list';
import IconBox from '../boxes/icon-box/icon-box';

type StakeholderSelectorProps = {
    stakeholderFilter?: 'GROUPED' | 'EXPANDED' | 'BROKER' | 'ACTIVEUSER' | 'ACTIVEUSER_AND_CVR';
    /**
     * Name of the consuming app - needed for the topContext.stakeholder
     **/
    appName: string;
    /**
     * Callback when selected viewId changes
     **/
    onChangeHandler?: (viewId: string) => void;
    /**
     * Callback if error occurs on getViews or setSelectedView on stakeholder
     **/
    onErrorHandler?: (errorMessage: string) => void;
} & Omit<DropdownProps, 'value' | 'onChange' | 'children' | 'size' | 'label'>;

type StakeholderViewProps = {
    id: string;
    name: string;
    selected: boolean;
    filter: string;
    type: string;
    activeUser: boolean;
    source: string;
    totalHouseholdMember: number;
};

export const StakeholderSelector = React.forwardRef<HTMLButtonElement, StakeholderSelectorProps>(
    ({ stakeholderFilter = 'GROUPED', appName, onChangeHandler, onErrorHandler, ...props }: StakeholderSelectorProps, ref) => {
        const stakeholderClient: StakeholderClientProps = window.topContext.stakeholder;
        const [views, setViews] = useState<StakeholderViewProps[]>([]);
        const [selectedView, setSelectedView] = useState<StakeholderViewProps | null>(null);

        React.useEffect(() => {
            if (!selectedView) {
                return;
            }
            const setViewInClient = async () => {
                try {
                    await stakeholderClient.setSelectedView(selectedView.id, appName);
                } catch (e) {
                    const message = `Error from StakeholderSelector when setting selected view`;
                    onErrorHandler && onErrorHandler(message);
                }
            };
            setViewInClient();
        }, [selectedView, stakeholderClient, appName, onErrorHandler]);

        const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
            if (e.target.value) {
                const newSelectedView = getViewById(e.target.value);
                if (newSelectedView) {
                    setSelectedView(newSelectedView);
                    onChangeHandler && onChangeHandler(newSelectedView.id);
                }
            }
        };

        const handleClick = (id: string) => {
            if (id) {
                const newSelectedView = getViewById(id);
                if (newSelectedView) {
                    setSelectedView(newSelectedView);
                    onChangeHandler && onChangeHandler(newSelectedView.id);
                }
            }
        };

        const getViewById = (viewId: string) => {
            return views.find((view: StakeholderViewProps) => view.id === viewId);
        };

        const getSelectedView = (views: StakeholderViewProps[]) => {
            // find the selected view
            const selectedView = views.find((view: StakeholderViewProps) => view.selected);
            // If none - then select the first and set it  - else just set the selected (to make sure event is published by stakeholder client on render)
            if (!selectedView) {
                // if no view is selected - then default to first one and update
                views[0].selected = true;
                return views[0];
            }
            return selectedView;
        };

        const getStakeholderViews = React.useCallback(async () => {
            try {
                const stakeholderViews: { views: StakeholderViewProps[] } = await stakeholderClient.getViews(stakeholderFilter, false, appName);
                setSelectedView(getSelectedView(stakeholderViews.views));
                return stakeholderViews.views;
            } catch (e) {
                const message = `Error from StakeholderSelector when getting views`;
                onErrorHandler && onErrorHandler(message);
                return [];
            }
        }, [appName, onErrorHandler, stakeholderClient, stakeholderFilter]);

        React.useEffect(() => {
            getStakeholderViews().then((viewsFromClient: StakeholderViewProps[]) => {
                if (viewsFromClient.length <= 4 && onChangeHandler) onChangeHandler(viewsFromClient[0].id);
                setViews(viewsFromClient);
            });
        }, [getStakeholderViews, onChangeHandler]);

        if (views.length === 0) return null;

        if (views.length > 4) {
            return (
                <div className={'u-spacing-stack-m'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className={'stakeholder-selector u-flex-box u-flex-box--gap-xsmall u-flex-box--col'}>
                        <div className={'h6 u-spacing-stack-none u-color-secondary-4-medium'} style={{ letterSpacing: 'normal', textTransform: 'none', textAlign: 'left' }}>
                            Profil
                        </div>
                        <Dropdown {...props} onChange={handleChange} ref={ref}>
                            {views.map((view, index) => {
                                return (
                                    <DropdownItem
                                        key={'key-' + index}
                                        value={view.id}
                                        iconShape={view.type === 'business' ? 'attache' : view.id === 'HOUSE_HOLD_GROUPED' ? 'person-two' : 'person-man'}
                                        selected={view.selected}
                                    >
                                        {view.name}
                                    </DropdownItem>
                                );
                            })}
                        </Dropdown>
                    </div>
                </div>
            );
        }

        if (views.length > 1) {
            return (
                <div className={'stakeholder-selector'}>
                    <IconBoxList variant={'row'}>
                        {views.map((view: any, index: number) => {
                            return (
                                <IconBox
                                    id={`stakeholder-${index}`}
                                    selected={selectedView?.id === view.id}
                                    onClick={() => {
                                        handleClick(view.id);
                                    }}
                                    variant="title-outside"
                                    key={index}
                                    title={view.name}
                                    iconShape={view.type === 'business' ? 'attache' : view.id === 'HOUSE_HOLD_GROUPED' ? 'person-two' : 'person-man'}
                                    data-testid={view.id}
                                />
                            );
                        })}
                    </IconBoxList>
                </div>
            );
        }

        if (views[0].id === 'HOUSE_HOLD_GROUPED' && views[0].totalHouseholdMember > 1) {
            return (
                <div className={'stakeholder-selector u-flex-box u-flex-box--gap-xsmall u-flex-box--col u-flex-box--center'}>
                    <FlosIcon shape={'person-two'} size={'medium'} className="is-indigo" />
                    <p className={'u-spacing-stack-none paragraph--small'}>{views[0].name}</p>
                </div>
            );
        }

        return null;
    }
);

StakeholderSelector.displayName = 'StakeholderSelector';

export type { StakeholderSelectorProps };
export default StakeholderSelector;
