import React from 'react';
import cx from 'classnames';
import { FlosIcon } from '../../../core/icon/flos-icon';
import { useId } from '../../../utils/hooks';
import { noop } from '../../../utils';
import { CondWrap } from '../../../utils/jsx-utils';

type CommonProps = {
    title: string;
    label?: string;
    selectable?: boolean;
    selected?: boolean;
    link?: string;
    variant?: 'default' | 'compact' | 'light' | 'title-outside' | 'large';
    quantitySelector?: React.ReactElement<QuantitySelectorProps>;
    giftCard?: React.ReactElement;
} & Omit<React.HTMLAttributes<HTMLAnchorElement>, 'onSelect'>;

type ConditionalProps =
    | {
          /** Image URL */
          imageUrl: string;
          iconShape?: never | undefined;
      }
    | {
          imageUrl?: never | undefined;
          /** Icon attribute */
          iconShape: string;
      };

type IconBoxProps = CommonProps & ConditionalProps;

type InternalIconBoxProps = {
    onSelect?: (selected: { title: string; id: string; selected: boolean | undefined; iconShape: string | undefined; imageUrl: string | undefined }) => void;
};

type QuantitySelectorProps = {
    initialValue?: number;
    multiple?: boolean;
    min?: number;
    max?: number;
    selected?: boolean;
    selectable?: boolean;
    onChange?: (selected: { multiple: boolean; selected?: boolean; quantity: number }) => void;
};

const button = 'icon-box-selected-icon';
const buttonEneabled = 'icon-box-selected-icon has-fill is-logo-blue';
const buttonDisabled = 'icon-box-selected-icon has-fill is-grey';

const QuantitySelector = ({ initialValue = 0, multiple = false, min = 0, max = 999, selected, selectable = true, onChange }: QuantitySelectorProps) => {
    const [quantity, setQuantity] = React.useState<number>(initialValue);

    const onReduce = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!(quantity - 1 < min)) {
            setQuantity((prevQuantity) => (prevQuantity > 0 ? --prevQuantity : prevQuantity));
            onChange && onChange({ multiple: multiple, selected: selected, quantity: quantity - 1 });
        }
    };

    const onIncrease = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        if (!(quantity + 1 > max)) {
            setQuantity((prevQuantity) => ++prevQuantity);
            onChange && onChange({ multiple: multiple, selected: selected, quantity: quantity + 1 });
        }
    };

    React.useEffect(() => {
        if (!selected) {
            setQuantity(0);
            onChange && onChange({ multiple: multiple, selected: selected, quantity: 0 });
        } else {
            setQuantity(initialValue ? initialValue : 1);
        }
    }, [selected]);

    return (
        <div className="quantity-selector">
            {multiple && selected ? (
                <div className={'reduce-increase-wrapper'}>
                    <FlosIcon onClick={onReduce} className={quantity - 1 < min ? buttonDisabled : buttonEneabled} size="medium" shape={'circle-minus'} />
                    <div className="quantity-selector-quantity">{quantity}</div>
                    <FlosIcon onClick={onIncrease} className={quantity + 1 > max ? buttonDisabled : buttonEneabled} size="medium" shape={'circle-plus'} />
                </div>
            ) : (
                <FlosIcon className={selectable ? (selected ? buttonEneabled : button) : buttonDisabled} size="medium" shape={selected ? 'circle-minus' : 'circle-plus'} />
            )}
        </div>
    );
};

const IconBox = ({
    id,
    title,
    iconShape,
    imageUrl,
    label,
    selectable = true,
    selected = false,
    link,
    onSelect = noop,
    className,
    variant = 'default',
    quantitySelector,
    giftCard,
    ...props
}: IconBoxProps & InternalIconBoxProps) => {
    const internalId = useId(id);
    const [isSelected, setIsSelected] = React.useState<boolean | undefined>(undefined);

    const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (!link) event.preventDefault();
        if (quantitySelector && isSelected) return;
        if (!selectable) return;
        setIsSelected((prevIsSelected) => !prevIsSelected);
        onSelect({ title: title, id: internalId, selected: !isSelected, iconShape: iconShape, imageUrl: imageUrl });
    };

    React.useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <div className={cx('icon-box-wrapper', variant && 'icon-box-wrapper--' + variant)}>
            {variant != 'compact' && variant != 'title-outside' && variant != 'light' && giftCard && <div className="icon-box-giftcard--desktop">{giftCard}</div>}
            <a
                id={internalId}
                className={cx(
                    'icon-box',
                    isSelected && 'icon-box--selected',
                    label && 'icon-box--has-label',
                    variant && 'icon-box--' + variant,
                    'icon-box-legacy-wrap',
                    giftCard && 'icon-box--has-giftcard',
                    className
                )}
                href={link}
                onClick={onClickHandler}
                style={{ flexWrap: (props.children && isSelected) || label ? 'wrap' : 'nowrap' }}
                {...props}
            >
                {variant != 'compact' && variant != 'title-outside' && variant != 'light' && label && (
                    <div className="icon-box-label">
                        <p className="small">{label}</p>
                    </div>
                )}
                {iconShape && <FlosIcon className="icon-box-icon" shape={iconShape} size={32} />}
                {imageUrl && (
                    <div className={cx('icon-box-image')}>
                        <img alt="" src={imageUrl} />
                    </div>
                )}
                {title && (variant === 'default' || variant === 'light' || variant === 'large') && (
                    <CondWrap condition={label != undefined} wrapper={(children: any) => <div className="icon-box-title-wrap">{children}</div>}>
                        <CondWrap condition={giftCard != undefined} wrapper={(children: any) => <div className="icon-box-giftcard-wrap">{children}</div>}>
                            <p className="h6 icon-box-title" role={'heading'}>
                                {title}
                            </p>
                            {giftCard && <div className="icon-box-giftcard--mobile">{giftCard}</div>}
                        </CondWrap>
                    </CondWrap>
                )}
                {title && variant === 'compact' && <p className="icon-box-title">{title}</p>}
                {props.children && isSelected && (
                    <div className="icon-box-content" onClick={(event) => event.stopPropagation()}>
                        {props.children}
                    </div>
                )}
                {(() => {
                    if (variant != 'compact' && variant != 'title-outside' && variant != 'light') {
                        if (quantitySelector) {
                            return <>{quantitySelector}</>;
                        } else {
                            return <QuantitySelector selected={isSelected} selectable={selectable} />;
                        }
                    }
                    return <></>;
                })()}
            </a>
            {title && variant === 'title-outside' && <p className="icon-box-wrapper-title">{title}</p>}
        </div>
    );
};

export type { IconBoxProps, QuantitySelectorProps };
export { IconBox, QuantitySelector };
export default IconBox;
