import React from 'react';
import AccordionItem from './accordion-item';
import { AccordionPanelProps } from './accordion-panel';
import { getId } from '../../utils';
import cx from 'classnames';
import { Children, ReactElement } from 'react';

type AccordionProps = {
    /** Variants */
    variant?: 'standard' | 'compact';
    /** Callback when expanded index changes */
    onChange?: (index: number | null) => void;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>;

const Accordion: React.FC<AccordionProps> = ({ variant = 'standard', onChange, children, id, className, ...props }: AccordionProps): React.ReactElement => {
    const newProps = { ...props };
    delete newProps['aria-label'];

    const arrayChildren = Children.toArray(children) as React.ReactElement<AccordionPanelProps>[];
    id = id || getId();

    const [expandedIndex, setExpandedIndex] = React.useState<number | null>(null);

    React.useEffect(() => {
        onChange && onChange(expandedIndex);
    }, [expandedIndex, onChange]);

    return (
        <div
            className={cx('flos-accordion', variant && `flos-accordion--${variant}`, className && className)}
            id={id}
            role="tablist"
            aria-label={props['aria-label']}
            {...newProps}
        >
            {arrayChildren.map((child: ReactElement, index: number) => {
                return (
                    child.props &&
                    child.props.title && (
                        <AccordionItem
                            key={`accordion-item-${index}`}
                            isExpandedInternal={index === expandedIndex}
                            title={child.props.title}
                            subtitle={child.props.subtitle}
                            index={index}
                            onToggleInternal={(toggledIndex: number) => {
                                setExpandedIndex((prevIndex) => {
                                    return prevIndex === toggledIndex ? null : toggledIndex;
                                });
                            }}
                            onChange={child.props.onChange}
                            {...child.props}
                            id={`${id}-accordion-panel-${index}`}
                        >
                            {child}
                        </AccordionItem>
                    )
                );
            })}
        </div>
    );
};

export type { AccordionProps };
export { Accordion };
export default Accordion;
