import React from 'react';
import cx from 'classnames';
import { CSS_STATUS_MAP, SCAN_STATUS, UploadFieldProps, FileNameType } from './types';
import { FlosIcon, Button } from '../../..';

export type ScanFileListProps = {
    deleteButtonLabel?: UploadFieldProps['deleteButtonLabel'];
    errorLabel: UploadFieldProps['errorLabel'];
    fileAction?: UploadFieldProps['fileAction'];
    fileList: UploadFieldProps['scannedFiles'];
    infectedLabel: UploadFieldProps['infectedLabel'];
    label?: React.ReactNode;
    loading?: UploadFieldProps['isLoading'];
    onDelete?: UploadFieldProps['onDelete'];
    onOpen?: UploadFieldProps['onFileOpen'];
    openButtonLabel?: UploadFieldProps['openButtonLabel'];
};

export const ScanFileList: React.FC<ScanFileListProps> = ({
    fileList,
    onDelete,
    deleteButtonLabel,
    openButtonLabel,
    errorLabel,
    infectedLabel,
    loading = false,
    label,
    fileAction = 'delete',
    onOpen,
}) => {
    const sortedFiles = fileList.sort((fileA, fileB) => fileA.key.localeCompare(fileB.key));
    const listRef = React.useRef<HTMLUListElement>(null);
    const [overflow, setOverflow] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!listRef.current) return;
        setOverflow(listRef.current.scrollHeight > listRef.current.clientHeight);
    }, [sortedFiles, listRef]);
    const actions = {
        open: onOpen as (fileName: FileNameType) => void,
        delete: onDelete as (fileName: FileNameType, files: UploadFieldProps['scannedFiles']) => void,
    };
    const buttonLabel = {
        open: openButtonLabel,
        delete: deleteButtonLabel,
    };

    if (sortedFiles.length <= 0) return null;
    return (
        <ul data-testid="filelist" className={cx('flos-uploadfield-filelist', { 'flos-uploadfield-filelist--overflow': overflow })} ref={listRef}>
            {label}
            {sortedFiles.map((file, index) => {
                const status = CSS_STATUS_MAP[file.scanStatus];
                const fileIsLoading = status === 'is-loading';

                return (
                    <li data-testid="file-wrapper" className="flos-uploadfield-file" key={`${file.key}-${index}`}>
                        {fileIsLoading && <FlosIcon className={'has-fill is-info'} shape={'notification'} size={24} />}
                        {!fileIsLoading && <FlosIcon shape="document" size={24} />}
                        <div className={cx('flos-uploadfield-filelabel', { [status]: !!status })} title={file.key}>
                            <div className="flos-uploadfield-filename">
                                {file.scanStatus === SCAN_STATUS.INFECTED && <span>{infectedLabel}</span>}
                                {file.scanStatus === SCAN_STATUS.ERROR && <span>{errorLabel}</span>} {file.key}
                            </div>
                        </div>
                        <Button
                            className={cx({ 'flos-uploadfield-btn': true, 'flos-uploadfield-btn--delete': fileAction === 'delete' })}
                            theme="ghost"
                            size="small"
                            disabled={loading || fileIsLoading}
                            onClick={(e) => {
                                e.preventDefault();
                                if (actions[fileAction]) actions[fileAction](file.key, [...fileList.filter((f) => f.key !== file.key)]);
                            }}
                        >
                            {buttonLabel[fileAction]}
                        </Button>
                    </li>
                );
            })}
        </ul>
    );
};
