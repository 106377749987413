import cx from 'classnames';
import React from 'react';
import { useId } from '../../../utils/hooks';
import { isBool } from '../../../utils/typeguard';
import { RadioFieldContext } from './radio-field-context';

export interface RadioInputProps extends Omit<JSX.IntrinsicElements['input'], 'value'> {
    /** The label of the radio button, the bounding box defines the clickable area of the field. */
    label: React.ReactNode;
    /** To provide more information about the choice. Rendered inside the label, so it will also be clickable. */
    description?: React.ReactNode;
    /** The value to pass on when the form is submitted or change event is fired. */
    value: string | number | boolean;
    /** validation status of the field */
    isValid?: boolean;
    /** Disables the field */
    disabled?: boolean;
    /** Mark field as required */
    required?: boolean;
    /** Error message */
    errorText?: string;
}

/**
 * `RadioInput` can be used in conjection with `RadioField` or as standalone
 *  When used as standalone, you can pass down any props a radio input accepts and it would just works.
 */
export const RadioInput: React.FC<RadioInputProps> = ({
    label,
    description,
    onChange,
    checked: checkedProp,
    id: providedId,
    value,
    isValid = undefined,
    disabled,
    defaultChecked,
    required,
    errorText,
    ...inputProps
}) => {
    const id = useId(providedId);

    const { value: contextValue, name, isValid: valid, variant, disabled: isDisabled } = React.useContext(RadioFieldContext);

    let checked = checkedProp;

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e);
    };

    if (typeof checked === 'undefined') {
        checked = value === contextValue;
    }
    if (defaultChecked) {
        checked = true;
    }

    const isButton = variant === 'button-toolbar' || variant === 'button-menu';
    const LabelContent = () => {
        return (
            <>
                <p className="u-No-bottom-margin">
                    {label}
                    {required && '*'}
                </p>
                {description && <p className="u-No-bottom-margin">{description}</p>}
            </>
        );
    };
    return (
        <>
            <input
                value={!isBool(value) ? value : undefined} // we pass down value only if it's not boolean because boolean is not valid input value
                type="radio"
                id={id}
                className="visuallyhidden"
                onChange={changeHandler}
                checked={checked}
                name={name}
                required={required}
                {...inputProps}
            />
            <label
                htmlFor={id}
                className={cx({
                    'flos-radio': !isButton,
                    'flos-button': isButton,
                    'flos-button--outline': isButton,
                    active: isButton && checked,
                    'is-disabled': disabled || isDisabled,
                    'has-success': isValid === true || valid === true,
                    'has-error': isValid === false || valid === false,
                })}
            >
                {isButton ? (
                    <LabelContent />
                ) : (
                    <>
                        <div className="dot" />
                        <div>
                            <LabelContent />
                        </div>
                    </>
                )}
            </label>
        </>
    );
};
