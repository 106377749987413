import React from 'react';
import { PageHeader } from '../../banners/page-header/page-header';
import { Section } from '../../../core/layout/section/section';
import { useId } from '../../../utils/hooks';

type ErrorPageProps = {
    errorHeader?: string;
    errorTitle?: string;
    errorDescription?: string;
    errorBackText?: string;
    errorContactLink?: string;
    errorContactText?: string;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * ErrorPage is a common component in most Hybrid App when there is a fatal error that the App could not recover easily.
 * All the keys of resources are required; please provide a default value for them instead of relying on resource bundle because ajax to get resource bundle may fail
 */
const ErrorPage: React.FC<ErrorPageProps> = ({
    errorHeader = 'Der opstod en fejl',
    errorTitle = 'Der opstod en fejl',
    errorDescription = 'Du kan prøve igen eller kontakte os hvis problemet forsætter.',
    errorBackText = 'Tilbage',
    errorContactLink = 'https://www.topdanmark.dk/faa-hjaelp/kontakt-os/',
    errorContactText = 'Kontakt os',
    ...props
}) => {
    const id = useId(props.id);
    return (
        <div {...props} id={id}>
            <PageHeader theme="secondary" id={`${id}-pageheader`} title={errorHeader ? errorHeader : 'Der opstod en fejl'} />
            <Section>
                <div className="Box ">
                    <h4>{errorTitle}</h4>
                    <p>{errorDescription}</p>
                    <a className="flos-button flos-button--primary" href=".">
                        {errorBackText}
                    </a>
                    <a href={errorContactLink} className="flos-button flos-button--secondary">
                        {errorContactText}
                    </a>
                </div>
            </Section>
        </div>
    );
};

export type { ErrorPageProps };
export { ErrorPage };
