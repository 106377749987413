import React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { Checkbox, CheckboxProps } from './checkbox';

type CheckboxFieldProps = {
    label: React.ReactNode;
} & CheckboxProps &
    Omit<FlosFieldProps, 'label' | 'maxLength' | 'iconShape'>;

/**
 * CheckboxField is a composition of `Field` and `Input` component.
 * If you wish to have more control, you can compose them yourself.
 */
const CheckboxField = React.forwardRef<HTMLInputElement, CheckboxFieldProps>(({ isValid, errorText, helpText, ...inputProps }, ref) => {
    return (
        <FlosField
            ref={ref}
            isValid={isValid}
            custom={true}
            errorText={errorText}
            required={inputProps.required}
            disabled={inputProps.disabled}
            id={inputProps.id}
            iconShape={undefined}
            helpText={helpText}
            renderInput={({ getInputProps }) => <Checkbox {...getInputProps({ ...inputProps, ref })} />}
        />
    );
});
CheckboxField.displayName = 'CheckboxField';
export default CheckboxField;
export { CheckboxField };
export type { CheckboxFieldProps };
