import React from 'react';
import { TooltipProps } from '../tooltip/tooltip';
import { getId } from '../../../utils';
import { TooltipIcon } from '../tooltip-icon/tooltip-icon';

export type TooltipLabeledProps = {
    iconShape?: string;
    label: string;
} & Omit<TooltipProps, 'children'>;

/**
 * TooltipLabeled is a wrapper of `TooltipIcon` component supporting tooltip on icon with label.
 */
const TooltipLabeled = ({ id, content, label, iconShape = 'info', ...props }: TooltipLabeledProps) => {
    const elementId = id || getId();
    return (
        <div className={'tooltip-labeled'} id={`tooltip-labeled-${elementId}`}>
            {label && <p className={'tooltip-labeled-text'}>{label}</p>}
            <TooltipIcon content={content} id={elementId} iconShape={iconShape} {...props} />
        </div>
    );
};

TooltipLabeled.displayName = 'TooltipLabeled';
export { TooltipLabeled };
export default TooltipLabeled;
