import React from 'react';
import cx from 'classnames';

export type GridItemProps = JSX.IntrinsicElements['div'] & {
    span?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    colStart?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    area?: 'title' | 'content' | 'right-and-under';
};

export const GridItem: React.FC<GridItemProps> = ({ span, colStart, className, area, ...props }: GridItemProps) => (
    <div {...props} className={cx({ [`flos-grid--span-${span}`]: !!span, [`flos-grid--col-start-${colStart}`]: !!colStart, [`flos-grid-area-${area}`]: !!area }, className)} />
);

export default GridItem;
