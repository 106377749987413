import cx from 'classnames';
import React from 'react';

export type ICoverageListProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>;
/**
 * `CoverageList` is the container for `CoverageListItem`.
 * It accepts all the props an `ul` element accepts
 */
export const CoverageList: React.FC<ICoverageListProps> = ({ className, ...props }) => <ul className={cx('flos-ResponsiveCoverage-list', className)} {...props} />;

export default CoverageList;
