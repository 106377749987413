import React from 'react';
import cx from 'classnames';

type ToggleProps = {
    checked?: boolean;
} & React.ComponentPropsWithRef<'input'>;
/**
 * Toggle works like a checkbox
 *
 * You can use `ref` to access the underlying `<input />` element
 */
export const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(({ className, onChange, checked = false, ...props }, ref) => {
    const [isChecked, setIsChecked] = React.useState(checked || false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        onChange && onChange(event);
    };

    React.useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    return (
        <label className={cx('flos-toggle', className && className)}>
            <input {...props} onChange={(e) => handleChange(e)} type="checkbox" ref={ref} checked={isChecked} />
            <span className="flos-toggle-slider" />
        </label>
    );
});

Toggle.displayName = 'Toggle';

export type { ToggleProps };
export default Toggle;
