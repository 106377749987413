import React from 'react';
import { FlowMenuItemProps } from './flow-menu-item';

type FlowMenuProps = {
    /** Only use <FlowMenuItem>'s as children. They will show up as clickable boxes */
    children?: React.ReactElement<FlowMenuItemProps> | React.ReactElement<FlowMenuItemProps>[];
};

/**
 * `FlowMenu` is a component that allows you to make a flows like subscription flows.
 */

const FlowMenu = ({ children }: FlowMenuProps) => {
    return <div className="flos-flow-menu">{children}</div>;
};

export type { FlowMenuProps };
export { FlowMenu };
export default FlowMenu;
