import cx from 'classnames';
import React from 'react';
import { callAll } from '../../../utils/fp';
import { Tooltip } from '../../../core/forms/tooltip/tooltip';

type DivProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'ref'>;

interface ISelectableBoxBaseProps {
    /** callback to be invoked when the Box is selected. use this instead of onClick as it handle keyboard as well */
    onSelect?: () => void;
    /** indicate if the Box is currently selected */
    active?: boolean;
    headerText?: React.ReactNode;
    /** the Box is disabled and cannot be selected */
    disabled?: boolean;
    /** to add Checkbox in the Box - isPlain has no effect */
    tooltip?: React.ReactNode;
    /** indicate if the Box should be dimmed - only apply for plain variant - and not multiple */
    dimmed?: boolean;
    /** indicate if the Box should be representing the ...more button */
    isMoreButton?: boolean;
    iconShape?: string;
}

interface ISelectableDivProps extends DivProps, ISelectableBoxBaseProps {
    /** callback to be invoked when the Box is selected. use this instead of onClick as it handle keyboard as well */
    onSelect?: () => void;
}

export type ISelectableBoxProps = ISelectableDivProps;
/**
 * `SelectableBox` is Button-like boxes that user can select by clicking or press enter.
 */
export const SelectableBox: React.FunctionComponent<ISelectableBoxProps> = ({ headerText, className, active, disabled, children, dimmed, isMoreButton, iconShape, ...rest }) => {
    const classes = cx(
        'Box',
        'Box--selectable-wrapper',
        dimmed && 'Box--dimmed',
        isMoreButton && 'Box--more',
        'Box--selectable-plain',
        children && 'Box--selectable-plain-description',
        active && 'is-active',
        disabled && 'Box--disabled',
        className
    );

    return (
        <SelectableDivBox {...({ headerText, className, active, disabled, children, dimmed, isMoreButton, ...rest } as ISelectableDivProps)} className={classes}>
            <>
                <div className={cx('Box', !isMoreButton && iconShape && 'Box--has-icon')}>
                    {isMoreButton && <flos-icon shape="more" />}
                    {!isMoreButton && iconShape && <flos-icon size={64} shape={iconShape} />}
                    {headerText && <p className={cx(!dimmed && !isMoreButton && 'strong')}>{headerText}</p>}
                </div>
                {children && (
                    <div className={'Box'}>
                        <small>{children}</small>
                    </div>
                )}
            </>
        </SelectableDivBox>
    );
};

const SelectableDivBox: React.FC<ISelectableDivProps> = ({
    headerText,
    className,
    active,
    disabled,
    children,
    onClick,
    onSelect,
    onKeyUp,
    tabIndex = 0,
    role = 'button',
    onKeyDown,
    tooltip,
    dimmed,
    isMoreButton,
    ...divProps
}) => {
    const boxNode = (
        <div
            className={className}
            role={role}
            tabIndex={disabled ? -1 : tabIndex}
            onClick={disabled ? undefined : callAll(onClick, onSelect)}
            onKeyDown={
                disabled
                    ? undefined
                    : callAll(
                          onKeyDown,
                          onSelect &&
                              ((ev) => {
                                  if (ev.which === 13 || ev.which === 32) {
                                      // enter and space key
                                      onSelect();
                                  }
                              })
                      )
            }
            {...divProps}
        >
            {children}
        </div>
    );

    return <>{tooltip ? <Tooltip content={tooltip}>{boxNode}</Tooltip> : boxNode}</>;
};
