import React from 'react';
import cx from 'classnames';
import { CondWrap } from '../../../../utils/jsx-utils';

type IListBoxInfoProps = {
    /** Info area label */
    label?: string;
    /** Info area value */
    text?: string;
    /** Info area addon label */
    addonLabel?: string;
    /** Info area addonvalue */
    addonText?: string;
    /** If you need to add a icon */
    icon?: React.ReactNode;
    /** Use as children. They will override the title and text */
    children?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;
const ListBoxInfo = ({ label, text, icon, addonLabel, addonText }: IListBoxInfoProps) => {
    return (
        <div className={cx('flos-list-box-info')}>
            {icon && icon}
            <CondWrap
                condition={(label || text) && addonLabel && addonText}
                wrapper={(children: any) => (
                    <div style={{ flexBasis: '100%' }} className="u-flex-box u-flex-box--col">
                        {children}
                    </div>
                )}
            >
                <div style={{ flexBasis: '100%' }} className={cx('u-flex-box u-flex-box--center', label && text ? 'u-flex-box--space-between' : 'u-flex-box--justify-center')}>
                    {label && <p className="small">{label}</p>}
                    {text && <h6>{text}</h6>}
                </div>
                {addonLabel && addonText && (
                    <div style={{ flexBasis: '100%' }} className="u-flex-box u-flex-box--center u-flex-box--space-between">
                        {addonLabel && <p className="small">{addonLabel}</p>}
                        {addonText && <h6>{addonText}</h6>}
                    </div>
                )}
            </CondWrap>
        </div>
    );
};

export type { IListBoxInfoProps };
export { ListBoxInfo };
export default ListBoxInfo;
