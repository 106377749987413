import React from 'react';
import cx from 'classnames';

type SidebarProps = {
    /** Set to show/hide the sidebar   */
    isOpen?: boolean;
    /** Called when clicking outside the Sidebar */
    onClickOutside?: () => void;
} & React.ComponentPropsWithRef<'div'>;

export const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
    const { isOpen, onClickOutside, className, children } = props;
    const backdropRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const handleClickOutside = () => {
            if (backdropRef.current && onClickOutside) {
                onClickOutside();
            }
        };
        if (backdropRef.current) {
            backdropRef.current.addEventListener('click', handleClickOutside);
        }
        return () => {
            if (backdropRef.current) {
                backdropRef.current.removeEventListener('click', handleClickOutside);
            }
        };
    }, [backdropRef, props.onClickOutside]);

    return (
        <>
            <div ref={ref} className={cx('site-header-sidebar', isOpen ? 'is-visible' : 'is-hidden', className && className)}>
                {children}
            </div>
            <div ref={backdropRef} className={cx('site-header-sidebar-backdrop', isOpen ? 'is-visible' : 'is-hidden')} />
        </>
    );
});

Sidebar.displayName = 'Sidebar';

export type { SidebarProps };
export default Sidebar;
