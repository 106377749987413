import React from 'react';
import cx from 'classnames';

export type ProgressBarProps = {
    /**  The current step the user is on. */
    currentStep: number;
    /** The total number of steps in the progress. */
    totalSteps: number;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * @fileoverview ProgressBar is a React component that renders a progress bar with dots.
 * @typedef {Object} ProgressBarProps
 * @param {number} currentStep - The current step the user is on.
 * @param {number} totalSteps - The total number of steps in the progress.
 * @returns {JSX.Element} - A progress bar with dots.
 */

const ProgressBar: React.FC<ProgressBarProps> = ({ currentStep, totalSteps, className, ...rest }) => {
    return (
        <div className={cx('flos-progressbar-dots', className)} {...rest}>
            {Array.from({ length: totalSteps }, (_, index) => index + 1).map((step) => {
                return (
                    <div
                        key={step}
                        role="presentation"
                        className={cx({
                            'flos-progressbar-dot': true,
                            'flos-progressbar-dot--active': currentStep >= step,
                        })}
                    />
                );
            })}
        </div>
    );
};

export { ProgressBar };
export default ProgressBar;
