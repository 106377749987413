import React from 'react';
import cx from 'classnames';
import * as Portal from '@radix-ui/react-portal';
import { changeBodyClass } from '../../../utils/dom';
import { callAll } from '../../../utils/fp';
import { useId } from '../../../utils/hooks';

const backdrop = (function () {
    const backdropEl = document.createElement('div');
    backdropEl.className = 'modal-backdrop fade';
    return backdropEl;
})();

const toggleBackdrop = (show: boolean) => {
    backdrop.className = show ? 'modal-backdrop fade in' : 'modal-backdrop fade';
};

const adjustZIndex = (zIndex: string) => {
    backdrop.style.zIndex = (parseInt(zIndex) - 1).toString();
};

const mountedOverlay: string[] = [];
const mountedBackdrop: string[] = [];

const addInstance = (instanceId: string, isFullScreen: boolean, zIndexStyle: string) => {
    if (mountedOverlay.length === 0) {
        changeBodyClass('modal-open', 'add');
    }
    if (!isFullScreen) {
        if (zIndexStyle) {
            adjustZIndex(zIndexStyle);
        }
        mountedBackdrop.push(instanceId);
        document.body.appendChild(backdrop);
        toggleBackdrop(true);
    }
    mountedOverlay.push(instanceId);
};

const removeInstance = (instanceId: string, isFullScreen: boolean) => {
    const instanceIndex = mountedOverlay.indexOf(instanceId);

    if (instanceIndex !== -1) {
        mountedOverlay.splice(instanceIndex, 1);
        if (!isFullScreen) {
            mountedBackdrop.splice(mountedBackdrop.indexOf(instanceId), 1);
        }

        if (mountedOverlay.length === 0) {
            changeBodyClass('modal-open', 'remove');
        }
        if (document.body.querySelector('.modal-backdrop')) {
            if (!isFullScreen && mountedBackdrop.length === 0) {
                toggleBackdrop(false);
                document.body.removeChild(backdrop);
            }
        }
    }
};

interface IModalOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
    isOpen: boolean;
    onDismiss?: () => void;
    isFullScreen?: boolean;
    container?: HTMLElement;
}

/**
 * `ModalOverlay` add the semi-transparent overlay behind `Modal`.
 * When isOpen, it will:
 * - add "modal-open" class to `body`
 * - append a backdrop `div` as child of `body`
 */
export const ModalOverlay: React.FC<IModalOverlayProps> = (props) => {
    const { isOpen, onDismiss, isFullScreen, container, className, onClick, onKeyDown, style, id, ...restProps } = props;
    const instanceId = useId(id);
    const [calculatedStyle, setCalculatedStyle] = React.useState({ ...style, display: 'none' });
    const divRef = React.useRef<HTMLDivElement>(null);
    const [focusModal, setFocusModal] = React.useState(false);

    React.useEffect(() => {
        if (isOpen) {
            addInstance(instanceId, isFullScreen || false, props.style?.zIndex?.toString() || '');
            setCalculatedStyle({ ...style, display: 'block' });
            setFocusModal(true);
        } else {
            setCalculatedStyle({ ...style, display: 'none' });
            removeInstance(instanceId, isFullScreen || false);
        }
        return () => {
            removeInstance(instanceId, isFullScreen || false);
        };
    }, [isOpen, instanceId]);

    React.useEffect(() => {
        if (focusModal) {
            setFocusModal(false);
            if (divRef.current) {
                divRef.current.focus();
            }
        }
    }, [focusModal]);

    return (
        <Portal.Root asChild container={container}>
            <div
                ref={divRef}
                className={cx('modal', isOpen && (isFullScreen ? 'slide-in' : 'in'), !isOpen && isFullScreen && 'slide-out', className)}
                role="dialog"
                aria-hidden={!isOpen}
                onClick={callAll(onClick, (ev) => {
                    ev.stopPropagation();
                    onDismiss && onDismiss();
                })}
                onKeyDown={callAll(onKeyDown, (ev) => {
                    if (ev.key === 'Escape') {
                        ev.stopPropagation();
                        onDismiss && onDismiss();
                    }
                })}
                tabIndex={0}
                style={calculatedStyle}
                id={instanceId}
                {...restProps}
            />
        </Portal.Root>
    );
};

export default ModalOverlay;
