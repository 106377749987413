import React from 'react';
import cx from 'classnames';

type CarouselProps = {
    defaultIndex?: number;
} & React.ComponentPropsWithRef<'div'>;

export const Carousel = React.forwardRef(({ defaultIndex = 0, children, ...props }: CarouselProps, ref: React.MutableRefObject<HTMLDivElement>) => {
    const carouselRef = ref || (React.useRef(ref) as React.MutableRefObject<HTMLDivElement>);

    React.useEffect(() => {
        if (!carouselRef.current) return;
        const childObj: any = carouselRef.current.childNodes[defaultIndex];
        carouselRef.current.scrollTo(childObj?.offsetLeft, 0);
    }, []);

    return (
        <div className={cx('flos-carousel')} ref={carouselRef} {...props}>
            {children}
        </div>
    );
});

Carousel.displayName = 'Carousel';

export type { CarouselProps };
export default Carousel;
