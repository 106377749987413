// @overview: Utilities for functional programming
export type CallBack<Params extends any[]> = (...args: Params) => void;

/**
 * Debounces a function so it will only call if there is no further invocation after some wait
 * @param fn function you want to debounce
 * @param [wait=250] in millisecond
 * @param context context for the debounced function. Only useful if you need `this` to be binded.
 */
export function debounce<Params extends any[]>(fn: CallBack<Params>, wait: number = 250, context: any = null) {
    let timeout: number | undefined;

    return function (...args: Params) {
        window.clearTimeout(timeout);
        timeout = window.setTimeout(function () {
            timeout = undefined;
            fn.apply(context, args);
        }, wait);
    };
}

/**
 * Throttle a function so it will only once within a wait period
 * @param fn function you want to debounce
 * @param [wait=250] in millisecond
 * @param context context for the debounced function. Only useful if you need `this` to be binded.
 */
export function throttle<Params extends any[]>(fn: CallBack<Params>, wait = 250, context: any = null) {
    let timeout: undefined | number;
    let args: undefined | Params;

    const later = () => {
        if (args) {
            fn.apply(context, args);
        }
        timeout = undefined;
    };

    return function (...ars: Params) {
        if (!timeout) {
            args = ars;
            timeout = window.setTimeout(later, wait);
        }
    };
}

/**
 * combines multiple functions with similar signatures and returns a function that has same signature.
 *
 * @param fns
 * @returns a wrapper function that will spread the arguments pass to it to all original functions
 */
export const callAll =
    <Params extends any[]>(...fns: Array<CallBack<Params> | undefined>) =>
    (...params: Params) =>
        fns.forEach((fn) => typeof fn === 'function' && fn(...params));

function areArgumentsShallowlyEqual(prev: null | IArguments, next: null | IArguments) {
    if (prev === null || next === null || prev.length !== next.length) {
        return false;
    }

    // Do this in a for loop (and not a `forEach` or an `every`) so we can determine equality as fast as possible.
    const length = prev.length;
    for (let i = 0; i < length; i++) {
        if (prev[i] !== next[i]) {
            return false;
        }
    }

    return true;
}

/**
 * Higher order function that will memoize the result of last function call
 * @param func the function that you want to memoize
 */
export function memoizeOne<T, Params extends any[]>(func: (...args: Params) => T): (...args: Params) => T {
    let lastArgs: any = null;
    let lastResult: any = null;

    // we reference arguments instead of spreading them for performance reasons
    return function () {
        if (!areArgumentsShallowlyEqual(lastArgs, arguments)) {
            // apply arguments instead of spreading for performance.
            lastResult = func.apply(null, arguments);
        }

        lastArgs = arguments;
        return lastResult;
    };
}
