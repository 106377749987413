import React from 'react';
import cx from 'classnames';
import { FlosIcon } from '../../../core/icon/flos-icon';
import { ButtonProps } from '../../../core/button/button';

type CommonProps = {
    children?: React.ReactNode | string;
};

type iconProps = { hasIcon?: false | undefined; iconSize?: never } | { hasIcon: true; iconSize?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | number };

type variants = 'link' | undefined;

type VariantProps =
    | { variant?: variants; theme?: 'logo' | 'external' | 'primary'; size?: never; minWidth?: never; disabled?: never }
    | {
          variant: 'button';
          theme?: ButtonProps['theme'];
          size?: ButtonProps['size'];
          minWidth?: ButtonProps['minWidth'];
          disabled?: ButtonProps['disabled'];
      };

type LinkProps = CommonProps & iconProps & VariantProps & React.ComponentPropsWithoutRef<'a'>;

const Link = ({ hasIcon, iconSize, className, children, theme, variant = 'link', size, minWidth, disabled, ...props }: LinkProps): React.ReactElement => {
    const linkAttributes = {
        ...props,
        ...(disabled && {
            'aria-disabled': true,
        }),
    };
    const isBtnVariant = variant === 'button';
    const linkClasses = cx(
        {
            'flos-link': true,
            'flos-link--has-icon': hasIcon && !isBtnVariant,
            [`flos-link--has-icon-only`]: hasIcon && !children && !isBtnVariant,
            [`flos-link--${theme}`]: !!theme,
            // button styling
            'flos-button': isBtnVariant,
            'flos-button--secondary': theme === 'secondary',
            [`flos-button--${size}`]: !!size,
            [`flos-button--${minWidth}`]: !!minWidth,
        },
        className
    );

    return (
        <a className={linkClasses} {...linkAttributes}>
            <span>
                {children && <span>{children}</span>}
                {hasIcon && <FlosIcon shape={theme === 'external' ? 'launch' : 'arrow-right'} size={iconSize || 'xsmall'} className={cx('flos-link-icon')} />}
            </span>
        </a>
    );
};

export type { LinkProps };
export { Link };
export default Link;
