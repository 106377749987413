import { LogLevels } from 'c9-js-log-client';
const siteConfig = window.topContext?.config.getSiteConfig();
const endpointConfig = window.topContext?.config.getEndpointConfig();
const appName = 'onsa-overlay';

export const appConfig = {
    appBasePath: '/onsa-overlay/',
    siteConfig: siteConfig,
    appName: appName,
    logLevel: siteConfig?.environment === 'prod' ? LogLevels.warn : LogLevels.debug,
    hybridAppEventBus: window.topContext?.hybridAppEventBus,
    user: window.topContext?.user,
    apiAwsHost: window.apiAwsHost,
    endpointConfig: {
        ...endpointConfig,
        exstream: {
            method: 'post',
            url: endpointConfig?.apiAwsHost + '/exstream-mailer-api/v1/mailer/MTI-bestiltTilb',
            headers: {
                'x-top-appname': appName,
                'x-top-env': siteConfig?.environment,
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }
    }
};

export const productConfig = {
    onsaUrl: {
        CAR: '/forsikringer/bilforsikring-pris',
        CONTENT: '/forsikringer/indboforsikring-pris',
        TRAVEL: '/forsikringer/rejseforsikring-pris',
        INJURY: '/forsikringer/ulykkesforsikring-pris',
        BOAT: '/forsikringer/baadforsikring-bestil-tilbud/',
        CHILD: '/forsikringer/boerneforsikring-pris/',
        DOG: '/forsikringer/hundeforsikring-pris/',
        CYBER: '/forsikringer/cyberforsikring-pris/',
        CONTENT_YOUTH: '/forsikringer/indboforsikring-til-unge-pris/',
        INJURY_YOUTH: '/forsikringer/ulykkesforsikring-til-unge-pris/',
        MC: '/forsikringer/motorcykelforsikring-bestil-tilbud/',
        CAR_ROAD: '/forsikringer/vejhjaelp-bil-pris/',
        MC_ROAD: '/forsikringer/vejhjaelp-mc-pris/',
        WAGE: 'https://loensikring.topdanmark.dk/beregn/topdanmark-ii'
    },
    productKeys: [
        'BOAT',
        'CAR',
        'CAT',
        'CHILD',
        'CONTENT',
        'CONTENT_YOUTH',
        'CYBER',
        'DOG',
        'HOLIDAY_COTTAGE',
        'HEALTH_INSURANCE',
        'HOUSE',
        'INJURY',
        'MOPED',
        'MOTORCYCLE',
        'TRAILER',
        'TRAVEL'
    ],
    productKeysCvr: [
        'CYBER',
        'WORK_INJURY',
        'PROPERTY',
        'BUSINESS_TRAVEL',
        'TRANSPORT',
        'WORK_MACHINES',
        'LIABILITY',
        'NETBANK',
        'VEHICLES',
        'SICKNESS_LOSS',
        'MOVABLES'
    ]
};

export const emailConfig = {
    fromEmail: '',
    toEmail: '',
    businessRecipient: '',
    brand: 'td',
    comment: '',
    customerEmailSubject: '',
    businessEmailSubject: '',
    inquiryFreeText: '',
    campaignCode: '',
    insuranceList: [],
    user: {
        email: '',
        phoneNumber: '',
        segment: 'private'
    },
    viaPOA: false,
    hasPension: null,
    business: {
        cvr: '',
        company: ''
    }
};
