import React, { useState, useEffect } from 'react';
const defaultRectObj = { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0, x: 0, y: 0, toJSON: () => {} };
/**
 * Custom hook that returns the CSS properties for positioning a dropdown list relative to a button element.
 * @param {React.RefObject<HTMLButtonElement>} buttonElement - The ref object for the button element.
 * @param {React.RefObject<HTMLDivElement>} listElement - The ref object for the dropdown list element.
 * @returns {React.CSSProperties} - The CSS properties for positioning the dropdown list.
 */
export const useDropdownPosition = (buttonElement: React.RefObject<HTMLButtonElement>, listElement: React.RefObject<HTMLDivElement>): React.CSSProperties => {
    const { innerWidth, innerHeight } = window;
    const [viewportSize, setViewportSize] = useState({ height: innerHeight, width: innerWidth });
    const [buttonElDOMRect, setButtonElDOMRect] = useState<DOMRect>(defaultRectObj);
    const [listElDOMRect, setListElDOMRect] = useState<DOMRect>(defaultRectObj);
    const { width, height } = viewportSize;
    useEffect(() => {
        const el = (buttonElement?.current?.lastElementChild || buttonElement.current) as HTMLElement;
        setButtonElDOMRect(el.getBoundingClientRect());
        // below code is to update button's DOMRect when layout shift happened
        const observer = new MutationObserver(() => {
            setButtonElDOMRect(el.getBoundingClientRect());
        });
        observer.observe(buttonElement.current as HTMLElement, { attributes: true, subtree: true, childList: true });
        return () => {
            observer.disconnect();
        };
    }, [buttonElement.current, width, height]);

    useEffect(() => {
        const el = listElement.current as HTMLElement;
        setListElDOMRect(el.getBoundingClientRect());
    }, [listElement, width, height]);
    useEffect(() => {
        const handleResize = () => setViewportSize({ height: window.innerHeight, width: window.innerWidth });
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const margin = 16;
    const hasSpaceBelow = buttonElDOMRect.y + buttonElDOMRect.height / 2 + listElDOMRect.height + margin <= height;
    const hasSpaceAbove = buttonElDOMRect.y - buttonElDOMRect.height / 2 - listElDOMRect.height - margin >= 0;
    const hasSpaceLeft = buttonElDOMRect.x + buttonElDOMRect.width / 2 - listElDOMRect.width - margin >= 0;
    const hasSpaceRight = buttonElDOMRect.x + buttonElDOMRect.width / 2 + listElDOMRect.width + margin <= width;

    const dropdownPosition: ['right' | 'left' | 'middle', 'top' | 'bottom'] = ['right', 'bottom'];

    if (!hasSpaceBelow && hasSpaceAbove) dropdownPosition[1] = 'top';
    if (hasSpaceRight) dropdownPosition[0] = 'right';
    else if (hasSpaceLeft) dropdownPosition[0] = 'left';
    else dropdownPosition[0] = 'middle';

    const dropdownCSSProperties: React.CSSProperties = {};
    const [horizontalAlign, verticalAlign] = dropdownPosition;
    switch (horizontalAlign) {
        case 'left':
            dropdownCSSProperties.right = Math.ceil(width - buttonElDOMRect.x - buttonElDOMRect.width / 2);
            break;
        case 'right':
            dropdownCSSProperties.left = Math.ceil(buttonElDOMRect.x + buttonElDOMRect.width / 2);
            break;
        case 'middle':
            dropdownCSSProperties.left = Math.ceil((width - listElDOMRect.width) / 2);
            break;
        default:
            break;
    }
    switch (verticalAlign) {
        case 'bottom':
            dropdownCSSProperties.top = Math.ceil(buttonElDOMRect.y + buttonElDOMRect.height / 2);
            break;
        case 'top':
            dropdownCSSProperties.bottom = Math.ceil(height - buttonElDOMRect.y - buttonElDOMRect.height / 2);
            break;
        default:
            break;
    }

    // console.table(dropdownPosition);
    // console.table(dropdownCSSProperties);

    return dropdownCSSProperties;
};
