import React from 'react';
import cx from 'classnames';

type CardProps = {
    /** Url to fetch the image */
    imageUrl?: string;
    /** Position of the imaga */
    imagePosition?: 'top-right';
    /** Coloring theme */
    theme?: 'primary' | 'secondary' | 'transparent';
    variant?: 'small' | 'medium';
    /** Title text */
    title?: string;
    /** Text below title */
    text?: string;
    /** This label will show above the title */
    label?: string;
    /** Children will be rendered below text */
    children?: React.ReactNode;
    /** Area with CTA buttons or links */
    actionArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

const Card = ({ imageUrl, theme, variant, imagePosition, title, text, label, children, actionArea, className, ...props }: CardProps) => {
    return (
        <div className={cx('card', { [`card--${theme}`]: !!theme, [`card--${variant}`]: !!variant, [`card--${imagePosition}`]: !!imagePosition }, className)} {...props}>
            {imageUrl && (
                <div className="card-image">
                    <img alt="" src={imageUrl} />
                </div>
            )}
            <div className={cx('card-content', { 'card-no-image': !imageUrl })}>
                <div className={'card-text'}>
                    {label && <div className={'h5 card-label'}>{label}</div>}
                    {title && <div className={cx('card-title', { 'u-spacing-stack-none': !text && !children, h4: variant === 'small', h2: variant !== 'small' })}>{title}</div>}
                    {text && <p className={cx({ 'u-spacing-stack-none': !children })}>{text}</p>}
                    {children && <div className="card-children">{children}</div>}
                </div>
                {actionArea && <div className={'card-action'}>{actionArea}</div>}
            </div>
        </div>
    );
};

export type { CardProps };
export { Card };
export default Card;
