import cx from 'classnames';
import React from 'react';

export interface IFormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    /** state if the form group is required */
    required?: boolean;
    /** validation status of the form group */
    isValid?: boolean;
}

/**
 * FormGroup is a container that wrap a control (input, select)
 * and the label to provide contextual styling. It usually it used
 * in conjection with Input, Select, and Label component
 */
export const FlosFormGroup = React.forwardRef<HTMLDivElement, IFormGroupProps>(function FormGroupComponent({ className = 'form-group', required, isValid, ...props }, ref) {
    return <div className={cx(required && 'is-required', typeof isValid !== 'undefined' ? (isValid ? 'has-success' : 'has-error') : '', className)} {...props} ref={ref} />;
});

export default FlosFormGroup;
