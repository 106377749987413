import React from 'react';
import { AttentionBanner } from '../../attention-banner/attention-banner';
import { Logger } from '../../../../types/types';

export type ErrorBoundaryProps = {
    children: React.ReactNode;
} & typeof ErrorBoundary.defaultProps;

/**
 * Boundary to catch JavaScript errors in child component tree.
 *
 * @see https://reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
    static defaultProps = {
        /**
         * logger to log error. You can pass `window.console` or logger object created with `c9-js-log-client`.
         */
        logger: console as Logger,
        /**
         * Fallback when error is caught. Default to a `AttentionBanner`.
         *
         * The `retry` parameter provided can be called to re-render subtree.
         */
        fallbackUi: ({ retry }: { retry: () => void }): JSX.Element => (
            <AttentionBanner role="alert">
                <p>
                    Der opstod en fejl.
                    <br />
                    <a
                        onClick={(ev) => {
                            ev.preventDefault();
                            retry();
                        }}
                        href="#fallback-ui"
                        id="fallback-ui"
                        className="Go"
                        role="button"
                    >
                        Prøv igen
                    </a>
                </p>
            </AttentionBanner>
        ),
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    state = {
        hasError: false,
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const { logger } = this.props;
        logger.error(`Caught error in ErrorBoundary`);
        logger.error(error.toString());
        logger.error(JSON.stringify(errorInfo));
    }

    render() {
        return this.state.hasError ? this.props.fallbackUi({ retry: this.retry }) : <>{this.props.children}</>;
    }

    retry = () => {
        this.setState({
            hasError: false,
        });
    };
}
