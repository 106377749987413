import React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { CvrInput, CvrInputProps } from './cvr-input';
import { useEffect, useState } from 'react';

export type CvrFieldProps = CvrInputProps & Omit<FlosFieldProps, 'label'> & { label?: string };

/**
 * CvrField is a composition of `FlosField` and `CvrInput` component.
 * If you wish to have more control, you can compose them yourself.
 */
export const CvrField = React.forwardRef<HTMLInputElement, Omit<CvrFieldProps, 'maxLength'>>(function CprFieldComp(
    { label = 'CVR-nr.', iconShape, isValid, errorText = 'er ikke korrekt udfyldt', editable, wrapperClassName, onValidityChange, helpText, ...inputProps },
    ref
) {
    const [state, setState] = useState<boolean | undefined>(undefined);

    const isValidHandler = (isCprValid?: boolean) => setState(isValid ? isValid : isCprValid);

    useEffect(() => {
        setState(isValid);
    }, [isValid]);

    return (
        <FlosField
            ref={ref}
            isValid={state}
            onValidityChange={onValidityChange}
            wrapperClassName={wrapperClassName}
            editable={editable}
            label={label}
            iconShape={iconShape}
            errorText={errorText}
            required={inputProps.required}
            id={inputProps.id}
            disabled={inputProps.disabled}
            helpText={helpText}
            renderInput={({ getInputProps }) => <CvrInput {...getInputProps({ ...inputProps, ref })} isValidHandler={isValidHandler} isValid={isValid} />}
        />
    );
});
