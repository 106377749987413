import { useSpring, easings } from '@react-spring/web';
import { useState } from 'react';

type configType = {
    direction: 'forward' | 'backward';
    duration: number;
    currentStep: number;
    immediate: boolean;
    variant?: 'vertical' | 'horizontal';
};

type returnType = [object, object, boolean];
/**
 * useWizardAnimation hook
 *
 * @fileoverview This hook uses the useSpring hook from the `@react-spring/web` package to create two spring animations for a wizard. The first animation is for exiting the step, and the second animation is for entering the step.
 * @param {String} direction - To configure if the animation moving `forward` of `backward`.
 * @param {number} duration - To configure duration of animation.
 * @param {number} currentStep - To be use in hook dept to be observe when animation should be trigger.
 * @param {boolean} immediate - To configure if animation should finish immediately (disabled animation).
 * @param {string} variant - To configure the animation transformation.
 */
export const useWizardAnimation = ({ direction, duration, currentStep, immediate, variant }: configType): returnType => {
    const isMovingForward = direction === 'forward';
    const isVerticalTransform = variant === 'vertical';
    const [animationEnd, setAnimationEnd] = useState(true);
    // Spring animation for exiting step
    const [exitStyle] = useSpring(
        {
            from: isVerticalTransform ? { opacity: 1, transform: 'translateY(0%)' } : { opacity: 1, transform: 'translateX(0%)' },
            to: isVerticalTransform
                ? { opacity: 0, transform: isMovingForward ? 'translateY(100%)' : 'translateY(-100%)' }
                : { opacity: 0, transform: isMovingForward ? 'translateX(100%)' : 'translateX(-100%)' },
            config: { duration, easing: easings.easeOutQuad },
            reset: true,
            immediate,
            onStart: () => {
                setAnimationEnd(false);
            },
            onRest: () => {
                setAnimationEnd(true);
            },
        },
        [currentStep]
    );

    // Spring animation for entering step
    const [entranceStyle] = useSpring(
        {
            from: isVerticalTransform
                ? { opacity: 0, transform: isMovingForward ? 'translateY(-32px)' : 'translateY(32px)' }
                : { opacity: 0, transform: isMovingForward ? 'translateX(-100%)' : 'translateX(100%)' },
            to: isVerticalTransform ? { opacity: 1, transform: 'translateY(0)' } : { opacity: 1, transform: 'translateX(0%)' },
            config: { duration, easing: easings.easeInQuad },
            reset: true,
            immediate,
        },
        [currentStep]
    );

    return [exitStyle, entranceStyle, animationEnd];
};
