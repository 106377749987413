// @overview: Utilities to process/transform JavaScript object
import { includes } from './array';
import { DiffedKeys, FilterdKeys } from './type-helper';
import { isNil, isPrimitive, Primitive } from './typeguard';

/**
 * Check if value is a object
 * @param value
 */
const isObject = (value: any): value is object => {
    const valType = typeof value;

    return !!value && valType === 'object';
};

type RecursivePartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U> ? Array<RecursivePartial<U>> : T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

/**
 * Recursively remove props with falsy value from an object
 * @param obj the original object which you want to remove the properties with falsy value
 * @param isFalsy predicate function used to determine whether a value is falsy, default to `val => isNil(val) OR val === ''`
 */
export const omitFalsyProps = <Original extends { [key: string]: any }>(obj: Original, isFalsy = (val: any) => isNil(val) || val === '') => {
    if (!obj) {
        return obj;
    }

    const result: RecursivePartial<Original> = {};

    for (const key in obj) {
        if (!isFalsy(obj[key])) {
            result[key] = Array.isArray(obj[key])
                ? obj[key].map((record: any) => (isObject(record) ? omitFalsyProps(record, isFalsy) : record))
                : isObject(obj[key])
                ? (omitFalsyProps(obj[key], isFalsy) as any) // typescript can't handle this :|
                : obj[key];
        }
    }

    return result;
};

/**
 * Omit specific keys from an object
 *
 * Example:
 *
 * ```js
 * import { omit } from '@flos/react-ui';
 *
 * const original = { a: 1, b: 2, c: 3 }
 * console.log(omit(original, ['b'])); // { a:1, c: 3 }
 * ```
 *
 * @param obj original obj
 * @param keysToOmit keys to omit
 */
export const omit = <Original extends { [key: string]: any }, Key extends keyof Original>(obj: Original, keysToOmit: Key[] | ReadonlyArray<Key>): Omit<Original, Key> => {
    if (keysToOmit.length === 0) {
        return obj;
    }

    const result: any = {};

    Object.keys(obj).forEach((key) => {
        if (!includes(keysToOmit, key as keyof Original)) {
            result[key] = obj[key];
        }
    });

    return result;
};

/**
 * Pick specific keys from an object
 *
 * Example:
 *
 * ```js
 * import { pick } from '@flos/react-ui';
 *
 * const original = { a: 1, b: 2, c: 3 }
 * console.log(pick(original, ['b'])); // { b: 2 }
 * ```
 *
 * @param obj original obj
 * @param keysToPick keys to pick/keep
 */
export const pick = <Original extends { [key: string]: any }, Key extends keyof Original>(obj: Original, keysToPick: Key[]): Pick<Original, Key> => {
    const result: any = {};

    Object.keys(obj).forEach((key) => {
        if (includes(keysToPick, key as keyof Original)) {
            result[key] = obj[key];
        }
    });

    return result;
};

type PickPrimitive<T> = {
    [P in FilterdKeys<T, Primitive>]: T[P];
};

/**
 * Keep properties of an object whose value is primitive (`undefined`, `null`, `boolean`, `number`, or `string`).
 *
 * This function returns a new object and the original object will be kept unchanged.
 *
 * Example:
 *
 * ```js
 * import { pickPrimitive } from '@flos/react-ui';
 *
 * const person = {
 *   name: 'Malcolm',
 *   age: 29,
 *   isMale: true,
 *   address: {
 *     street: 5,
 *   },
 *   hello: function() {
 *     console.log(`Hi, I am ${name}.`);
 *   },
 * };
 *
 * pickPrimitive(person); // { name: 'Malcolm',  age: 29, isMale: true}
 * ```
 * @param value
 */
export const pickPrimitive = <T extends { [key: string]: any }>(value: T): PickPrimitive<T> =>
    Object.keys(value).reduce<PickPrimitive<T>>((result, key) => (isPrimitive(value[key]) ? Object.assign({}, result, { [key]: value[key] }) : result), {} as any);

type PickNonPrimitive<T> = {
    [P in DiffedKeys<T, Primitive>]: T[P];
};

/**
 * Keep properties of an object whose value is *NOT* primitive (`undefined`, `null`, `boolean`, `number`, or `string`).
 *
 * This function returns a new object and the original object will be kept unchanged.
 *
 * Example:
 *
 * ```js
 * import { pickNonPrimitive } from '@flos/react-ui';
 *
 * const person = {
 *   name: 'Malcolm',
 *   age: 29,
 *   isMale: true,
 *   address: {
 *     street: 5,
 *   },
 *   hello: function() {
 *     console.log(`Hi, I am ${name}.`);
 *   },
 * };
 *
 * pickNonPrimitive(person); // { address: { street: 5 },  hello: fn() }
 * ```
 * @param value
 */
export const pickNonPrimitive = <T extends { [key: string]: any }>(value: T): PickNonPrimitive<T> =>
    Object.keys(value).reduce<PickNonPrimitive<T>>((result, key) => (!isPrimitive(value[key]) ? Object.assign({}, result, { [key]: value[key] }) : result), {} as any);

/**
 * Extract the values and the last part of the property key (separated by separator) of the properties of an object where the property value start with specific pattern/characters
 *
 * This is commonly used to extract out resources properties from resource bundle.
 *
 * The properties are sorted alphabetically
 * @param obj the object that you want to extract the values
 * @param startsWith the initial characters/patterns of the properties that you want to extract
 * @param separator the separator that will split the properties and returns the last part as `key`
 */
export const pickValuesStartWith = (obj: { [key: string]: string }, startsWith: string, separator = '.') =>
    Object.keys(obj)
        .filter((key) => new RegExp('^' + startsWith).test(key))
        .sort()
        .map((key) => ({
            key: key.split(separator).pop(),
            value: obj[key],
        }));
