import React from 'react';

type TabProps = {
    label: React.ReactNode | string;
    iconshape?: string;
} & React.ComponentPropsWithoutRef<'div'>;

export const Tab: React.FC<TabProps> = ({ children, id, onClick }) => {
    return (
        <div className={'flos-tab-panel'} id={`${id}-panel`} role="tabpanel" aria-labelledby={`${id}-button`} onClick={onClick}>
            {children}
        </div>
    );
};

export type { TabProps };
export default Tab;
