import cx from 'classnames';
import React from 'react';
import { useState, Children, cloneElement, KeyboardEvent, ReactElement } from 'react';
import Button from '../../../core/button/button';
import { ButtonProps } from '../../../core/button/button';
import { useDropdownPosition } from './dropdown-position';
import { FlosInput } from '../../../core/forms/input/flos-input';
import { DropdownItemProps } from './dropdown-item';
import FlosIcon from '../../../core/icon/flos-icon';
type DropdownProps = {
    /**
     * Label of the dropdown. If size=small => no label will be shown. Use the onChange callback to show the value somewhere.
     **/
    label?: string;
    children?: ReactElement<DropdownItemProps> | ReactElement<DropdownItemProps>[];
    disabled?: boolean;
    /**
     * Selected value. If size=small => no value will be shown.
     **/
    value?: string;
    onChange?: any;
    /**
     * Size of the dropdown - will render dropdown in different size variants. If small  -> no label[selected] will be shown - get it from onChange callback.
     **/
    size?: ButtonProps['size'];
    /**
     * Fixed width of dropdown select in pixels - will cut the label text and add some dots.
     **/
    fixedWidth?: number;
} & Omit<React.ComponentPropsWithRef<'button'>, 'onChange'>;

export const Dropdown: React.FC<DropdownProps> = React.forwardRef<HTMLButtonElement, DropdownProps>(
    ({ label, children, onChange, className, size = 'medium', fixedWidth, ...props }: DropdownProps, ref): React.ReactElement => {
        const arrayChildren = Children.toArray(children) as any;
        const initialOption = arrayChildren.findIndex((child: any) => child.props.selected);
        const [showOptions, setShowOptions] = useState<boolean>(false);
        const [selectedOption, setSelectedOption] = useState<number | null>(initialOption >= 0 ? initialOption : null);
        const [inputValue, setInputValue] = React.useState('');
        const inputRef = React.useRef(ref) as React.MutableRefObject<HTMLInputElement | null>;
        const listRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
        const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
        const selectRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
        const dropdownPositionParams = useDropdownPosition(buttonRef, listRef);

        const toggleOptions = () => {
            // // decide starting position of dropdown
            // setDropdownPositionParams(useDropdownPosition(buttonRef.current, selectRef.current, listRef.current, size));

            setShowOptions(!showOptions);
            // scroll selected into view
            const selected = listRef.current.querySelector('li[aria-selected=true]') as HTMLElement;
            if (selected && typeof selected.scrollIntoView == 'function') {
                setTimeout(function () {
                    selected?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    });
                }, 100);
            }
        };

        const setAndClose = (index: number) => {
            setSelectedOption(index);
            setShowOptions(false);
        };

        const handleKeyPress = (index: number) => (e: KeyboardEvent) => {
            switch (e.key) {
                case ' ':
                case 'SpaceBar':
                case 'Enter':
                    e.preventDefault();
                    setAndClose(index);
                    break;
                default:
                    break;
            }
        };

        const handleListKeyDown = (e: KeyboardEvent) => {
            const currentOption = typeof selectedOption == 'number' ? selectedOption : -1;
            switch (e.key) {
                case 'Escape':
                    e.preventDefault();
                    setShowOptions(false);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    currentOption - 1 >= 0 && setSelectedOption(currentOption - 1);
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    currentOption + 1 < arrayChildren.length && setSelectedOption(currentOption + 1);
                    break;
                default:
                    break;
            }
        };

        React.useEffect(() => {
            for (let i = 0; i < arrayChildren.length; i++) {
                if (arrayChildren[i].props.selected) {
                    setSelectedOption(i);
                }
            }
        }, []);

        React.useEffect(() => {
            if (typeof selectedOption == 'number') {
                setInputValue(arrayChildren[selectedOption].props.value);
            }
            const selected = listRef.current.querySelector('li[aria-selected=true]') as HTMLElement;
            if (selected && !isInParentsViewPort(selected) && typeof selected.scrollIntoView == 'function') {
                selected?.scrollIntoView();
            }
        }, [selectedOption]);

        React.useEffect(() => {
            onChange &&
                onChange({ target: inputRef.current, currentTarget: inputRef.current, label: selectedOption !== null ? arrayChildren[selectedOption].props.children : undefined });
        }, [inputValue]);

        React.useEffect(() => {
            const handleClickOutside = (e: MouseEvent) => {
                if (showOptions) {
                    if (!listRef.current.contains(e.target as Node)) {
                        setShowOptions(false);
                    }
                    return;
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [showOptions]);

        return (
            <div className={cx('flos-dropdown', 'flos-dropdown--' + size, props.disabled && 'flos-dropdown--disabled', className)}>
                <div className={'flos-dropdown-select'} ref={selectRef}>
                    <FlosInput autoComplete={'off'} type="hidden" value={inputValue} ref={inputRef} />
                    <Button
                        type="button"
                        size={size}
                        aria-haspopup="listbox"
                        aria-expanded={showOptions}
                        theme="secondary"
                        onClick={toggleOptions}
                        onKeyDown={handleListKeyDown}
                        ref={buttonRef}
                        iconShape="arrow-down"
                        {...props}
                    >
                        {size !== 'small' && selectedOption != null && arrayChildren[selectedOption].props.iconShape ? (
                            <span className={'u-flex-box u-flex-box--center u-flex-box--gap-xsmall u-flex-box--nowrap'}>
                                <FlosIcon className={'is-indigo'} shape={arrayChildren[selectedOption !== null ? selectedOption : 0].props.iconShape} />
                                <span
                                    style={fixedWidth ? { width: fixedWidth - 128 } : {}}
                                    className={cx('flos-dropdown-select-label', fixedWidth && 'flos-dropdown-select-label--fixed')}
                                >
                                    {arrayChildren[selectedOption !== null ? selectedOption : 0].props.children}
                                </span>
                            </span>
                        ) : (
                            size !== 'small' && (
                                <span
                                    style={fixedWidth ? { width: fixedWidth - 96 } : {}}
                                    className={cx('flos-dropdown-select-label', fixedWidth && 'flos-dropdown-select-label--fixed')}
                                >
                                    {label && selectedOption == null ? label : arrayChildren[selectedOption !== null ? selectedOption : 0].props.children}
                                </span>
                            )
                        )}
                    </Button>
                </div>
                <div className={'flos-dropdown-outer'}>
                    <div
                        ref={listRef}
                        className={cx('flos-dropdown-inner', showOptions && 'flos-dropdown-inner--show')}
                        style={{
                            visibility: showOptions ? 'visible' : 'hidden',
                            position: 'fixed',
                            ...dropdownPositionParams,
                        }}
                    >
                        <ul role={'listbox'} className={cx('flos-dropdown-list', 'u-scrollbar')}>
                            {Children.map(children, (child: any, index: number) => {
                                return cloneElement(child as any, {
                                    key: 'dropdown-item-' + index,
                                    selected: selectedOption == index,
                                    onClick: () => {
                                        setAndClose(index);
                                    },
                                    onKeyPress: handleKeyPress(index),
                                });
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
);

Dropdown.displayName = 'Dropdown';

const isInParentsViewPort = (element: HTMLElement) => {
    const parent = element?.offsetParent;
    if (parent) {
        const elemBounding = element.getBoundingClientRect();
        const parentBounding = parent.getBoundingClientRect();
        return elemBounding.bottom <= parentBounding.height + parentBounding.top && elemBounding.top >= parentBounding.top;
    }
    return false;
};

export type { DropdownProps, DropdownItemProps };
export default Dropdown;
