import { createContext } from 'react';
// @ts-ignore
import { LogLevels } from 'c9-js-log-client/dist/log-level-enum';

export interface ISiteConfig {
    environment: string;
    env: string;
    preview: boolean;
    logLevel: LogLevels | undefined;
    siteType: string;
    originalHost: string;
    sisterHost: string;
    brand: string;
}

export interface IEndpointConfig {
    wso2host: string;
    apiAwsBase: string;
    apiAwsHost: string;
    apiGatewayBase: string;
    logger: string;
    jwtRefresh: string;
    cdnBase: string;
    apiHippoHost: string;
    contentDocuments: string;
    htmlContent: string;
    resourceBundle: string;
    exstream: any;
}

export interface IConfigProps {
    siteConfig?: ISiteConfig;
    endpointConfig: IEndpointConfig;
    appBasePath: string;
    appName: string;
    logLevel: LogLevels | undefined;
    hybridAppEventBus: any;
    user: any;
    getSiteConfig?: Function;
    apiAwsHost: string;
}

const ConfigContext = createContext<IConfigProps>({} as IConfigProps);

export { ConfigContext };
