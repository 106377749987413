import React from 'react';
import cx from 'classnames';
import { useId } from '../../../utils/hooks';

type PillProps = {
    /**
     * The content of the pill
     */
    content: string;
    /**
     * theme of the pill
     */
    theme?: 'primary' | 'secondary';
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Pill is a rectangle dot with a number or char inside. It supports up to 2 digits/chars - will not render anything when above.
 */

const Pill = ({ content, theme = 'primary', ...props }: PillProps) => {
    const id = useId(props.id);

    return (
        <>
            <div {...props} id={id} className={cx('flos-pill', `flos-pill--${theme}`, props.className && props.className)}>
                <p className="small">{content}</p>
            </div>
        </>
    );
};

export type { PillProps };
export { Pill };
