// @overview: API request utilities
import { createRequest as oriCreateRequest, SimpleResponse } from 'xhfetch';
import { isNil } from './typeguard';

const DEFAULT_RETRIES = [1000, 3000];

export type FetchInit = RequestInit & {
    params?: Record<string, string | number | boolean | undefined>;
    data?: any;
    /** delay in millisecond before retry again. Default to [1000, 3000] (wait 1 sec, then 3 secs) */
    retryDelays?: number[];
};

/**
 * A wrapper of `fetch` that will retry after some delay.
 *
 * You probably want to use `fetchJson` instead.
 *
 * Second parameter (`init`) accepts all options of `fetch` and the following additional options:
 *
 * | Properties    | Type        | Description |
 * | ------------- | ---------- | ---------------------------------------- |
 * | `params`      | `Object`   | Key-value object to be appended as query parameter. E.g. `{params: {q: 'Malcolm'}}` will append `q=Malcolm` at the end of the URL |
 * | `data`        | `any`      | object to be stringified and sent as `body`. |
 * | `retryDelays` | `number[]` | An array of delays for the retry request when fail. Default to [1000, 3000] (wait 1 sec, then 3 secs). |
 *
 * @param url url to fetch
 * @param init options to customize behavior
 */
export function fetchWithRetry(url: string, init: FetchInit = {}): Promise<Response> {
    const { retryDelays = DEFAULT_RETRIES, params, data, ...remainingInit } = init;
    return new Promise((fulfill, reject) => {
        let attemptCount = -1;
        const requestUrl = url + stringifyParams(params);

        function makeRequest(): void {
            attemptCount++;
            const request = fetch(
                requestUrl,
                data
                    ? {
                          ...remainingInit,
                          body: JSON.stringify(data),
                      }
                    : remainingInit
            );

            request
                .then((response) => {
                    if (response.ok) {
                        fulfill(response);
                    } else if (shouldRetry(attemptCount)) {
                        retryRequest();
                    } else {
                        const error: any = new Error(`fetchWithRetry: No success response after ${attemptCount} retries, give up!`);
                        error.response = response;
                        reject(error);
                    }
                })
                .catch((err) => {
                    if (shouldRetry(attemptCount)) {
                        retryRequest();
                    } else {
                        reject(err);
                    }
                });
        }

        function retryRequest(): void {
            const retryDelay = retryDelays[attemptCount];
            window.setTimeout(makeRequest, retryDelay);
        }

        function shouldRetry(attempt: number) {
            return attempt < retryDelays.length;
        }

        makeRequest();
    });
}

/**
 * Convenient wrapper of `fetchWithRetry` that set the correct headers and parsing for fetching JSON.
 *
 * @param url url to fetch
 * @param init options to customize behavior
 */
export function fetchJson(url: string, init: FetchInit = {}) {
    const { headers, ...restInit } = init;
    const additionalHeaders: Record<string, string> =
        init.data || init.body
            ? {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
              }
            : {
                  Accept: 'application/json',
              };

    return fetchWithRetry(url, {
        headers: {
            ...additionalHeaders,
            ...headers,
        },
        ...restInit,
    }).then((res) => res.json());
}

const hasOwnProperty = Object.prototype.hasOwnProperty;

const stringifyParams = (params: FetchInit['params']): string => {
    if (!params) {
        return '';
    }

    const results: string[] = [];

    for (const key in params) {
        if (hasOwnProperty.call(params, key)) {
            const value = params[key];
            if (!isNil(value)) {
                results.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }
    }

    return `?${results.join('&')}`;
};

/**
 * Simple wrapper around `fetch` API that supports additional `params` options, which is an object that will be appended to the url as query parameter.
 *
 * For instance, `get('www.google.com', { params: {q: 'Malcolm'} })` will make a GET request with URL `'www.google.com?q=Malcolm'`.
 *
 * @param url
 * @param init
 * @deprecated Use `CreateRequest` instead.
 */
export const get = <T = any>(url: string, init?: FetchInit): Promise<T> => {
    if ('production' !== process.env.NODE_ENV) {
        console.warn('get is Deprecated and will be removed in next major release. Use CreateRequest instead.');
    }
    return fetchJson(url, init);
};

/**
 * Simple wrapper around `fetch` API that accepts additional
 * `params` options, default method to `'POST'`, and stringify `data`
 *
 * @param url
 * @param data
 * @param init
 * @deprecated Use `CreateRequest` instead.
 */
export const post = <T = any>(url: string, data: any, init?: FetchInit): Promise<T> => {
    if ('production' !== process.env.NODE_ENV) {
        console.warn('post is Deprecated and will be removed in next major release. Use CreateRequest instead.');
    }

    return fetchJson(url, { method: 'POST', data, ...init });
};

/**
 * Simple wrapper around `fetch` API that accepts additional
 * `params` options, default method to `'PUT'`, and stringify `data`.
 *
 * @param url
 * @param data
 * @param init
 * @deprecated Use `CreateRequest` instead.
 */
export const put = <T = any>(url: string, data: any, init?: FetchInit): Promise<T> => {
    if ('production' !== process.env.NODE_ENV) {
        console.warn('put is Deprecated and will be removed in next major release. Use CreateRequest instead.');
    }

    return fetchJson(url, { method: 'PUT', data, ...init });
};

export type CreateRequestOptions = RequestInit & {
    params?: Record<string, string | number | boolean | undefined>;
    json?: boolean;
    /** delay in millisecond before retry again. Default to [1000, 3000] (wait 1 sec, then 3 secs) */
    retryDelays?: number[];
};

export type CreateRequestResult = {
    /**
     * call this only when you need the xhr because the initial xhr may be replaced if request fails.
     */
    getXhr: () => XMLHttpRequest;
    fetch: () => Promise<SimpleResponse>;
};

/**
 * Simple wrapper of [`xhfetch`](https://www.npmjs.com/package/xhfetch).
 *
 * Preferred over `fetchJson` as this allowed cancellation.
 *
 * ```js
 * import { CreateRequest } from '@flos/react-ui';
 *
 * const { xhr, fetch } = CreateRequest('https://google.com') // same parameters with `fetch`.
 *
 * fetch().then(res => res.text()).then(console.log) // usage just like `fetch`,
 *
 * xhr.abort(); // underlying xhr is exposed so you can cancel request.
 * ```
 *
 * See live example [here](#create-request-demo).
 *
 * @param url url to make the request
 * @param options the same options that you can provide to `fetch` with the following two additional properties:
 *  - params: key-value to be appended as query string
 *  - json: set the correct header value for JSON request
 *  - retryDelays: delay in millisecond before retry again. Default to [1000, 3000] (wait 1 sec, then 3 secs)
 */
export const createRequest = (url: string, options: CreateRequestOptions = {}): CreateRequestResult => {
    const { params, json, retryDelays = DEFAULT_RETRIES, headers, ...fetchOptions } = options;
    let latestXhr: XMLHttpRequest;

    function retryableFetch(): Promise<SimpleResponse> {
        return new Promise<SimpleResponse>((fulfill, reject) => {
            let attemptCount = -1;

            function makeRequest() {
                attemptCount++;
                // we create a new xhr for each attempt so that Authorization header is set correctly
                // this is something specific special about topdanmark site
                const { xhr, fetch } = oriCreateRequest(url + stringifyParams(params), {
                    ...fetchOptions,
                    headers: json
                        ? options.method && options.method.toLowerCase() !== 'get'
                            ? {
                                  Accept: 'application/json',
                                  'Content-Type': 'application/json',
                                  ...headers,
                              }
                            : {
                                  Accept: 'application/json',
                                  ...headers,
                              }
                        : headers,
                });

                latestXhr = xhr;

                fetch()
                    .then((response) => {
                        if (response.ok || !shouldRetry(attemptCount)) {
                            fulfill(response);
                        } else {
                            retryRequest();
                        }
                    })
                    .catch((err) => {
                        if (shouldRetry(attemptCount)) {
                            retryRequest();
                        } else {
                            reject(err);
                        }
                    });
            }

            function retryRequest(): void {
                const retryDelay = retryDelays[attemptCount];
                window.setTimeout(makeRequest, retryDelay);
            }

            function shouldRetry(attempt: number): boolean {
                return attempt < retryDelays.length;
            }

            makeRequest();
        });
    }

    return {
        getXhr: () => latestXhr,
        fetch: retryableFetch,
    };
};
