import React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { FlosInput, FlosInputProps } from '../input/flos-input';

export type FileUploadFieldProps = FlosInputProps & FlosFieldProps;

/**
 * FileUploadField is a wrapper of `FlosField`.
 * If you wish to have more control, you can compose them yourself.
 */
export const FileUploadField = React.forwardRef<HTMLInputElement, FileUploadFieldProps>(function FileUploadComp({ isValid, wrapperClassName, ...inputProps }, ref) {
    return (
        <FlosField
            ref={ref}
            isValid={isValid}
            label={''}
            wrapperClassName={wrapperClassName}
            disabled={inputProps.disabled}
            id={inputProps.id}
            renderInput={({ getInputProps }) => <FlosInput {...getInputProps({ ...inputProps, ref })} type={'file'} />}
        />
    );
});
