import cx from 'classnames';
import React from 'react';

export type GridProps = JSX.IntrinsicElements['div'] & {
    variant?: 'two-col' | 'narrow' | 'right-and-under';
    gap?: 'none' | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    children?: React.ReactNode;
};

/**
 *
 */
export const Grid: React.FC<GridProps> = ({ variant, children, className, gap, ...props }: GridProps) => (
    <div {...props} className={cx('flos-grid', { [`flos-grid--${variant}`]: !!variant, [`flos-grid--gap-${gap}`]: !!gap }, className)}>
        {children}
    </div>
);

export default Grid;
