import React from 'react';
import { createRequest, CreateRequestResult } from '../../utils/ajax';
import { isDefined } from '../../utils/typeguard';
import { ResourceContext } from './resource-context';

type Resource = Record<string, string>;

export type ResourceContextProviderProps = {
    children: React.ReactNode;
    /**
     * The endpoint or array of endpoints that will return you a JSON object with `key-value`, which are probably hippo resource bundles.
     */
    resourceEndpoint?: string | string[];
    /**
     * The initial value of your resource bundle. This is handy if you want to define the default resource bundle which can be overwritten
     * by the resources returned from `resourceEndpoint`.
     */
    initialResource?: Resource;
    /**
     * Callback to be invoked when missing key is detected.
     */
    logMissingKey?: (msg: string) => void;
    /**
     * Callback to be invoked when resource(s) are fetched.
     */
    onResourcesFetched?: () => void;
};

/**
 * `ResourceContextProvider` is a Helper context to manage resource bundle (key-value text label).
 *
 * Use `React.useContext(ResourceContext)` to get the `getLabel` function.
 */
export const ResourceContextProvider = ({ initialResource = {}, resourceEndpoint, logMissingKey = console.warn, onResourcesFetched, children }: ResourceContextProviderProps) => {
    const [resource, setResource] = React.useState<Resource>(initialResource || {});

    React.useEffect(() => {
        if (resourceEndpoint) {
            const resourceEndpoints: string[] = typeof resourceEndpoint === 'string' ? [resourceEndpoint] : resourceEndpoint;

            const requests: CreateRequestResult[] = [];
            const fetches = resourceEndpoints.map(async (resourceEndpoint) => {
                const request = createRequest(resourceEndpoint);
                requests.push(request);

                return request
                    .fetch()
                    .then((res) => {
                        if (res.ok) {
                            return res.json();
                        }
                        throw new Error(`Getting resource request fails: ${resourceEndpoint}`);
                    })
                    .then((additionalResource) =>
                        setResource((currentResource: Resource) => ({
                            ...currentResource,
                            ...additionalResource,
                        }))
                    )
                    .catch(console.error);
            });

            Promise.all(fetches).then(() => {
                if (onResourcesFetched) onResourcesFetched();
            });

            return () => requests.forEach((request) => request.getXhr().abort());
        }
        return undefined;
    }, [resourceEndpoint]);

    const getLabel = React.useCallback(
        function getLabelForKey(key: string): string {
            const label = resource[key];
            if (isDefined(label)) {
                return label;
            } else {
                if (Object.keys(resource).length > 0) {
                    logMissingKey(`Missing label in resource bundle for ${key}.`);
                }
                return key;
            }
        },
        [resource, logMissingKey]
    );

    return <ResourceContext.Provider value={getLabel}>{children}</ResourceContext.Provider>;
};
