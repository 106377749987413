import React from 'react';
import cx from 'classnames';
import FlosIcon from '../../../core/icon/flos-icon';

type CommonProps = {
    /** Title text */
    title: string;
    /** Top label within title and icon */
    label?: string;
    /** Text below of title */
    text?: string;
    /** Text and icon alignment */
    alignment?: 'left';
    /** Theme variants */
    theme?: 'white' | 'no-border' | 'link';
    /** Action area like button or link */
    actionArea?: React.ReactNode;
};

type ConditionalProps =
    | {
          /** Image URL */
          imageUrl?: string;
          iconShape?: never;
      }
    | {
          imageUrl?: never;
          /** Icon attribute */
          iconShape?: string;
      };

type InfoBoxProps = CommonProps & ConditionalProps & React.ComponentPropsWithoutRef<'div'>;

// interface InfoBoxProps {
//     /** Title text */
//     title: string;
//     /** Top label within title and icon */
//     label?: string;
//     /** Text below of title */
//     text?: string;
//     /** Text and icon alignment */
//     alignment?: 'left';
//     /** Theme variants */
//     theme?: 'white' | 'no-border' | 'link';
//     /** Action area like button or link */
//     actionArea?: React.ReactNode;
//     /** Image URL */
//     imageUrl?: string;
//     /** Icon attribute */
//     iconShape?: never;
//     className: string;
// }

export const InfoBox = ({ iconShape, imageUrl, alignment, theme, title, text, label, actionArea, className, ...props }: InfoBoxProps): React.ReactElement => {
    const isLinkTheme = theme === 'link';
    return (
        <div className={cx('info-box', theme && 'info-box--theme-' + theme, alignment && 'info-box--align-' + alignment, className)} {...props}>
            <div className={cx('info-box-content')}>
                {iconShape && (
                    <div className={cx('info-box-icon', { 'info-box-icon--large': !isLinkTheme })}>
                        <FlosIcon shape={iconShape} />
                    </div>
                )}
                {imageUrl && (
                    <div className={cx('info-box-image', { 'info-box-image--large': !isLinkTheme })}>
                        <img alt="" src={imageUrl} />
                    </div>
                )}
                <div className={'info-box-text'}>
                    {label && <div className={'h5 info-box-label'}>{label}</div>}
                    {title && <div className={cx('h4', 'info-box-title', !text && 'u-spacing-stack-none')}>{title}</div>}
                    {text && <p>{text}</p>}
                </div>
            </div>
            {actionArea && <div className={'info-box-action'}>{actionArea}</div>}
        </div>
    );
};

export type { InfoBoxProps };
//export { InfoBox };
export default InfoBox;
