import React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { FlosInput, FlosInputProps } from '../input/flos-input';

export type TextFieldProps = FlosInputProps & FlosFieldProps;

/**
 * TextField is a composition of `FlosField` and `FlosInput` components.
 * If you wish to have more control, you can compose them yourself.
 */
export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    ({ label, iconShape, isValid, errorText = 'skal udfyldes', maxLength, minLength, wrapperClassName, editable, onValidityChange, helpText, showLength, ...inputProps }, ref) => {
        return (
            <FlosField
                ref={ref}
                disabled={inputProps.disabled}
                editable={editable}
                errorText={errorText}
                helpText={helpText}
                iconShape={iconShape}
                id={inputProps.id}
                isValid={isValid}
                label={label}
                onValidityChange={onValidityChange}
                required={inputProps.required}
                wrapperClassName={wrapperClassName}
                minLength={minLength}
                maxLength={maxLength}
                showLength={showLength}
                renderInput={({ getInputProps }) => <FlosInput {...getInputProps({ ...inputProps, ref })} />}
            />
        );
    }
);

TextField.displayName = 'TextField';
