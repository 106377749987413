import React from 'react';
import cx from 'classnames';
import { FlosFormGroup } from './form-group/flos-form-group';
import { getId } from '../../utils';
import { callAll } from '../../utils/fp';
import { useState, useRef, useEffect, MutableRefObject } from 'react';

import type { FlosInputProps } from './input/flos-input';

type FieldProps<T> = T;

type FlosFieldRenderInputProps = {
    getInputProps: <T>(oriProps: FieldProps<T>) => FieldProps<T>;
};

type FlosFieldInternalProps = {
    /** the props to render your input */
    renderInput: (renderInputProps: FlosFieldRenderInputProps) => JSX.Element;
    /** the id of the input, important for accessibility */
    inputId?: string;
    hasDropdown?: boolean;
    readOnlyHandler?: () => void | null;
};
type FlosFieldProps = {
    /** label text */
    label?: string;
    /** className to be added to form group container */
    wrapperClassName?: string;
    /** icon to be added in the left side of the field */
    iconShape?: string;
    /** validation status of the field */
    isValid?: boolean;
    /** Error text for the field that will be displayed next to the label if the field is invalid */
    errorText?: string;
    /** Helper text that render below input field */
    helpText?: string;
    required?: boolean;
    /** regex filtering out nonallowed chars */
    pattern?: string;
    /** set a max length on string */
    maxLength?: number;
    /** set a minimal length required */
    minLength?: number;
    id?: string;
    /** sets disabled state on the input field */
    disabled?: boolean;
    /** overwrite label and icons */
    custom?: boolean;
    /** show "Pen-icon" button at the end to control ReadOnly mode */
    editable?: boolean;
    /** callback having the current isValid state for the field  */
    onValidityChange?: (isValid: boolean | undefined) => void;
    /** don't show length even if maxLength is set  */
    showLength?: boolean;
    isOpen?: boolean;
};

type FieldState = {
    isFocused?: boolean;
    isDirty?: boolean;
    isValid?: boolean;
    isReadOnly?: boolean;
};

const LabelIcons = ({
    label,
    iconShape,
    required,
    disabled,
    inputId,
    hasDropdown = false,
    editable,
    readOnlyHandler,
    isFocused,
}: FlosFieldProps & FlosFieldInternalProps & FieldState) => {
    const shouldChevronIcon = !editable && hasDropdown && !disabled;
    return (
        <>
            {label ? (
                <label htmlFor={inputId} className="flos-label">
                    {label}
                    {required && '*'}
                </label>
            ) : null}
            {iconShape ? <flos-icon class="flos-field-icon is-indigo" shape={iconShape} /> : null}
            {shouldChevronIcon ? (
                <flos-icon class={cx('flos-field-icon flos-field-icon--secondary is-logo-blue', { 'flos-field-icon--reversed': isFocused })} shape="arrow-down" />
            ) : null}
            {editable ? (
                <button
                    className={'flos-field-edit-button'}
                    tabIndex={0}
                    onClick={() => {
                        readOnlyHandler && readOnlyHandler();
                    }}
                >
                    <flos-icon class="is-interactive is-interactive--filled" shape={'edit'} />
                </button>
            ) : null}
        </>
    );
};

/**
 * `Field` is the wrapper for form control.
 * It is just composition of `FormGroup` and `Label`.
 * If the composition here doesn't fit your use case, you could compose yourself.
 */
const FlosField = React.forwardRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, FlosFieldProps & FlosFieldInternalProps>(
    (
        {
            custom,
            disabled,
            editable,
            errorText,
            hasDropdown = false,
            helpText,
            iconShape,
            id,
            isValid,
            label,
            maxLength = 0,
            minLength = 0,
            onValidityChange,
            renderInput,
            required,
            wrapperClassName,
            showLength = true,
            isOpen,
        },
        ref: React.RefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | null>
    ) => {
        let inputRefInternal = useRef() as MutableRefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
        if (ref) inputRefInternal = ref as MutableRefObject<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
        const valueLength = inputRefInternal?.current?.value?.length || 0;
        const [state, setState] = useState<FieldState>({
            isFocused: false,
            isDirty: !!valueLength,
            isValid: isValid,
            isReadOnly: editable,
        });

        const inputId = id || getId();
        const formgroupRef = useRef<HTMLDivElement | null>(null);
        const hintText = state.isValid === false ? errorText : helpText;
        const showHelptext = !!hintText || maxLength >= 1;
        const handleReadOnlyState = () => {
            setState((prevState) => ({ ...prevState, isReadOnly: !prevState.isReadOnly }));
        };

        const handleFocus = () => {
            setState((prevState) => ({ ...prevState, isFocused: true }));
        };

        const determineIsValid = (prevState: FieldState, valueLength: number, minLength: number, maxLength: number | undefined, isValid: boolean | undefined) => {
            if (typeof isValid !== 'undefined') return isValid;
            if (prevState.isDirty && valueLength !== 0) return valueLength >= minLength && (maxLength ? valueLength <= maxLength : true);
            return undefined;
        };

        const handleBlur = () => {
            setState((prevState) => ({
                ...prevState,
                isFocused: false,
                isValid: determineIsValid(prevState, valueLength, minLength, maxLength, isValid),
                isReadOnly: !!editable,
            }));
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            setState((prevState) => ({ ...prevState, isDirty: true }));
        };

        const handleKeyUp = () => {
            setState((prevState) => ({ ...prevState, isDirty: true }));
        };

        const handleKeyPress = (event: any) => {
            if (event.target && event.target.pattern) {
                if (typeof event.target.pattern === 'string') {
                    if (!regExTest(new RegExp(event.target.pattern), event.key)) event.preventDefault();
                } else if (!regExTest(event.target.pattern, event.key)) event.preventDefault();
            }
            if (maxLength && valueLength >= maxLength) event.preventDefault();
        };
        const handleKeydown = (event: any) => {
            if (event.keyCode === 27) {
                if (inputRefInternal.current) {
                    inputRefInternal.current.blur();
                }
            }
        };

        const handlePaste = (event: any) => {
            const pasted = event.clipboardData.getData('text');
            if (maxLength && valueLength + pasted.length > maxLength) event.preventDefault();
        };

        const getHelpTextId = () => `${inputId}-error`;

        const getAriaDescribedBy = (oriDescribedBy?: string) => {
            oriDescribedBy ? getHelpTextId() : undefined;
        };

        const getInputProps = <T extends FlosInputProps>(oriProps: T) => {
            return Object.assign({}, oriProps, {
                'aria-describedby': getAriaDescribedBy(oriProps['aria-describedby']),
                id: inputId,
                onBlur: callAll(oriProps.onBlur, handleBlur),
                onFocus: callAll(oriProps.onFocus, handleFocus),
                onChange: callAll(oriProps.onChange, handleChange),
                onKeyUp: callAll(oriProps.onKeyUp, handleKeyUp),
                onKeyPress: callAll(oriProps.onKeyPress, handleKeyPress),
                onKeyDown: callAll(oriProps.onKeyDown, handleKeydown),
                onPaste: callAll(oriProps.onPaste, handlePaste),
                readOnly: typeof editable !== 'undefined' ? state.isReadOnly : oriProps.readOnly,
                ref: inputRefInternal,
            });
        };

        const regExTest = (regex: RegExp, key: string) => {
            return regex.test(key);
        };

        useEffect(() => {
            setState((prevState) => ({ ...prevState, isValid: isValid }));
        }, [isValid]);

        useEffect(() => {
            onValidityChange && onValidityChange(state.isValid);
        }, [state.isValid]);

        useEffect(() => {
            if (!state.isReadOnly && editable) {
                (inputRefInternal as MutableRefObject<HTMLInputElement>).current.focus();
            }
        }, [state.isReadOnly, editable]);

        return (
            <FlosFormGroup
                className={cx(
                    'flos-field',
                    { 'has-icon': iconShape, 'has-edit-button': editable && state.isReadOnly, 'flos-field--with-helptext': showHelptext, 'has-chevron': hasDropdown },
                    wrapperClassName
                )}
                isValid={disabled ? undefined : state.isValid}
                required={required}
                ref={formgroupRef}
            >
                {renderInput({ getInputProps: getInputProps as any })}
                {!custom ? (
                    <LabelIcons
                        label={label}
                        iconShape={iconShape}
                        required={required}
                        renderInput={renderInput}
                        disabled={disabled}
                        inputId={inputId}
                        isValid={state.isValid}
                        hasDropdown={hasDropdown}
                        editable={editable && state.isReadOnly}
                        readOnlyHandler={handleReadOnlyState}
                        isFocused={state.isFocused || isOpen}
                    />
                ) : null}
                {showLength && showHelptext ? (
                    <div className={cx('flos-field-helptext-row')}>
                        <div
                            className={cx('flos-field-helptext-col', {
                                ['flos-field-helptext--valid']: !disabled && state.isValid,
                                ['flos-field-helptext--invalid']: !disabled && state.isValid === false,
                            })}
                        >
                            {hintText}
                        </div>
                        {maxLength >= 1 && (
                            <div className="flos-field-helptext-col flos-field-helptext-col--right">
                                {valueLength} / {maxLength}
                            </div>
                        )}
                    </div>
                ) : null}
            </FlosFormGroup>
        );
    }
);
FlosField.displayName = 'FlosField';
export type { FlosFieldProps, FlosFieldInternalProps, FlosFieldRenderInputProps };
export { FlosField };
export default FlosField;
