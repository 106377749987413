import cx from 'classnames';
import React from 'react';

export interface IFormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    /** state if the form group is required */
    required?: boolean;
    /** validation status of the form group */
    status?: 'success' | 'error' | 'warning';
    /** max width of the form group */
    maxWidth?: 'narrow' | 'small' | 'wide' | 'wide-thin' | 'medium';
    /** state if the form group is compact, i.e. smaller spacing between elements */
    compact?: boolean;
    /** if the form group has a leading icon */
    iconShape?: string;
    /** state if the form group is compact, i.e. smaller spacing between elements */
    hasAnimatedLabel?: boolean;
    /** state if the form group has an ending icon */
    hasIconAfter?: boolean;
}

/**
 * FormGroup is a container that wrap a control (input, select)
 * and the label to provide contextual styling. It usually it used
 * in conjection with Input, Select, and Label component
 *  @deprecated use FlosFormGroup instead
 */
export const FormGroup = React.forwardRef<HTMLDivElement, IFormGroupProps>(function FormGroupComponent(
    { className, iconShape, hasAnimatedLabel, hasIconAfter, maxWidth, required, status, compact, ...props },
    ref
) {
    return (
        <>
            <div
                className={cx(
                    'form-group',
                    hasAnimatedLabel && 'form-group-animated',
                    iconShape && 'has-icon-before',
                    hasIconAfter && 'has-icon-after',
                    required && 'is-required',
                    maxWidth && `form-group--${maxWidth}`,
                    status && `has-${status}`,
                    compact && 'form-group--compact',
                    className
                )}
                {...props}
                ref={ref}
            />
        </>
    );
});

export default FormGroup;
