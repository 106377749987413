// @overview: Utilities over DOM API
/**
 * @private
 */
export function changeBodyClass(className: string, type: 'add' | 'remove') {
    if (document.body) {
        if (type === 'add') {
            document.body.className += ` ${className}`;
        } else {
            document.body.className = document.body.className
                .split(' ')
                .filter((cn) => cn !== className)
                .join(' ');
        }
    }
}

const userAgentPatterns = ['MSIE ', 'Trident/', 'Edge/'];

/**
 * Check if current browser is made by Microsoft, which usually
 * has compatibility issues and requires special handling
 * @param userAgent default to `window.navigator.userAgent`
 */
export function isMicrosoftBrowser(userAgent = window.navigator.userAgent) {
    return new RegExp(userAgentPatterns.join('|')).test(userAgent);
}

/**
 * Preload image eagerly so it will render instantly when you need it.
 *
 * Beware that doing this excessively wastes your user bandwidth unnecessarily.
 * @param imageSrc url for the image that you want to preload
 * @param onLoad callback to invoked when the image is loaded successfully
 */
export function preloadImage(imageSrc: string, onLoad?: () => void) {
    const image = new Image();
    if (onLoad) {
        image.onload = onLoad;
    }
    image.src = imageSrc;
}

/**
 * Copy string to clipboard so easy do not need to manually do it.
 *
 * See example [here](#copy-transient-demo).
 *
 * @param textToCopy string to be copied to clipboard
 */
export function copyToClipboard(textToCopy: string): void {
    const currentActive = document.activeElement as HTMLElement;

    const textarea = document.createElement('textarea');
    textarea.value = textToCopy;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand('copy');

    document.body.removeChild(textarea);

    if (currentActive && currentActive !== document.body && currentActive.focus) {
        currentActive.focus();
    }
}
