import React from 'react';
import cx from 'classnames';

type HeroBannerProps = {
    /** Url to fetch the image */
    imageUrl?: string;
    /** Coloring theme */
    theme?: 'primary' | 'secondary' | 'transparent';
    /** This label will show above the title */
    label?: string;
    /** Title text */
    title: string;
    /** Subtitle text */
    subtitle?: string;
    /** Children to render below subtitle*/
    children?: React.ReactNode;
    /** Campaign Splash area */
    splashArea?: React.ReactNode;
    /** Action buttons or links for the Hero content area */
    actionArea?: React.ReactNode;
    /** 12 col grid area below content to add any custom stuff - Only applicable when transparent theme */
    bottomActionArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'article'>;

const HeroBanner = ({ imageUrl, theme, title, subtitle, label, splashArea, children, actionArea, bottomActionArea, className, ...props }: HeroBannerProps) => {
    return (
        <article className={cx('flos-hero', { [`flos-hero--${theme}`]: !!theme, 'has-splash': splashArea && theme !== 'transparent' }, className)} {...props}>
            {imageUrl && theme === 'transparent' && (
                <picture>
                    <img alt="flos-hero" src={imageUrl} className="flos-hero-bg-fit" />
                </picture>
            )}

            <div className={cx('container', { 'flos-grid': theme === 'transparent' })}>
                {imageUrl && theme !== 'transparent' && (
                    <div className={'flos-hero-image-container modal-ready'}>
                        <img alt="" src={imageUrl} className={'flos-hero-image'} />
                    </div>
                )}
                <div
                    className={cx(
                        {
                            'flos-grid--span-6 flos-grid--col-start-1 flos-hero-content--transparent': theme === 'transparent',
                            'flos-hero-content': theme !== 'transparent',
                        },
                        'modal-ready'
                    )}
                >
                    <div className={'flos-hero-text'}>
                        {label && <div className={'h5 flos-hero-label'}>{label}</div>}
                        <h1 className={cx('flos-hero-title', { 'u-spacing-stack-none': !children && !subtitle })}>{title}</h1>
                        {subtitle && <p className={cx('flos-hero-subtitle large', { 'u-spacing-stack-none': !children })}>{subtitle}</p>}
                        {children && children}
                    </div>
                    {actionArea && <div className={'flos-hero-action'}>{actionArea}</div>}
                </div>

                {splashArea && (
                    <div
                        className={cx(
                            {
                                'flos-grid--span-6 flos-grid--row-start-mobile-1 flos-hero-splash-container--transparent': theme === 'transparent',
                                'flos-hero-splash-container': theme !== 'transparent',
                            },
                            'modal-ready'
                        )}
                    >
                        {splashArea}
                    </div>
                )}
                {theme === 'transparent' && bottomActionArea && <div className={'flos-grid--span-12 flos-hero-bottom-area'}>{bottomActionArea && bottomActionArea}</div>}
            </div>
        </article>
    );
};

export type { HeroBannerProps };
export { HeroBanner };
export default HeroBanner;
