import React from 'react';
import { throttle } from '../../utils/fp';

export type UseSelectOnScrollProps = {
    selectedPackageIndex: number;
};

export type UseSelectOnScrollResult = [number, React.Dispatch<React.SetStateAction<number>>, React.MutableRefObject<HTMLDivElement>];

export const useSelectOnScrolled = ({ selectedPackageIndex }: UseSelectOnScrollProps): UseSelectOnScrollResult => {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(selectedPackageIndex);
    // Create a ref to the carousel container element
    const carouselRef = React.useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;
    // Create state to track whether scrolling is available in the container or not
    const [scrollUnavailable, setScrollUnavailable] = React.useState(!carouselRef.current);

    // Use callback hook to cache the handle scroll function
    const handleScroll = React.useCallback((event: Event, orientation: 'horizontal' | 'vertical' = 'horizontal') => {
        event.preventDefault();
        // Get an array of all the children of the carousel container
        const items = Array.from(carouselRef.current.children);
        const isHorizontal = orientation === 'horizontal';
        const containerRect = carouselRef.current.getBoundingClientRect();
        const center = isHorizontal ? containerRect.width / 2 : containerRect.height / 2;
        // Get the rect value that closest to center
        const closestItem = items.reduce(
            (prev, curr) => {
                const currRect = curr.getBoundingClientRect();
                const currCenter = isHorizontal ? currRect.left + currRect.width / 2 : currRect.top + currRect.height / 2;
                return Math.abs(currCenter - center) < Math.abs(prev.center - center) ? { index: curr.getAttribute('data-index'), center: currCenter } : prev;
            },
            { index: null, center: Infinity }
        );
        if (closestItem.index !== null) {
            const closestItemIndex = parseInt(closestItem.index);
            setSelectedIndex((prevIndex) => {
                if (prevIndex === closestItemIndex) return prevIndex;
                return closestItemIndex;
            });
        }
    }, []);

    const connect = React.useCallback(() => {
        carouselRef.current.addEventListener('scroll', (e) => requestAnimationFrame(() => handleScroll(e)));
    }, [handleScroll]);

    const cleanup = React.useCallback(() => {
        carouselRef.current && carouselRef.current.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    React.useEffect(() => {
        if (!carouselRef.current) return;
        // Remove the listener if scrolling is not available
        if (scrollUnavailable) cleanup();
        // Else Add listenser
        else connect();
        // Cleanup when the component unmounts
        return cleanup;
    }, [scrollUnavailable, carouselRef.current]);

    // Check size synchronously before DOM repaint, do nothing in SSR
    React.useLayoutEffect(() => {
        const handleResize = throttle(() => {
            if (!carouselRef.current) return;
            const { scrollWidth, clientWidth } = carouselRef.current;
            // If the scroll width is less than or equal to the client width, scrolling is not available
            setScrollUnavailable(scrollWidth <= clientWidth);
        }, 100);
        handleResize();
        // Defer resize handler until the next animation frame
        const onResize = () => requestAnimationFrame(handleResize);
        // Add an event listener to the window to detect resizing
        window.addEventListener('resize', onResize);
        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [carouselRef.current]);
    return [selectedIndex, setSelectedIndex, carouselRef];
};
