import React from 'react';
import cx from 'classnames';
import { Logo } from '../../../cms/site-header/parts/logo';
import ActionMenuItem, { ActionMenuItemProps } from './action-menu-item';

type SiteHeaderProps = {
    brand?: 'td' | 'co';
    logoHref: string;
    children?: React.ReactElement<ActionMenuItemProps>[] | React.ReactElement<ActionMenuItemProps>;
} & React.ComponentPropsWithoutRef<'div'>;

const SiteHeader = ({ children, brand = 'td', logoHref, ...props }: SiteHeaderProps): React.ReactElement => {
    const childrenArray: React.ReactElement<ActionMenuItemProps>[] = children ? (React.Children.toArray(children) as React.ReactElement<ActionMenuItemProps>[]) : [];
    const actionMenuItems = (childrenArray: React.ReactElement<ActionMenuItemProps>[]) => {
        return childrenArray.map((child: React.ReactElement<ActionMenuItemProps>) => {
            if (React.isValidElement(child) && child.type === ActionMenuItem) {
                return child;
            } else {
                return;
            }
        });
    };

    return (
        <>
            <header id="site-header" className={`site-header hidden-xs hidden-sm`} {...props}>
                <a href={logoHref} className="site-header-logo">
                    <svg width="144" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <use xlinkHref="#site-header-logo" />
                    </svg>
                </a>
                <ul className="site-header-actionmenu">{childrenArray && actionMenuItems(childrenArray)}</ul>
            </header>
            <header id="site-header-mobile" className={cx('site-header-mobile', 'hidden-md hidden-lg')} {...props}>
                <div id="site-header-mobile-top" className="site-header-mobile-top">
                    <Logo brand={brand} href={logoHref} />
                    <ul className={'site-header-mobile-top-actionmenu'}>{childrenArray && actionMenuItems(childrenArray)}</ul>
                </div>
            </header>
        </>
    );
};

export type { SiteHeaderProps };
export { SiteHeader };
export default SiteHeader;
