import React from 'react';
import cx from 'classnames';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import { FlosField, FlosFieldProps } from '../flos-field';
import { FlosInput } from '../input/flos-input';
import format from 'date-fns/format';

registerLocale('da', da);

const CustomInput = React.forwardRef<HTMLInputElement, FlosFieldProps>(function CustomInput(
    { label, isValid, iconShape, errorText, wrapperClassName, helpText, isOpen, ...flosInputProps },
    forwardedRef: React.MutableRefObject<HTMLInputElement>
) {
    return (
        <FlosField
            ref={forwardedRef}
            isValid={isValid}
            label={label}
            iconShape={iconShape || 'calendar-02'}
            errorText={errorText}
            wrapperClassName={wrapperClassName}
            disabled={flosInputProps.disabled}
            required={flosInputProps.required}
            id={flosInputProps.id}
            hasDropdown
            isOpen={isOpen}
            helpText={helpText}
            renderInput={({ getInputProps }) => <FlosInput {...getInputProps(flosInputProps)} readOnly autoComplete="off" />}
        />
    );
});

export type DatePickerFieldProps = ReactDatePickerProps & {
    onChange?: (date: Date, event: React.ChangeEvent<HTMLInputElement>) => void;
} & Omit<FlosFieldProps, 'onValidityChange' | 'maxLength' | 'minLength' | 'editable' | 'custom'>;

/**
 * DateField is a wrapper of `react-datepicker` using styling from ´FlosField´ and ´FlosInput´.
 * You can read more about ´react-datepicker´ here: https://reactdatepicker.com/
 * If you wish to have more control, you can compose them yourself.
 */
export const DateField = React.forwardRef<HTMLInputElement, DatePickerFieldProps>(
    (
        {
            filterDate,
            selected,
            dateFormat = 'dd/MM/yyyy',
            onCalendarClose,
            onCalendarOpen,
            onChangeRaw,
            minDate,
            maxDate,
            inline,
            excludeDates,
            onChange,
            onBlur,
            onFocus,
            errorText,
            helpText,
            isValid,
            value,
            ...rest
        },
        forwardedRef: React.MutableRefObject<HTMLInputElement>
    ) => {
        // ! React Hook "React.useRef" is called conditionally.
        // ? According to eslint(react-hooks/rules-of-hooks): React Hooks must be called in the exact same order in every component render.
        // Create a fallback ref if user didn't pass any ref when they use DateField component.
        const fallbackRef = React.useRef<React.MutableRefObject<HTMLInputElement>>();
        const DateFieldRef = forwardedRef || fallbackRef;
        const [dateInString, setDateInString] = React.useState(value);
        const [selectedDate, setSelectedDate] = React.useState(selected);
        const hintText = isValid === false ? errorText : helpText;
        const sanitizedDateFormat = `${dateFormat}`.replace(/[A-LN-Z]+/g, (match) => match.toLowerCase());

        const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);

        const openHandler = () => {
            setIsCalendarOpen(true);
            onCalendarOpen && onCalendarOpen();
        };

        const closeHandler = () => {
            setIsCalendarOpen(false);
            onCalendarClose && onCalendarClose();
        };

        const handleChange = React.useCallback(
            (date: Date) => {
                setSelectedDate(date);
                const formattedDate = format(date, sanitizedDateFormat, { locale: da });
                setDateInString(formattedDate);
                if (!onChange) return;
                const newEvent: React.ChangeEvent<HTMLInputElement> = Object.assign({ target: Object.assign({}, DateFieldRef.current, { value: formattedDate }) });
                onChange(date, newEvent);
            },
            [DateFieldRef, onChange, sanitizedDateFormat]
        );
        React.useEffect(() => {
            if (!value) return;
            const timestamp = Date.parse(value);
            const newDate = new Date(timestamp);
            handleChange(newDate);
        }, [value, sanitizedDateFormat]);

        return (
            <div className={cx('flos-date-field', { 'flos-date-field--with-helptext': !!hintText })}>
                <DatePicker
                    ref={(datePickerInput) => datePickerInput && (DateFieldRef.current = datePickerInput['input'])}
                    id={rest.id}
                    inline={inline}
                    required={rest.required}
                    placeholderText={' '}
                    selected={selectedDate}
                    onChange={handleChange}
                    onChangeRaw={onChangeRaw}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    showPopperArrow={false}
                    popperModifiers={[
                        {
                            name: 'flos',
                            enabled: true,
                            phase: 'main',
                            fn: ({ state }: any) => {
                                if (/^bottom/.test(state.placement)) {
                                    DateFieldRef.current.classList.add('flos-input-dialog-below');
                                } else {
                                    DateFieldRef.current.classList.remove('flos-input-dialog-below');
                                }
                                if (/^top/.test(state.placement)) {
                                    DateFieldRef.current.classList.add('flos-input-dialog-above');
                                } else {
                                    DateFieldRef.current.classList.remove('flos-input-dialog-above');
                                }
                            },
                        },
                        {
                            name: 'preventOverflow',
                            options: {
                                rootBoundary: 'viewport',
                                tether: false,
                                altAxis: true,
                            },
                        },
                    ]}
                    locale={'da'}
                    filterDate={filterDate}
                    dateFormat={sanitizedDateFormat}
                    onCalendarOpen={openHandler}
                    onCalendarClose={closeHandler}
                    minDate={minDate}
                    maxDate={maxDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    peekNextMonth
                    disabled={rest.disabled}
                    excludeDates={excludeDates}
                    value={dateInString}
                    customInput={<CustomInput helpText={helpText} errorText={errorText} isValid={isValid} isOpen={isCalendarOpen} {...rest} ref={DateFieldRef} />}
                />
            </div>
        );
    }
);

DateField.displayName = 'DateField';
