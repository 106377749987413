import React from 'react';

type FlosIconProps = {
    /**
     * The shape name for the icon in the icon library.
     */
    shape: string;
    /**
     * Size of the icon - use t-shirt sizes or any number.
     */
    size?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | number;
    /**
     * If you need to rotate the icon.
     */
    dir?: string;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * `FlosIcon` will render icons from the [icon library](https://flos.topdanmark.dk/icons/latest/index.html)
 */
export const FlosIcon: React.FC<FlosIconProps> = ({ shape = 'error', size = 'small', className = '', dir = '', ...props }): React.ReactElement => {
    let iconSize;
    switch (size) {
        case 'xlarge':
            iconSize = 128;
            break;
        case 'large':
            iconSize = 64;
            break;
        case 'medium':
            iconSize = 32;
            break;
        case 'small':
            iconSize = 24;
            break;
        case 'xsmall':
            iconSize = 16;
            break;
        default:
            iconSize = size;
    }
    return React.createElement('flos-icon', { class: className, size: iconSize, shape, dir, ...props });
};

export type { FlosIconProps };
export default FlosIcon;
