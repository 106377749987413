import cx from 'classnames';
import React from 'react';

type OptionProps = {
    label: string;
    value: string;
    optgroup?: string;
    selected?: boolean;
    disabled?: boolean;
};

type OptionGroupProps = OptionProps[] | { [group: string]: OptionProps[] };

type SelectInputProps = {
    /**
     * options that will be rendered as children, you can also construct your option markup as children
     */
    options?: Array<OptionProps | string>;
    optgroup?: boolean;
    placeholder?: string;
} & React.ComponentPropsWithRef<'select'>;

const normalizeOption = (option: OptionProps | string): OptionProps => {
    return typeof option === 'string' ? { value: option, label: option.charAt(0).toUpperCase() + option.slice(1) } : option;
};

const normalizeOptionGroup = (options: (OptionProps | string | undefined)[]) => {
    const normalizedOptions: OptionGroupProps = [];
    options.forEach((option: OptionProps) => {
        if (option.optgroup) {
            if (!normalizedOptions[option.optgroup]) {
                normalizedOptions[option.optgroup] = [];
            }
            normalizedOptions[option.optgroup].push(normalizeOption(option));
        } else {
            normalizedOptions.push(normalizeOption(option));
        }
    });
    return normalizedOptions;
};

const renderOption = (opt: OptionProps, currentValue: string, index: number) => {
    return (
        <option key={index} value={opt.value} aria-selected={opt.value === currentValue}>
            {opt.label}
        </option>
    );
};

const renderGroupedOption = (options: OptionProps[], currentValue: string) => {
    const groupedOptionOutput: any[] = [];
    let groupedIndex = 100;
    options.forEach((o: any) => {
        groupedOptionOutput.push(renderOption(o, currentValue, groupedIndex));
        groupedIndex++;
    });
    return groupedOptionOutput;
};

const renderOptionGroup = (options: OptionGroupProps, currentValue: any) => {
    const output: any[] = [];
    let index = 0;
    for (const key in options) {
        if (options.hasOwnProperty(key)) {
            if (isNaN(parseInt(key))) {
                // Option groups will be a string
                const groupedOptionOutput = renderGroupedOption(options[key], currentValue);
                groupedOptionOutput.length &&
                    output.push(
                        <optgroup key={index} label={key}>
                            {groupedOptionOutput}
                        </optgroup>
                    );
            } else {
                output.push(renderOption(options[key], currentValue, index));
            }
        }
        index++;
    }
    return output;
};

/**
 * Select is a wrapper around `<select>` html element,
 * thus it would accepts all props a `<select />` accepts.
 * You can configure the options by passing down `options` props
 * or construct the `<option>` markup like a `select` element.
 * Ref will be forwarded to underlying `<select>` element
 */
const SelectInput = React.forwardRef<HTMLSelectElement, SelectInputProps>(({ className, onChange, children, options, optgroup = false, placeholder, ...props }, ref) => {
    const optionGroup: OptionProps[] = options ? normalizeOptionGroup(options) : [];
    return (
        <select className={cx('flos-select', className)} onChange={onChange} {...props} ref={ref as React.Ref<HTMLSelectElement>}>
            <option value="" hidden className="flos-select-default-option">
                {placeholder ? placeholder : 'Vælg...'}
            </option>
            {options ? renderOptionGroup(optionGroup, props.value) : children}
        </select>
    );
});

SelectInput.displayName = 'SelectInput';

export type { SelectInputProps, OptionProps, OptionGroupProps };
export { SelectInput, normalizeOption, normalizeOptionGroup };
export default SelectInput;
