import ReactDOM from 'react-dom/client';
import Bootstrap from './bootstrap';
import { ConfigContext } from './context/ConfigContext';
import { appConfig } from './config';
let context = (window as any) || {};

context.OnsaOverlayApp = function (data: any) {
    (window as any).apiAwsHost = data.apiAwsHost;
    const root = document.getElementById('OnsaOverlayApp') as HTMLElement;
    ReactDOM.createRoot(root).render(
        <ConfigContext.Provider value={appConfig}>
            <Bootstrap />
        </ConfigContext.Provider>
    );
};
