/**
 * check if a value is *NOT* `undefined`. This is useful to check if optional props is specified.
 * @param value the value that could be `undefined`
 */
export const isDefined = <T>(value: T | undefined): value is T => typeof value !== 'undefined';

/**
 * check if a value is `undefined` or `null`.
 * @param value
 */
export const isNil = (value: any): value is undefined | null => typeof value === 'undefined' || value === null;

/**
 * Check if a value is boolean (`true` / `false`)
 * @param value
 */
export const isBool = (value: any): value is boolean => typeof value === 'boolean';

/**
 * Check if value is a string
 * @param value
 */
export const isString = (value: any): value is string => typeof value === 'string';

/**
 * Collection of primitive types
 */
export type Primitive = string | boolean | number | null | undefined;

/**
 * check if a value is primitive (`undefined`, `null`, `boolean`, `number`, or `string`).
 * @param value
 */
export const isPrimitive = (value: any): value is Primitive => value === null || ['string', 'undefined', 'boolean', 'number'].indexOf(typeof value) !== -1;

/**
 * Check if value is a function
 * @param value
 */
export const isFunction = (value: any): value is Function => typeof value === 'function';
