import { isMicrosoftBrowser } from './dom';

export interface IScrollToOptions {
    offset?: number;
    behavior?: ScrollOptions['behavior'];
}

function legacyScrollTo(offsetPosition: number) {
    window.scrollTo(0, offsetPosition);
}

/**
 * Scroll window to specific target.
 *
 * See it in action [here](#just-like-native-Button).
 *
 * @param targetEl target of the scroll
 * @param scrollOptions similar option for `window.ScrollTo`
 * @param scrollOptions.offset the offset between the target element and the top of the window. Default to `160` (the height of site header + `<flos-pricebar />`).
 * @param scrollOptions.behavior the scrolling behavior. Default to 'smooth'. This parameter will be ignored in IE/Microsoft Edge because they doesn't support the smooth scrolling behavior by default.
 */
export function scrollTo(targetEl: HTMLElement, scrollOptions: IScrollToOptions = {}) {
    if (process.env.NODE_ENV === 'test' && !process.env.__MIGRATION_FLOS_TEST__) {
        return;
    }

    const { offset = 160, behavior = 'smooth' } = scrollOptions;

    try {
        const bodyRect = document.body.getBoundingClientRect().top;
        const targetRect = targetEl.getBoundingClientRect().top;
        const targetPosition = targetRect - bodyRect;
        const offsetPosition = targetPosition - offset;
        if (isMicrosoftBrowser()) {
            legacyScrollTo(offsetPosition);
        } else {
            try {
                window.scrollTo({
                    behavior,
                    top: offsetPosition,
                });
            } catch (e) {
                // old browser may throw error if they doesn't support, we will attempt to use the legacy ver instead
                legacyScrollTo(offsetPosition);
            }
        }
    } catch (err) {
        // ScrollTo should not throw error and break your application
        console.error(err);
        console.error('ScrollTo not supported');
    }
}
