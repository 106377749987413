import React from 'react';
import cx from 'classnames';
import { getId } from '../../utils';

type SasSpotProps = {
    /**
     * Unique selector. When one specific message can be injected in one place on the page
     */
    id?: string;
    /**
     * Common selector. When the same message can be injected several places on the page or across pages.
     */
    selector?: string;
    /**
     * Function that will recieve notice when DOM changes within the component
     */
    onUpdate?: (isUpdated: boolean) => void;
} & React.ComponentPropsWithoutRef<'div'>;

const SasSpot = ({ id, selector, onUpdate, children }: SasSpotProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const spotId = id || getId();

    React.useEffect(() => {
        if (ref.current && onUpdate) {
            const observer = new MutationObserver(() => onUpdate(true));
            observer.observe(ref.current, {
                childList: true,
            });
            return () => observer.disconnect();
        }
        return;
    }, []);

    React.useEffect(() => {
        const event = new CustomEvent('spotloaded', { detail: { id, selector } });
        document.dispatchEvent(event);
    }, []);

    return (
        <div ref={ref} id={'sas-spot-' + spotId} className={cx('sas-spot', 'sas-spot-' + selector)}>
            {children}
        </div>
    );
};

export type { SasSpotProps };
export { SasSpot };
export default SasSpot;
