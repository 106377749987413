import React from 'react';
import cx from 'classnames';

type ImageBannerProps = {
    /** Url to fetch the image */
    imageUrl: string;
    /** Title text */
    title: string;
    /** Subtitle text */
    subtitle?: string;
    /** Children to render below subtitle */
    children?: React.ReactNode;
    /** Action buttons or links for the Image content area */
    actionArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'article'>;

const ImageBanner = ({ imageUrl, title, subtitle, children, actionArea, className, ...props }: ImageBannerProps) => {
    return (
        <article className={cx('flos-image-banner container', className)} {...props}>
            <div className={'flos-image-banner-container'}>
                <div className={'flos-image-banner-content'}>
                    <div className={'flos-image-banner-text'}>
                        <h3 className={cx('flos-image-banner-title', { 'u-spacing-stack-none': !children && !subtitle })}>{title}</h3>
                        {subtitle && <p className={cx('flos-image-banner-subtitle', { 'u-spacing-stack-none': !children })}>{subtitle}</p>}
                        {children && children}
                    </div>
                    {actionArea && <div className={'flos-image-banner-action'}>{actionArea}</div>}
                </div>
                <div className={'flos-image-banner-image-container'}>
                    <img alt="" src={imageUrl} className={'flos-image-banner-image'} />
                </div>
            </div>
        </article>
    );
};

export type { ImageBannerProps };
export { ImageBanner };
export default ImageBanner;
