import React, { useRef, MutableRefObject } from 'react';
import { NumericFormat, NumberFormatValues, NumericFormatProps } from 'react-number-format';

import { FlosInput, FlosInputProps } from '../input/flos-input';
export type NumberInputProps = {
    /**
     * specify how many decimals are allowed - will behave as float - with formatting
     */
    decimalsAllowed?: number;

    /**
     * specify if decimals should be fixed. If set to true 0's will be added to match the number of decimals allowed
     */
    decimalsFixed?: boolean;

    /**
     * specify if the display value should have thousand separator
     */
    withFormatting?: boolean;
} & NumericFormatProps &
    Omit<FlosInputProps, 'type'>;

/**
 * NumberInput is a wrapper of Input component that only restrict value to number.
 *
 * It currently supports integer and float number (number with decimal places).
 */
export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(function NumberInputComp(
    { decimalsAllowed = 0, decimalsFixed = false, suffix = '', withFormatting, allowLeadingZeros = false, type = 'tel', max, min, ...inputProps },
    ref
) {
    let inputRef = useRef() as MutableRefObject<HTMLInputElement>;
    if (ref) inputRef = ref as MutableRefObject<HTMLInputElement>;

    const isAllowed = (values: NumberFormatValues) => {
        if (max == null && min == null) return true;
        const { floatValue } = values;
        const v = floatValue as number;
        if (max !== null && v >= (max as number)) {
            return false;
        }
        if (min !== null && v <= (min as number)) {
            return false;
        }
        return true;
    };

    return (
        <NumericFormat
            {...inputProps}
            suffix={suffix}
            type={type}
            decimalSeparator={','}
            getInputRef={inputRef}
            thousandSeparator={withFormatting ? '.' : false}
            decimalScale={decimalsAllowed && decimalsAllowed > 0 ? decimalsAllowed : 0}
            fixedDecimalScale={decimalsFixed}
            allowLeadingZeros={allowLeadingZeros}
            customInput={FlosInput}
            isAllowed={isAllowed}
        />
    );
});
