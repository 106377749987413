/**
 * Check if an array contains an item.
 *
 * Example:
 *
 * ```js
 * import { includes } from '@flos/react-ui';
 *
 * includes([1,2,3,4,5], 4); // true
 * includes([1,2,3,4,5], 9); // false
 * ```
 * @param array
 * @param item
 */
export const includes = <T>(array: T[] | ReadonlyArray<T>, item: T) => {
    return array.includes ? array.includes(item) : array.indexOf(item) !== -1;
};

/**
 * Flatten an array of array into single array.
 *
 * Example:
 *
 * ```js
 * import { concatAll } from '@flos/react-ui';
 *
 * concatAll([1,2,[3,4], [5,6,7]]); // [1,2,3,4,5,6,7]
 * ```
 * @param array
 */
export const concatAll = <T>(array: Array<T | T[]>) => {
    return array.reduce<T[]>((result, item) => result.concat(item), []);
};
