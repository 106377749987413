import cx from 'classnames';
import React from 'react';
import { FlosField } from '../../flos-field';
import { MultiSelect } from './multi-select';
import { SelectFieldProps } from '../select-field/select-field';

/**
 * `MultiSelectField` is a composition of `Field` component and `MultiSelect` component. If you need extra customization, you can compose them yourself.
 *
 *  Ref will be forwarded to the underlying `<select>` element.
 */

export const MultiSelectField = React.forwardRef<HTMLSelectElement, SelectFieldProps>(function SelectFieldComp(
    { label, iconShape, isValid, errorText = 'skal udfyldes', onValidityChange, helpText, ...selectProps },
    ref
) {
    return (
        <FlosField
            ref={ref}
            wrapperClassName={cx('flos-select-multi-wrapper', { 'is-disabled': selectProps.disabled, 'u-Space-down--lg': !!errorText || !!helpText }, selectProps.wrapperClassName)}
            isValid={isValid}
            onValidityChange={onValidityChange}
            label={label}
            iconShape={iconShape}
            errorText={errorText}
            required={selectProps.required}
            id={selectProps.id}
            disabled={selectProps.disabled}
            helpText={helpText}
            renderInput={({ getInputProps }) => <MultiSelect {...getInputProps(selectProps)} ref={ref} />}
        />
    );
});
