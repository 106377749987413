import React from 'react';
import cx from 'classnames';

type AccordionPanelProps = {
    /** Accordion panel title */
    title: string;
    /** Accordion panel subtitle */
    subtitle?: string;
    /** Info area content*/
    infoArea?: React.ReactNode;
    /** Callback when panel is toggled */
    onChange?: (isOpen: boolean) => void;
    /** Boolean to control if panel should be expanded */
    isOpen?: boolean;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title" | "onChange'>;

const AccordionPanel: React.FC<AccordionPanelProps> = ({ children, className, title, ...props }) => {
    const newProps = { ...props };
    delete newProps['isOpen'];
    delete newProps['infoArea'];
    delete newProps['subtitle'];
    delete newProps['onChange'];

    return (
        <div className={cx('flos-accordion-content', className && className)} aria-label={title} {...newProps}>
            {children}
        </div>
    );
};

export type { AccordionPanelProps };
export { AccordionPanel };
export default AccordionPanel;
