import { useEffect, useRef } from 'react';
import { IScrollToOptions, scrollTo } from './scroll-to';

/**
 * A hook to scroll to a target when the component is mount. Returns a ref that you can pass to the target component.
 *
 * @param scrollOptions options similar to [`ScrollTo`](#section-scrollto)
 */
export function useScrollOnMount<T extends HTMLElement>(scrollOptions: IScrollToOptions = {}) {
    const { offset = 110, behavior } = scrollOptions;
    const targetRef = useRef<null | T>(null);
    useEffect(() => {
        if (targetRef.current) {
            scrollTo(targetRef.current, {
                behavior,
                offset,
            });
        }
    }, [offset, behavior]);
    return targetRef;
}
