import React from 'react';
import cx from 'classnames';
import { useId } from '../../../utils/hooks';

type BadgeProps = {
    /**
     * The content of the badge
     */
    content: string;
    /**
     * Size of the badge
     */
    size?: 'medium' | 'small';
    /**
     * theme of the badge
     */
    theme?: 'default' | 'primary';
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Badge is a circle dot with a number or char inside. It supports up to 2 digits/chars - will not render anything when above.
 */

const Badge = ({ content, size = 'medium', theme = 'default', ...props }: BadgeProps) => {
    const id = useId(props.id);

    return (
        <>
            {content.length < 3 && (
                <div {...props} id={id} className={cx('flos-badge', `flos-badge--${size}`, `flos-badge--${theme}`, props.className && props.className)}>
                    {content}
                </div>
            )}
        </>
    );
};

export type { BadgeProps };
export { Badge };
