import React from 'react';
import { useEffect, useState } from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { CprInput, CprInputProps } from './cpr-input';

export type CprFieldProps = CprInputProps & Omit<FlosFieldProps, 'label'> & { label?: string };

/**
 * CprField is a composition of `FlosField` and `CprInput` component.
 * If you wish to have more control, you can compose them yourself.
 */
export const CprField = React.forwardRef<HTMLInputElement, Omit<CprFieldProps, 'maxLength'>>(function CprFieldComp(
    { label = 'CPR-nr.', iconShape, isValid, errorText = 'er ikke korrekt udfyldt', editable, wrapperClassName, onValidityChange, helpText, ...inputProps },
    ref
) {
    const [state, setState] = useState<boolean | undefined>(undefined);

    const isValidHandler = (isCprValid?: boolean) => setState(isValid ? isValid : isCprValid);

    useEffect(() => {
        setState(isValid);
    }, [isValid]);

    return (
        <FlosField
            ref={ref}
            isValid={state}
            onValidityChange={onValidityChange}
            wrapperClassName={wrapperClassName}
            editable={editable}
            label={label}
            iconShape={iconShape}
            errorText={errorText}
            required={inputProps.required}
            id={inputProps.id}
            disabled={inputProps.disabled}
            helpText={helpText}
            renderInput={({ getInputProps }) => <CprInput {...getInputProps({ ...inputProps, ref })} isValidHandler={isValidHandler} isValid={isValid} />}
        />
    );
});
